import {Immutable} from "@witivio_teamspro/use-reducer";
import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import {AxiosError} from "axios";
import {Notification} from "../../interfaces/Notification";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";

const getTemplates = () => new Promise<Immutable<Array<Notification>>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get("/notifications/templates");
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get notifications templates", error as AxiosError);
        resolve([]);
    }
});

const addTemplate = (template: Notification) => new Promise<Immutable<Array<Notification>>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.post("/notifications/templates", template);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't add notification template", error as AxiosError);
        resolve([]);
    }
});

const deleteTemplate = (id: string) => new Promise<Immutable<Array<Notification>>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.delete(`/notifications/templates/${id}`);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't delete notification template", error as AxiosError);
        resolve([]);
    }
});

export const NotificationsApi = {
    getTemplates,
    addTemplate,
    deleteTemplate,
}