import React, {ReactElement, useEffect, useMemo} from "react";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {generateFilterItems, generateSearchResultsCategories, initialState, reducer} from "./Home.reducer";
import "./Home.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {useConfigurationSelector} from "../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import {useBestPracticesCache} from "../../services/CacheService/bestPracticesCache";
import {useTrainingsCache} from "../../services/CacheService/trainingsCache";
import {EditMessageDialogRef} from "./EditMessageDialog/EditMessageDialog.interfaces";
import {CarouselRef} from "../../components/Carousel/Carousel.interfaces";
import {ItemDataModule} from "../../modules/ItemData.module";
import {ItemDialogRef} from "../../components/ItemDialog/ItemDialog.interfaces";
import {SearchbarRef} from "../../components/Searchbar/Searchbar.interfaces";
import {Searches} from "./parts/Searches";
import {News} from "./parts/News";
import {BestPractices} from "./parts/BestPractices";
import {SearchResults} from "./parts/SearchResults";
import {ItemDialog} from "../../components/ItemDialog/ItemDialog";
import {HomeLogic} from "./Home.interfaces";
import {AutoWidthMenuRef} from "../../components/AutoWidthMenu/AutoWidthMenu.interfaces";
import moment from "moment";
import {AppViewElementProps} from "../../interfaces/AppView/AppViewData";
import {ScreenModule} from "../../modules/Screen.module";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const Home = (props: AppViewElementProps): ReactElement => {
    const {messageOfTheDay} = useConfigurationSelector("messageOfTheDay");
    const {userId, userMail} = useMsTeamsSelector("userId", "userMail");

    const {bestPractices} = useBestPracticesCache();
    const {trainings} = useTrainingsCache();

    const editMessageDialogRef = useMagicReducerRef<EditMessageDialogRef>();
    const carouselRef = useMagicReducerRef<CarouselRef>();
    const searchesMenuRef = useMagicReducerRef<AutoWidthMenuRef>();
    const bestPracticesMenuRef = useMagicReducerRef<AutoWidthMenuRef>();
    const itemDialogRef = useMagicReducerRef<ItemDialogRef>();
    const searchBarRef = useMagicReducerRef<SearchbarRef>();

    const isSmallScreen = ScreenModule.isSmallScreen();

    const bestPracticesCategories = useMemo(() => ItemDataModule.generateCategories(bestPractices), [bestPractices]);

    const [state, dispatch] = useMagicReducer(reducer(
        bestPractices,
        trainings,
        editMessageDialogRef,
        bestPracticesCategories,
        searchesMenuRef,
        userId,
        userMail
    ), initialState(messageOfTheDay));

    useEffect(() => {
        searchBarRef.dispatch("clear")();
    }, [props.renderKey]);

    useEffect(() => {
        window.addEventListener("resize", dispatch("windowResize"));
        return () => window.removeEventListener("resize", dispatch("windowResize"));
    }, []);

    useEffect(() => {
        if (searchBarRef.state?.query)
            dispatch("handleSearch")(searchBarRef.state?.query, searchBarRef.state?.filterItems);
    }, [bestPractices, trainings]);

    let selectedFromDate = (searchBarRef.state?.filterItems?.["from-date"]?.inputValue ?? "") + "";
    if (!selectedFromDate) selectedFromDate = moment().startOf("day").add(-1, "year").format("YYYY-MM-DD");
    let selectedToDate = (searchBarRef.state?.filterItems?.["to-date"]?.inputValue ?? "") + "";
    if (!selectedToDate) selectedToDate = moment().startOf("day").format("YYYY-MM-DD");

    const filterItems = useMemo(() => (
        generateFilterItems(bestPractices, trainings, selectedFromDate, selectedToDate)
    ), [bestPractices, trainings, selectedFromDate, selectedToDate]);

    const searchResultsCategories = useMemo(generateSearchResultsCategories, []);

    const showSearchResults = !!searchBarRef.state?.query && searchBarRef.state?.query?.length > 0;

    const filteredBestPractices = useMemo(() => {
        if (!bestPractices) return undefined;
        let items = [...bestPractices];
        if (state.selectedBestPracticesCategoryIndex !== 0) {
            const selectedCategory = bestPracticesCategories?.[state.selectedBestPracticesCategoryIndex];
            if (selectedCategory) items = items.filter(bp => bp.topics.includes(selectedCategory.key));
        }
        items.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
        return items;
    }, [state.selectedBestPracticesCategoryIndex, bestPractices]);

    const showSkeletons = !bestPractices || !trainings;

    const logic: HomeLogic = {
        ...state,
        bestPracticesCategories,
        itemDialogRef,
        carouselRef,
        searchBarRef,
        showSearchResults,
        searchResultsCategories,
        filterItems,
        editMessageDialogRef,
        bestPractices: filteredBestPractices,
        dispatch,
        searchesMenuRef,
        bestPracticesMenuRef,
        showSkeletons,
    }

    return (
        <Flex fill column className={"home-view no-select"}>
            <Flex fill column={isSmallScreen} className={"home-view-content"}>
                <Flex className={"home-view-left-content"} fill column>
                    <Searches {...logic}/>
                    <News {...logic}/>
                    <BestPractices {...logic}/>
                    <SearchResults {...logic}/>
                </Flex>
            </Flex>
            <ItemDialog externalRef={logic.itemDialogRef}/>
        </Flex>
    )
}