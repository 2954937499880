import {useQuery} from "react-query";
import {IsTeamsApp} from "services/AuthService/AuthService";
import * as microsoftTeams from "@microsoft/teams-js";

const isAppFromTeams = async () => new Promise<boolean>(async resolve => {
    microsoftTeams.app.initialize().then(() => resolve(true)).catch(() => resolve(false));
});

export const useMsTeamsCache = () => {
    const {data: isTeamsApp} = useQuery(IsTeamsApp, isAppFromTeams, {staleTime: Infinity});

    return {isTeamsApp};
};