import {State} from "./FilterMobileDialog.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogRef} from "../../Dialog/Dialog.interfaces";

export const initialState: State = {
    openMenuIds: [],
}

export const reducer = (dialogRef: DialogRef) => ({
    clearOpenMenus: ({setState}) => {
        setState({openMenuIds: new Array<string>()});
    },
    toggleMenu: ({setState, state}, _, menuId: string) => {
        let openMenuIds = [...state.openMenuIds];
        if (openMenuIds.includes(menuId)) openMenuIds = openMenuIds.filter(id => id !== menuId);
        else openMenuIds.push(menuId);
        setState({openMenuIds});
    }
}) satisfies MagicReducerObject<State>;