import {ManagementDataRecommendedItems, ManagementUserData} from "../../interfaces/ManagementUserData";
import {RecommendItemData} from "../../interfaces/RecommendItemData";
import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";

const getManagerRecommendations = () => new Promise<ManagementDataRecommendedItems | undefined>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get("/management/manager/recommendations");
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user manager recommendations");
        return resolve(undefined);
    }
});

const getMyRecommendations = () => new Promise<ManagementUserData | undefined>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get("/management/me/recommendations");
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user recommendations");
        return resolve(undefined);
    }
});

const updateItemRecommendation = (itemData: RecommendItemData) => new Promise<boolean>(async (resolve) => {
    try {
        await AxiosConfig.instance.post("/management/recommend", itemData);
        return resolve(true);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update item recommendation");
        return resolve(false);
    }
});

export const ManagementApi = {
    getMyRecommendations,
    getManagerRecommendations,
    updateItemRecommendation,
}