import {useCallback, useEffect, useRef} from 'react';

const useUserActivityTimer = () => {
    const secondsRef = useRef<number>(0);
    const isActiveRef = useRef<boolean>(false);
    const isActiveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleActivity = useCallback(() => {
        if (isActiveTimeoutRef.current) clearTimeout(isActiveTimeoutRef.current);
        isActiveRef.current = true;
        isActiveTimeoutRef.current = setTimeout(() => {
            isActiveRef.current = false;
        }, 10000);
    }, []);

    useEffect(function initializeListeners() {
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('click', handleActivity);
        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('click', handleActivity);
        };
    }, []);

    useEffect(function initializeInterval() {
        let interval = setInterval(() => {
            if (isActiveRef.current) secondsRef.current += 1;
        }, 1000);
        return () => {
            if (interval) clearInterval(interval);
        };
    }, []);

    const getActiveSeconds = useCallback(() => secondsRef.current, []);

    return {
        getActiveSeconds,
    }
};

export default useUserActivityTimer;