import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props, State} from "./VirtualizedGrid.interfaces";
import "./VirtualizedGrid.styles.scss";
import {Flex} from "@fluentui/react-northstar";
import {Immutable, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer, useResizeEffect} from "./VirtualizedGrid.reducer";

export const VirtualizedGrid = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState);

    useResizeEffect(dispatch);
    useEffect(dispatch("initialize"), []);
    useMemo(dispatch("childrenUpdated"), [props.children]);

    const items = !state.isResizing && !!props.children ?
        props.children : Array.from({length: state.itemsPerLine * state.visibleLines}).map(() => null);

    const skeletons = useMemo(() => (
        Array.from({length: state.itemsPerLine * state.visibleLines}).map((_, i) => props.renderSkeleton("skeleton" + i))
    ), [state.itemsPerLine]);

    const topFillDivHeight = state.visibleRange[0]! / state.itemsPerLine;
    const bottomFillDivHeight = ((props.children?.length ?? 0) - state.visibleRange[1]!) / state.itemsPerLine;

    const gridStyles = {
        ...props.styles,
        padding: props.gridPadding + "px",
        gridGap: props.gridGap + "px",
        gridTemplateColumns: `repeat(auto-fill, minmax(${props.itemMinWidth}px, 1fr))`,
    };

    const containerStyles = {
        overflow: props.showSkeletons ? "hidden" : "scroll",
    }

    const showSkeletons = !props.children || props.showSkeletons || state.isResizing;

    let emptyItemsCount = (state.visibleLines * state.itemsPerLine) - (props.children?.length ?? 0);
    if (emptyItemsCount < 0) emptyItemsCount = 0;

    const emptyItems = !props.showAlwaysScrollbar ? null : renderEmptyItems(emptyItemsCount, props.itemHeight);

    return (
        <Flex fill styles={containerStyles} ref={dispatch("containerRef")} onScroll={dispatch("scroll")}>
            <div className={"virtualized-grid"} style={gridStyles}>
                {showSkeletons ? skeletons :
                    <>
                        {renderFillDiv("top", topFillDivHeight, state.itemsPerLine, props.itemHeight, props.gridGap)}
                        {items?.map((item, index) => isIndexInRange(index, state.visibleRange) ? item : null)}
                        {emptyItems}
                        {renderFillDiv("bottom", bottomFillDivHeight, state.itemsPerLine, props.itemHeight, props.gridGap)}
                    </>
                }
            </div>
        </Flex>
    )
}, (pp, np) => pp.children === np.children);

const renderFillDiv = (key: string, height: number, width: number, itemHeight: number, gridGap: number) => {
    const gridColumn = "span " + width;
    let gridRow = "span " + height;
    if (height <= 0) return null;
    const heightInPixels = (itemHeight * height) + (gridGap * (height - 1)) + "px";
    return (
        <div
            key={"empty-div" + key}
            className={"empty-div"}
            style={{height: heightInPixels, gridColumn, gridRow}}
        />
    )
}

const isIndexInRange = (index: number, range: Immutable<State["visibleRange"]>): boolean => {
    const [rangeStart, rangeEnd] = range;
    return index >= rangeStart! && index < rangeEnd!;
}

const renderEmptyItems = (count: number, itemHeight: number) => {
    return Array.from({length: count}).map((_, i) => (
        <div key={i} className={"empty-div"} style={{height: itemHeight}}/>
    ));
}