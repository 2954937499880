import {State} from "./Home.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {MessageOfTheDay} from "../../interfaces/MessageOfTheDay";
import {ItemData, ItemDataType} from "../../interfaces/ItemData";
import {EditMessageDialogRef} from "./EditMessageDialog/EditMessageDialog.interfaces";
import {FilterItemsData, IFilterItem} from "../../components/Filter/Filter.interfaces";
import {ItemDataModule} from "../../modules/ItemData.module";
import {BestPracticesModule} from "../../modules/BestPractices.module";
import {TrainingsModule} from "../../modules/Trainings.module";
import {AutoWidthMenuRef} from "../../components/AutoWidthMenu/AutoWidthMenu.interfaces";
import {HistoryService} from "../../services/HistoryService/HistoryService.hook";
import {ReportingModule} from "../../modules/Reporting.module";
import {AppView} from "../../interfaces/AppView/AppView";

export const homeViewCacheKey = "home-view";

export const initialState = (messageOfTheDay: MessageOfTheDay | undefined): State => {
    const historyState = HistoryService.getComponentState<State>(homeViewCacheKey);
    if (historyState) return {
        ...historyState,
        slidesPerView: getSlidesPerView(),
        messageOfTheDay: messageOfTheDay ?? {message: "", show: false, buttons: undefined},
    }
    return {
        slidesPerView: getSlidesPerView(),
        selectedBestPracticesCategoryIndex: 0,
        selectedSearchResultsCategoryIndex: 0,
        searchResults: undefined,
        messageOfTheDay: messageOfTheDay ?? {message: "", show: false, buttons: undefined},
    }
}

const saveState = (state: Immutable<State>) => {
    const {selectedBestPracticesCategoryIndex, selectedSearchResultsCategoryIndex} = state;
    HistoryService.saveComponentState(homeViewCacheKey, {
        selectedBestPracticesCategoryIndex,
        selectedSearchResultsCategoryIndex,
    }, 300);
}

export const reducer = (
    bestPractices: Immutable<Array<ItemData>> | undefined,
    trainings: Immutable<Array<ItemData>> | undefined,
    editMessageDialogRef: EditMessageDialogRef,
    categories: Array<{ key: string, content: string }> | undefined,
    searchesMenuRef: AutoWidthMenuRef,
    userId: string,
    userUpn: string,
) => ({
    handleEditMessageOfTheDay: () => {
        editMessageDialogRef.dispatch("handleOpen")();
    },
    handleClickBestPracticesMenuItem: (reducerData, [index]: [number]) => {
        const selectedItem = categories?.[index];
        if (!selectedItem) return;
        const {state, setState} = reducerData;
        if (state.selectedBestPracticesCategoryIndex === index) return;
        setState({selectedBestPracticesCategoryIndex: index});
        saveState(reducerData.state);
        ReportingModule.notifyTopicSelected(userId, selectedItem.key, AppView.Home);
    },
    handleClickSearchResultsMenuItem: (reducerData, [index]: [number]) => {
        const selectedItem = categories?.[index];
        if (!selectedItem) return;
        const {setState} = reducerData;
        setState({selectedSearchResultsCategoryIndex: index});
        saveState(reducerData.state);
    },
    handleSearch: (reducerData, [value, filterItems]: [string, FilterItemsData | undefined]) => {
        ReportingModule.notifyQuery(userId, value, AppView.Home);
        const {setState} = reducerData;
        setState({searchResults: undefined});
        const filteredBestPractices = ItemDataModule.filterItems({
            items: bestPractices,
            query: value,
            filterItems,
            userUpn,
        });
        const filteredTrainings = ItemDataModule.filterItems({
            items: trainings,
            query: value,
            filterItems,
            userUpn,
        });
        const searchResults = [
            ...ItemDataModule.mapItemsToSearchDataItems(filteredBestPractices, ItemDataType.BestPractice),
            ...ItemDataModule.mapItemsToSearchDataItems(filteredTrainings, ItemDataType.Training),
        ]
        setState({searchResults});
    },
    handleMessageOfTheDayEdited: (reducerData, [messageOfTheDay]: [MessageOfTheDay]) => {
        reducerData.setState({messageOfTheDay: messageOfTheDay});
    },
    handleOpenLink: (_, __, link: string) => {
        window.open(link, "_blank");
    },
    render: (reducerData) => {
        reducerData.render();
    },
    showSearchMoreItems: (_, __, type: ItemDataType) => {
        let index = 0;
        switch (type) {
            case ItemDataType.BestPractice:
                index = 1;
                break;
            case ItemDataType.Training:
                index = 2;
                break;
        }
        searchesMenuRef.dispatch("setActiveMenuIndex")(index);
    },
    windowResize: ({state, setState}) => {
        const slidesPerView = getSlidesPerView();
        if (state.slidesPerView === slidesPerView) return;
        setState({slidesPerView});
    },
}) satisfies MagicReducerObject<State>;

export const generateSearchResultsCategories = () => {
    return [
        {key: "all", content: "All"},
        {key: "best-practices", content: "Best Practices"},
        {key: "trainings", content: "Trainings"},
    ]
}

export const getSlidesPerView = () => {
    return Math.round(window.innerWidth / 450);
}

export const generateFilterItems = (
    bestPractices: Immutable<Array<ItemData>> | undefined,
    trainings: Immutable<Array<ItemData>> | undefined,
    selectedFromDate: string,
    selectedToDate: string,
): Array<IFilterItem> => {
    const bpFilters = BestPracticesModule.generateFilterItems(bestPractices) as Array<IFilterItem>;
    const trainingsFilters = TrainingsModule.generateFilterItems(trainings) as Array<IFilterItem>;
    const mergeFilterItems = (filterKey: string): IFilterItem | undefined => {
        const items = bpFilters.find(f => f.key === filterKey);
        const trainingsFilter = trainingsFilters.find(f => f.key === filterKey);
        if (!items && !trainingsFilter) return undefined;
        const existingItemKeys = items?.menu?.items?.map(i => i.key) ?? [];
        const trainingsItems = (trainingsFilter?.menu?.items ?? []) as Array<IFilterItem>;
        const newItems = trainingsItems.filter(i => !existingItemKeys.includes(i.key));
        if (items?.menu?.items) items.menu.items = items.menu?.items.concat(newItems);
        return items;
    };
    const fromDateFilter = bpFilters.find(f => f.key === "from-date");
    if (fromDateFilter?.input) {
        fromDateFilter.input.max = selectedToDate;
        fromDateFilter.input.value = selectedFromDate;
    }
    const toDateFilter = bpFilters.find(f => f.key === "to-date");
    if (toDateFilter?.input) {
        toDateFilter.input.min = selectedFromDate;
        toDateFilter.input.value = selectedToDate;
    }
    return [
        mergeFilterItems("job-family"),
        mergeFilterItems("topic"),
        fromDateFilter,
        toDateFilter,
    ].filter(Boolean) as Array<IFilterItem>;
}