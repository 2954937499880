import {Immutable} from "@witivio_teamspro/use-reducer";

export type State = {
    selectionMode: DateRangeType,
    isCustomEndDateSelected: boolean,
    selectedDate: string,
}

export type Props = {
    dateRange: Immutable<{ start: string, end: string }> | undefined,
    onDateRangeChange: (start: string, end: string) => void,
}

export enum DateRangeType {
    Day = 0,
    Week = 1,
    Month = 2,
    WorkWeek = 3,
    Custom = 4,
}