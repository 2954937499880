import {State} from "./DeleteTemplateDialog.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogRef} from "../../../components/Dialog/Dialog.interfaces";
import {Notification} from "../../../interfaces/Notification";
import {useNotificationTemplatesCache} from "../../../services/CacheService/notificationTemplatesCache";
import {ErrorModule} from "../../../components/ErrorBoundary/ErrorBoundary";

export const initialState: State = {
    isDeleting: false,
    notification: undefined,
}

export const reducer = (config: {
    dialogRef: DialogRef,
    deleteTemplate: ReturnType<typeof useNotificationTemplatesCache>["deleteTemplate"],
}) => ({
    open: ({setState}, [notification]: [Immutable<Notification>]) => {
        const {dialogRef} = config;
        setState({notification, isDeleting: false});
        dialogRef.dispatch("open")();
    },
    confirmDelete: async ({state, setState}) => {
        if (!state.notification) return ErrorModule.showErrorAlert("Can't delete template, id is missing");
        const {dialogRef, deleteTemplate} = config;
        setState({isDeleting: true});
        await deleteTemplate(state.notification.id);
        dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;