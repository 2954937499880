import {useQuery} from "react-query";
import {UserApi} from "../../apis/User/UserApi";
import {UserRole} from "../../interfaces/UserRole";

export const useUserRoleCache = (enabled: boolean = false) => {
    const {data: userRole} = useQuery("user_role", UserApi.getUserRole, {
        staleTime: Infinity,
        enabled,
    });

    return {
        userRole,
        isBasicUser: userRole === undefined || userRole === UserRole.User,
        isCommunityManager: userRole === UserRole.CommunityManager,
        isAdmin: userRole === UserRole.Admin,
    };
};