import React, {memo, ReactElement, useEffect, useMemo} from "react";
import {Props} from "./ItemsCarousel.interfaces";
import {Immutable, MagicDispatch, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ItemsCarousel.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ItemsCarousel.styles.scss";
import {ScreenModule} from "../../modules/Screen.module";
import {Button, ChevronEndIcon, ChevronStartIcon, Flex, Text} from "@fluentui/react-northstar";
import {AutoWidthMenu} from "../AutoWidthMenu/AutoWidthMenu";
import {ItemCard} from "../ItemCard/ItemCard";
import {ItemDataModule} from "../../modules/ItemData.module";
import {Carousel} from "../Carousel/Carousel";
import {ItemData} from "../../interfaces/ItemData";
import {ItemDialogRef} from "../ItemDialog/ItemDialog.interfaces";
import {CarouselRef} from "../Carousel/Carousel.interfaces";
import {AutoWidthMenuRef} from "../AutoWidthMenu/AutoWidthMenu.interfaces";
import {translations} from "../../translations";
import {useMyRecommendationsCache} from "../../services/CacheService/recommendationsCache";

export const ItemsCarousel = memo((props: Props): ReactElement | null => {
    const carouselRef = useMagicReducerRef<CarouselRef>();
    const autoWidthMenuRef = useMagicReducerRef<AutoWidthMenuRef>();
    const [state, dispatch, render] = useMagicReducer(reducer({
        props,
        carouselRef
    }), initialState(props), props.externalRef);
    const {canRecommendItem} = useMyRecommendationsCache();

    useEffect(() => {
        window.addEventListener("resize", dispatch("windowResize"));
        return () => window.removeEventListener("resize", dispatch("windowResize"));
    }, []);

    const hideRecommendationButton = props.hideRecommendationButton ?? !canRecommendItem;
    const isSmallScreen = ScreenModule.isSmallScreen();

    return (
        <Flex column className={"items-carousel"} styles={{paddingBottom: isSmallScreen ? "15px" : "30px"}}>
            <Flex vAlign={"center"}>
                <Flex column fill vAlign={"center"}
                      styles={{marginBottom: isSmallScreen ? "15px" : "0"}}>
                    <Text
                        className={"items-carousel-content-title"}
                        size={"larger"}
                        weight={"bold"}
                        content={props.title}
                    />
                    <Text
                        className={"items-carousel-content-subtitle"}
                        content={props.subtitle}
                    />
                    <Flex className={"w-100"} styles={{marginBottom: isSmallScreen ? "0" : "10px"}}>
                        <AutoWidthMenu
                            externalRef={autoWidthMenuRef}
                            defaultMenuIndex={props.defaultMenuIndex}
                            items={props.menuItems}
                            onIndexChange={dispatch("handleClickMenuItem")}
                        />
                    </Flex>
                </Flex>
            </Flex>
            {renderCarousel({
                items: props.items,
                itemDialogRef: props.itemDialogRef,
                carouselRef,
                slidesPerView: state.slidesPerView,
                render,
                dispatch,
                hideRecommendationButton,
            })}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const renderCarousel = (config: {
    items: Immutable<Array<ItemData>> | undefined,
    itemDialogRef: ItemDialogRef,
    carouselRef: CarouselRef,
    render: () => void,
    slidesPerView: number,
    dispatch: MagicDispatch<typeof reducer>,
    hideRecommendationButton: boolean
}) => {
    const items = useMemo(() => (
        !config.items ?
            Array.from({length: 10}).map((_, i) => <ItemCard key={"skeleton" + i} item={undefined}
                                                             hideRecommendButton={true}/>)
            :
            ItemDataModule.sortItemsByRank(config.items).map(b => (
                <ItemCard
                    key={b.id}
                    item={b}
                    itemDialogRef={config.itemDialogRef}
                    hideRecommendButton={config.hideRecommendationButton}
                />
            ))
    ), [config.items, config.hideRecommendationButton]);

    const hasItems = items.length > 0;

    const className = [
        "items-carousel-container",
        !hasItems && "items-carousel-container-empty"
    ].filter(i => i).join(" ");

    const isSmallScreen = ScreenModule.isSmallScreen();

    return (
        <Flex className={className} vAlign={"center"} hAlign={"center"} ref={config.dispatch("containerRef")}>
            {!hasItems &&
                <Text content={translations.get("NoItemsAvailable")} styles={{color: "darkgray"}} size={"larger"}/>
            }
            {hasItems && <>
                <Button
                    text icon={<ChevronStartIcon size={isSmallScreen ? "large" : "largest"}/>} iconOnly
                    onClick={config.carouselRef.dispatch("showPrev")}
                    disabled={config.carouselRef.state?.isPrevButtonDisabled ?? false}
                    style={{marginLeft: "-5px"}}
                />
                <Flex column className={"h-100 w-100 overflow-hidden"} styles={{padding: "0 1px"}}>
                    {config.slidesPerView &&
                        <Carousel
                            className={"items-carousel-carousel"}
                            onLoaded={config.render}
                            onButtonStateChanged={config.render}
                            externalRef={config.carouselRef}
                            slidesPerView={config.slidesPerView}
                            slidesPerGroup={config.slidesPerView}
                            spaceBetween={20}
                            items={items}
                        />
                    }
                </Flex>
                <Button
                    text icon={<ChevronEndIcon size={isSmallScreen ? "large" : "largest"}/>} iconOnly
                    onClick={config.carouselRef.dispatch("showNext")}
                    disabled={config.carouselRef.state?.isNextButtonDisabled ?? false}
                    style={{marginRight: "-5px"}}
                />
            </>}
        </Flex>
    )
}