import {useMsTeamsSelector} from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useMemo} from "react";
import {DeeplinkContext} from "../interfaces/DeeplinkContext";

export const useDeeplinkContext = () => {
    const {subEntityId} = useMsTeamsSelector("subEntityId");

    return useMemo(() => {
        if (!subEntityId || !subEntityId.startsWith("{") || !subEntityId.endsWith("}")) return undefined;
        return JSON.parse(subEntityId) as DeeplinkContext;
    }, [subEntityId]);
}