import {useQuery} from "react-query";
import {GraphService} from "../GraphService/GraphService";

export const useUserThumbnailCache = (id: string | undefined) => {
    const {data: imageUrl, isLoading} = useQuery("userThumbnail" + id, async () => {
        if (!id) return undefined;
        return await GraphService.getUserPictureAsync(id);
    }, {staleTime: Infinity, enabled: !!id});

    return {imageUrl, isLoading};
};