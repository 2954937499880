import {State} from "./Configuration.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {Notification} from "../../../interfaces/Notification";
import {GraphService} from "../../../services/GraphService/GraphService";
import {IFormItemValue} from "@witivio_teamspro/northstar-form";
import React from "react";
import {GuidModule} from "../../../modules/Guid.module";
import {useNotificationTemplatesCache} from "../../../services/CacheService/notificationTemplatesCache";
import {NotificationSentDialogRef} from "../NotificationSentDialog/NotificationSentDialog.interfaces";
import {ErrorModule} from "../../../components/ErrorBoundary/ErrorBoundary";
import {ReportingApi} from "../../../apis/Reporting/ReportingApi";
import {Constants} from "../../../const/Constants";
import {DeeplinkContextType} from "../../../interfaces/DeeplinkContext";

export const initialState: State = {
    isSendingNotification: false,
    isAddingTemplate: false,
}

export const reducer = (config: {
    dataRef: React.MutableRefObject<Record<string, IFormItemValue>>,
    resetForm: () => void,
    templateRef: React.MutableRefObject<Immutable<Notification> | undefined>,
    addTemplate: ReturnType<typeof useNotificationTemplatesCache>["addTemplate"],
    notificationSentDialogRef: NotificationSentDialogRef,
}) => ({
    applyTemplate: ({render}, [template]: [Immutable<Notification>]) => {
        config.templateRef.current = template;
        render();
        setTimeout(config.resetForm, 0);
    },
    sendNotification: async ({setState}) => {
        const {dataRef, notificationSentDialogRef} = config;
        setState({isSendingNotification: true});
        const notification = extractNotificationFromDataRef(dataRef);
        const userIds = await ReportingApi.getSessionsUserIds();
        if (userIds.length === 0) {
            setState({isSendingNotification: false});
            return ErrorModule.showErrorAlert("No user found to send notification");
        }
        await GraphService.sendNotification({
            appName: Constants.appName,
            userIds,
            title: notification.title,
            subtitle: notification.subtitle,
            deeplinkContext: {
                type: DeeplinkContextType.Notification,
                id: notification.id
            },
            entityId: "starvoice",
        });
        setState({isSendingNotification: false});
        notificationSentDialogRef.dispatch("open")();
    },
    saveTemplate: async ({setState}) => {
        const {dataRef, addTemplate} = config;
        const notification = extractNotificationFromDataRef(dataRef);
        setState({isAddingTemplate: true});
        await addTemplate(notification);
        setState({isAddingTemplate: false});
    }
}) satisfies MagicReducerObject<State>;

const extractNotificationFromDataRef = (dataRef: React.MutableRefObject<Record<string, IFormItemValue>>) => {
    const title = (dataRef.current["title"] ?? "") + "";
    const subtitle = (dataRef.current["subtitle"] ?? "") + "";
    const notification: Notification = {
        id: GuidModule.generateGUID(),
        title,
        subtitle,
    }
    return notification;
}