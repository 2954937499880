import {useQuery} from "react-query";
import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";

export const bestPracticesCacheKey = "bestPractices";

export const useBestPracticesCache = (enabled: boolean = false) => {
    const {data: bestPractices} = useQuery(bestPracticesCacheKey, BestPracticeApi.getAll, {
        staleTime: Infinity,
        enabled
    });

    return {bestPractices};
};