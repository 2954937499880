import * as React from "react";
import {memo, ReactElement, useEffect} from "react";
import {Props} from "./PopupMenuButton.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer, stopPropagation} from "./PopupMenuButton.reducer";
import "./PopupMenuButton.styles.scss";
import {Flex, MenuButton, Popup} from "@fluentui/react-northstar";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const PopupMenuButton = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState);
    const {isTouchScreen} = useMsTeamsSelector("isTouchScreen");

    useEffect(function handleCloseOnOutsideClick() {
        if (!state.open) return;
        window.document.body.addEventListener("click", dispatch("close"));
        window.document.body.addEventListener("touchmove", dispatch("close"));
        return () => {
            window.document.body.removeEventListener("click", dispatch("close"));
            window.document.body.removeEventListener("touchmove", dispatch("close"));
        }
    }, [state.open]);

    const fillTrigger = "fill" in props.trigger && props.trigger.fill;

    const trigger = "fill" in props.trigger ? props.trigger.trigger : props.trigger;

    const triggerElement = (
        <Flex fill={fillTrigger} onClick={dispatch("toggleOpen")} ref={dispatch("triggerRef")}
              {...(!isTouchScreen && props.on === "hover" && {onMouseEnter: dispatch("open")})}
              {...(!isTouchScreen && {onMouseLeave: dispatch("handleLeaveTrigger")})}>
            {trigger}
        </Flex>
    )

    const menuButton = (
        <MenuButton
            open
            on={props.on ?? "click"}
            onClick={props.closeOnClick ? dispatch("close") : stopPropagation}
            className={"no-select " + (props.className ?? "")}
            menu={props.menu}
            {...(!isTouchScreen && {
                mouseLeaveDelay: props.mouseLeaveDelay ?? 300,
                onOpenChange: dispatch("handlePopupHoverOpenChange"),
            })}
        />
    )

    return (
        <Popup
            open={state.open}
            offset={props.offset ?? [0, 0]}
            closeOnScroll
            {...(props.position && {position: props.position})}
            {...(props.align && {align: props.align})}
            trigger={triggerElement}
            content={{
                className: "popup-menu-button",
                content: menuButton,
                onClick: stopPropagation,
            }}
        />
    )
}, (prevProps, nextProps) => (
    prevProps.menu === nextProps.menu &&
    prevProps.trigger === nextProps.trigger
));