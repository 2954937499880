import {NavbarItem, Props, State} from "./Navbar.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useNavigate} from "react-router-dom";
import {AppView} from "../../interfaces/AppView/AppView";
import {AppViews} from "../../const/AppViews";
import {translations} from "../../translations";
import {HistoryService} from "../../services/HistoryService/HistoryService.hook";
import {bestPracticesViewCacheKey} from "../../views/BestPractices/BestPractices.reducer";
import {trainingsViewCacheKey} from "../../views/Trainings/Trainings.reducer";
import {homeViewCacheKey} from "../../views/Home/Home.reducer";
import {UserRole} from "../../interfaces/UserRole";
import {MutableRefObject} from "react";

export const initialState = (props: Props): State => (
    HistoryService.getComponentState("navbar") ?? {
        activeItem: props.initialActiveItem ?? AppView.Home,
        smallButtons: showSmallButtons(),
    }
);

export const reducer = (config: {
    props: Props,
    buttonsParentContainerRef: MutableRefObject<HTMLDivElement | null>,
    buttonsContainerRef: MutableRefObject<HTMLDivElement | null>,
}) => ({
    onMount: (reducerData, __, navigate: ReturnType<typeof useNavigate>) => {
        reducer(config).handleNavigateToView(reducerData, undefined, {navigate, id: reducerData.state.activeItem});
    },
    handleMenuItemSelected: (reducerData, [item]: [AppView], navigate: ReturnType<typeof useNavigate>) => {
        reducer(config).handleNavigateToView(reducerData, undefined, {navigate, id: item});
    },
    handleNavigateToView: ({state, setState}, __, extraArg: {
        navigate: ReturnType<typeof useNavigate>,
        id: AppView,
        clearCachedStates?: boolean
    }) => {
        const {navigate, id, clearCachedStates} = extraArg;
        const path = AppViews[id].path;
        if (path === window.location.pathname) {
            config.props.onRefreshLocation?.();
        } else {
            setState({activeItem: id});
            navigate(path);
            if (clearCachedStates) removeCachedStates(id);
            HistoryService.saveComponentState("navbar", state, 100);
        }
    },
    openHelpPage: () => {
        window.open(process.env.REACT_APP_HELP_PAGE_URL, "_blank");
    },
    openLibraryForm: () => {
        window.open(process.env.REACT_APP_LIBRARY_FORM_URL, "_blank");
    },
    windowResize: ({state, setState}) => {
        const {buttonsContainerRef, buttonsParentContainerRef} = config;
        zoomOutButtonsContainer({buttonsContainerRef, buttonsParentContainerRef});
        const smallButtons = showSmallButtons();
        if (smallButtons === state.smallButtons) return;
        setState({smallButtons});
    }
}) satisfies MagicReducerObject<State>;

export const zoomOutButtonsContainer = ({buttonsParentContainerRef, buttonsContainerRef}: {
    buttonsParentContainerRef: MutableRefObject<HTMLDivElement | null>,
    buttonsContainerRef: MutableRefObject<HTMLDivElement | null>,
}) => {
    const parentContainerWidth = buttonsParentContainerRef.current?.clientWidth ?? 0;
    const containerWidth = buttonsContainerRef.current?.clientWidth ?? 0;
    if (containerWidth < parentContainerWidth) return;
    let zoom = parentContainerWidth / containerWidth;
    buttonsContainerRef.current?.style.setProperty("zoom", zoom.toString());
}

const showSmallButtons = () => {
    return window.innerWidth <= 1050;
}

export const generateItems = (userRole: UserRole | undefined): Array<NavbarItem> => {
    const views = [AppView.Dashboard, AppView.BestPractices, AppView.Trainings, AppView.Reporting];
    if (userRole === UserRole.Admin) views.push(AppView.Notifications);
    return views.map((id): NavbarItem => {
        const view = AppViews[id];
        return {
            id,
            icon: view.icon,
            label: translations.get(view.titleId),
        }
    })
}

const removeCachedStates = (currentView: AppView) => {
    switch (currentView) {
        case AppView.Home:
            HistoryService.clearAllStates(bestPracticesViewCacheKey);
            HistoryService.clearAllStates(trainingsViewCacheKey);
            break;
        case AppView.BestPractices:
            HistoryService.clearAllStates(homeViewCacheKey);
            HistoryService.clearAllStates(trainingsViewCacheKey);
            break;
        case AppView.Trainings:
            HistoryService.clearAllStates(bestPracticesViewCacheKey);
            HistoryService.clearAllStates(homeViewCacheKey);
            break;
    }
}