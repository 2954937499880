import {Props, State} from "./Carousel.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import SwiperCore from "swiper";

export const initialState: State = {
    swiper: null,
    isPrevButtonDisabled: true,
    isNextButtonDisabled: false,
}

export const reducer = (props: Props) => ({
    swiperRef: ({setState}, [ref]: [SwiperCore]) => {
        setState({swiper: ref}, false);
    },
    showPrev: ({state}) => {
        state.swiper?.slidePrev();
    },
    showNext: ({state}) => {
        state.swiper?.slideNext();
    },
    slideTo: ({state}, payload: [index: number, speed: number]) => {
        state.swiper?.slideTo(payload[0], payload[1]);
    },
    handleIndexChanged: (reducerData) => {
        const {state, setState} = reducerData;
        const activeIndex = (state.swiper?.activeIndex ?? 0) * (props.grid?.rows ?? 1);
        const lastIndex = props.items.length - ((props.slidesPerView as number ?? 0) * (props.grid?.rows ?? 1));
        const isPrevButtonDisabled = activeIndex <= 0;
        const isNextButtonDisabled = activeIndex >= lastIndex;
        const hasPrevButtonStateChanged = state.isPrevButtonDisabled !== isPrevButtonDisabled;
        const hasNextButtonStateChanged = state.isNextButtonDisabled !== isNextButtonDisabled;
        setState({isPrevButtonDisabled, isNextButtonDisabled}, false);
        if (hasPrevButtonStateChanged || hasNextButtonStateChanged) props.onButtonStateChanged();
    },
    handleSlideChange: (reducerData) => {
        reducer(props).handleIndexChanged(reducerData);
    },
}) satisfies MagicReducerObject<State>;