import {Props, State} from "./MeetingCard.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import moment from "moment/moment";
import * as microsoftTeams from "@microsoft/teams-js";
import {Meeting} from "../../interfaces/Meeting";

export const initialState: State = {}

export const reducer = (props: Props) => ({
    handleJoinMeeting: async (_, __, isTeamsApp: boolean | undefined) => {
        const joinUrl = props.meeting?.joinUrl;
        if (!joinUrl) return;
        if (isTeamsApp) await microsoftTeams.app.openLink(joinUrl);
        else window.open(joinUrl, "_blank");
    },
    handleShowMeetingDetails: async (_, __, isTeamsApp: boolean | undefined) => {
        const formUrl = props.meeting?.formUrl;
        if (!formUrl) return;
        if (isTeamsApp) await microsoftTeams.app.openLink(formUrl);
        else window.open(formUrl, "_blank");
    }
}) satisfies MagicReducerObject<State>;

export const canJoinMeeting = (meeting: Immutable<Meeting> | undefined) => {
    if (!meeting || !meeting.joinUrl) return false;
    const now = moment();
    const start = moment(meeting.start);
    const end = moment(meeting.end);
    return now.isBetween(start, end);
}