import {IColor} from "interfaces/IColor";
import {ReactElement} from "react";
import {Immutable, MagicDispatch, MagicReducerRef} from "@witivio_teamspro/use-reducer";
import {reducer} from "./Table.reducer";

export type State = {
    mounted: boolean,
    columnSort: string | null,
    columnFilters: Map<string, ColumnFilterData>,
    sortAscending: boolean,
    visibleItemsCount: number,
    visibleRange: [number, number],
    editedItem: ITableEditedItem | undefined,
    prevSkip: number | undefined,
    skip: number | undefined,
    columnFiltersTimeout: NodeJS.Timeout | undefined,
    tableContainer: HTMLDivElement | null,
    table: HTMLDivElement | null,
}

export type Props<T> = {
    show?: boolean,
    items: Array<ITableItem<T>> | undefined,
    columns: Array<ITableColumn<ITableItem<T>>>,
    actions?: {
        items: Array<ITableRowAction<ITableItem<T>>>,
        icon?: ReactElement | ((item: ITableItem<T>) => ReactElement),
        backgroundColor?: IColor | ((item: ITableItem<T>) => IColor | undefined),
    },
    rowHeight?: number,
    debugMode?: boolean,
    initialSortedColumn?: string | undefined,
    initialSortOrder?: "ascending" | "descending" | undefined,
    fill?: boolean,
    fetchOnScroll?: boolean | undefined,
    fetchNextItems?: ((skip: number, take: number) => void | Promise<void>) | undefined,
    onFilterByColumn?: ((columnFilters: Record<string, string>) => void) | undefined,
    onSortColumn?: ((columnSort: string | null, sortAscending: boolean) => void) | undefined,
    filterByColumnThrottlingInMs?: number | undefined,
    defaultColumnFilters?: Record<string, string> | undefined,
    externalRef?: MagicReducerRef<typeof reducer<T>>,
    sortLocally?: boolean | undefined,
}

export type TableRef<T> = Exclude<Props<T>["externalRef"], undefined>;

export type TableProps<T> = Props<T>;

export type Logic<T> = {
    show: boolean,
    columns: Array<ITableColumn<ITableItem<T>>>
    sortAscending: boolean
    columnSort: string | null
    getColumnByField: (field: string) => ITableColumn<ITableItem<T>>
    visibleRange: Immutable<State["visibleRange"]>,
    actionColumnWidth: number
    editedItem: ITableEditedItem | undefined
    showActionColumn: boolean
    items: Array<ITableItem<any>>
    actions: {
        items: Array<ITableRowAction<ITableItem<T>>>
        icon?: ReactElement | ((item: ITableItem<T>) => ReactElement)
        backgroundColor?: IColor | ((item: ITableItem<T>) => (IColor | undefined))
    } | undefined
    columnFilters: Immutable<State["columnFilters"]>,
    isTouchScreen: boolean,
    hideFilters: boolean,
    dispatch: MagicDispatch<ReturnType<typeof reducer<T>>>
}

export type ColumnFilterData = { type: TableColumnFilterType, text: string, regexp?: RegExp };

export enum TableColumnFilterType {
    Contains, StartWith, EndWith
}

export interface ITableEditedItem {
    itemId: string,
    field: string,
}

export type ITableColumnWidth = `${number}px` | `${number}%`;

export interface ITableColumn<T> {
    field: string,
    label: string,
    icon?: ReactElement,
    minWidth?: ITableColumnWidth,
    maxWidth?: ITableColumnWidth,
    render: (item: ITableItem<T>) => string | number | ReactElement | null,
    sort?: (a: ITableItem<T>, b: ITableItem<T>) => number,
    skeleton?: ReactElement,
    backgroundColor?: IColor | ((item: ITableItem<T>) => IColor | undefined),
    renderEditField?: (item: ITableItem<T>, onEditDone: () => void) => ReactElement,
    onClick?: (item: ITableItem<T>) => void,
    getFilterValue?: (item: ITableItem<T>) => string,
    filterPlaceholder?: string,
}

export interface ITableRowAction<T> {
    label: string | ((item: ITableItem<T>) => string),
    icon?: ReactElement | ((item: ITableItem<T>) => ReactElement | null) | null,
    onClick?: (item: ITableItem<T>) => void,
    isVisible?: (item: ITableItem<T>) => boolean,
    menu?: Array<ITableRowAction<T>>
}

export type ITableItem<T> = null | (T & { id: string });