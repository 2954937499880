import {useQuery} from "react-query";
import {ReportingApi} from "../../apis/Reporting/ReportingApi";
import {UserReport} from "../../interfaces/UserReport";
import {queryClient} from "../../index";

const userReportCacheKey = "userReport";

export const useUserReportCache = () => {
    const {data: userReport} = useQuery(userReportCacheKey, ReportingApi.getMyReport, {staleTime: Infinity});
    return {
        userReport,
        incrementReportValue,
        decrementReportValue,
    };
};

const incrementReportValue = (key: keyof UserReport, value: number = 1) => {
    const localUserReport = queryClient.getQueryData<UserReport | undefined>(userReportCacheKey);
    if (!localUserReport) return;
    const report = {...localUserReport, [key]: localUserReport[key] + value};
    queryClient.setQueryData(userReportCacheKey, report);
}

const decrementReportValue = (key: keyof UserReport, value: number = 1) => {
    incrementReportValue(key, -value);
}

export const UserReportCacheActions = {
    incrementReportValue,
    decrementReportValue,
}