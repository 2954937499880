import React, {memo, ReactElement, useEffect, useRef} from "react";
import {Props} from "./EditMessageDialog.interfaces";
import {ImmutableObject, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./EditMessageDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./EditMessageDialog.styles.scss";
import {FormItem, useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "translations";
import {
    AddIcon,
    Button,
    Divider,
    Flex,
    PopupIcon,
    RetryIcon,
    SaveIcon,
    Text,
    TrashCanIcon
} from "@fluentui/react-northstar";
import {MessageOfTheDayButton} from "interfaces/MessageOfTheDay";
import {Dialog} from "../../../components/Dialog/Dialog";
import {DialogRef} from "../../../components/Dialog/Dialog.interfaces";
import {ScreenModule} from "../../../modules/Screen.module";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import scssVariables from "variables.module.scss";
import {MentionModule} from "../../../modules/Mention.module";

export const EditMessageDialog = memo((props: Props): ReactElement | null => {
    const {userId, locale} = useMsTeamsSelector("userId", "locale");

    const initialMessageRef = useRef(props.message.message);

    const dialogRef = useMagicReducerRef<DialogRef>();

    const isSmallScreen = ScreenModule.isSmallScreen();

    const formData = useForm({
        locale,
        fluid: true,
        items: [
            {
                id: "message",
                type: "richTextInput",
                placeholder: translations.get("WriteYourMessage"),
                height: "100%",
                maxLength: 400,
                initialValue: initialMessageRef.current,
                showMentions: true,
                onSuggestMentions: MentionModule.fetchSuggestions(userId),
                mentionTextColor: scssVariables.orangeColor,
            },
            {
                id: "visible",
                type: "checkbox",
                toggle: true,
                label: translations.get(isSmallScreen ? "Show" : "ShowMessage"),
                initialValue: props.message.show,
            },
        ]
    }, [initialMessageRef.current]);

    const currentMessage = (formData.dataRef.current?.message ?? "") + "";

    useEffect(function onMessageRefChange() {
        formData.reset();
    }, [props.message]);

    useEffect(function onButtonsChange() {
        buttonsRef.current = [...props.message.buttons ?? []];
        render();
    }, [props.message.buttons]);

    useEffect(function onCurrentMessageChange() {
        if (!dialogRef.state?.isOpen) return;
        dispatch("saveDraftMessage")(currentMessage)
    }, [currentMessage]);

    const buttonsRef = React.useRef<ImmutableObject<MessageOfTheDayButton>[]>([...props.message.buttons ?? []]);

    const buttonsForm = useForm({
        locale,
        fluid: true,
        items: [
            ...buttonsRef.current.map((button): Array<FormItem> => [
                {
                    id: button.id + "_title",
                    type: "input",
                    inputMode: "text",
                    placeholder: translations.get("EnterTitle"),
                    initialValue: button.title,
                    maxLength: 50,
                },
                {
                    id: button.id + "_link",
                    type: "input",
                    inputMode: "url",
                    placeholder: translations.get("EnterLink"),
                    initialValue: button.link,
                    maxLength: 500,
                }
            ]).flat()
        ]
    }, [buttonsRef.current]);

    const reducerConfig = {props, formData, buttonsForm, buttonsRef, dialogRef, initialMessageRef};
    const [state, dispatch, render] = useMagicReducer(reducer(reducerConfig), initialState, props.externalRef);

    useEffect(function onDraftRetrieved() {
        dispatch("handleDraftRetrieved")();
    }, [state.canRetrieveDraft]);

    const canPublish = !state.isSaving && formData.isValid && buttonsForm.isValid;

    const canAddButton = state.buttons.length < 5;

    const buttons = (
        <Flex fill column className={"overflow-hidden"} gap={"gap.small"}>
            <Flex className={"no-shrink"}>
                <Button
                    fluid icon={<AddIcon outline/>}
                    content={translations.get("AddButton")}
                    onClick={dispatch("handleAddButton")}
                    disabled={!canAddButton}
                />
            </Flex>
            <Flex fill column className={"overflow-scroll"} gap={"gap.small"}>
                {state.buttons.map((b, index) => (
                    <Flex column key={b.id}>
                        <Flex styles={{gap: "6px"}} vAlign={"center"}>
                            <PopupIcon outline/>
                            <Text content={translations.get("CustomButton") + " " + (index + 1)}/>
                        </Flex>
                        <Flex gap={"gap.smaller"} vAlign={isSmallScreen ? "center" : "start"}>
                            <Flex className={"button-color-picker"}>
                                <input
                                    defaultValue={b.color}
                                    onChange={dispatch("handleSetButtonColor", b.id)}
                                    type={"color"}
                                />
                            </Flex>
                            <Flex fill gap={"gap.smaller"} column={isSmallScreen} vAlign={"start"}>
                                {buttonsForm.formItems[b.id + "_title"]}
                                {buttonsForm.formItems[b.id + "_link"]}
                            </Flex>
                            <Button
                                className={"button-delete-button"} text iconOnly
                                icon={<TrashCanIcon outline/>}
                                onClick={dispatch("handleDeleteButton", b.id)}
                            />
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );

    const footer = (
        <Flex className={"w-100"} space={"between"} vAlign={"center"}>
            {formData.formItems["visible"]}
            <Button
                primary icon={<SaveIcon size={"large"} outline/>}
                content={translations.get("Save")}
                onClick={dispatch("handleSave")}
                disabled={!canPublish}
                loading={state.isSaving}
            />
        </Flex>
    )

    return (
        <Dialog
            title={translations.get("MessageOfTheDay")}
            width={1000}
            externalRef={dialogRef}
            closeOnOutsideClick={false}
            onClose={dispatch("removeDraftMessage")}
            renderContent={() => (
                <Flex fill gap={"gap.medium"} className={"edit-message-dialog x-padding y-padding"}>
                    <Flex fill column={isSmallScreen} gap={"gap.medium"} className={"overflow-hidden"}
                          styles={{height: isSmallScreen ? "100%" : "300px"}}>
                        <Flex fill column gap={"gap.small"}>
                            {state.canRetrieveDraft &&
                                <Flex>
                                    <Button
                                        icon={<RetryIcon outline/>} fluid
                                        content={translations.get("RetrieveDraft")}
                                        onClick={dispatch("handleRetrieveDraft")}
                                    />
                                </Flex>
                            }
                            <Flex fill className={"custom-rich-text-editor"}>
                                {formData.formItems["message"]}
                            </Flex>
                        </Flex>
                        <Divider vertical={!isSmallScreen}/>
                        {buttons}
                    </Flex>
                </Flex>
            )}
            renderFooter={() => footer}
        />
    )
}, CompareModule.areObjectsEqual);