import {HostClientType, UserTeamRole} from "@microsoft/teams-js";
import {ThemePrepared} from "@fluentui/react-northstar";

export const MSTEAMS_INIT = "ms-teams/init";

export enum ThemeClass {
    Default = "teamsDefault",
    Dark = "teamsDark",
    HighContrast = "teamsHighContrast",
}

export interface MicrosoftTeamsReducerState {
    loaded: boolean,
    locale: string,
    fullLocale: string,
    themeClass: ThemeClass,
    groupId: string,
    channelId: string,
    hostClientType: HostClientType | undefined,
    teamId: string,
    teamName: string,
    tenantId: string,
    channelName: string,
    userMail: string,
    userName: string,
    userId: string,
    isConfiguringApp: boolean,
    isDeletingApp: boolean,
    isOnMobile: boolean,
    isInPersonalApp: boolean,
    subEntityId: string,
    isInTaskModule: boolean,
    entityId: string,
    isInMeeting: boolean,
    meetingId: string,
    isInMeetingSidePanel: boolean,
    userRole: UserTeamRole,
    isTouchScreen: boolean,
    isTeamsIframe: boolean,
    isLightTheme: boolean,
    theme: ThemePrepared,
}