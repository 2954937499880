import React, {ReactElement} from "react";
import {Props} from "./ItemsStatsKpi.interfaces";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {Table} from "../../../../components/Table/Table";
import {useItemStatsTableColumns} from "./hooks/useItemStatsTableColumns";
import {ItemData} from "../../../../interfaces/ItemData";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useMsTeamsSelector} from "../../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {translations} from "../../../../translations";
import {ItemDataModule} from "../../../../modules/ItemData.module";

export const ItemsStatsKpi = (props: Props): ReactElement | null => {
    const {locale, userMail} = useMsTeamsSelector("locale", "userMail");

    const columns = useItemStatsTableColumns(props);

    const {formItems, dataRef} = useForm({
        locale,
        fluid: true,
        items: [{
            id: "search",
            type: "input",
            inputMode: "text",
            placeholder: translations.get("SearchFile"),
        }]
    }, []);

    const filteredItems = ItemDataModule.filterItems({
        items: props.items,
        query: (dataRef.current["search"] ?? "") + "",
        userUpn: userMail,
    });

    return (
        <Flex column fill className={"overflow-hidden"}>
            <Flex gap={"gap.medium"} className={"no-shrink"}>
                <Flex fill>
                    {props.title && <Text truncated weight={"semibold"} size={"larger"} content={props.title}/>}
                </Flex>
                <Flex styles={{marginTop: "-5px", minWidth: "250px"}}>
                    {!props.showSkeletons && formItems["search"]!}
                </Flex>
            </Flex>
            {props.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Table<Immutable<ItemData>>
                    // @ts-ignore
                    items={filteredItems}
                    filterByColumnThrottlingInMs={500}
                    columns={columns}
                    sortLocally
                    initialSortedColumn={"clicks"}
                    initialSortOrder={"descending"}
                />
            }
        </Flex>
    );
}