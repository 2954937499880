import {HomeLogic} from "../Home.interfaces";
import {Button, EditIcon, EyeSlashIcon, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import MarkdownView from "react-showdown";
import {ColorModule} from "../../../modules/Color.module";
import React from "react";
import {EditMessageDialog} from "../EditMessageDialog/EditMessageDialog";
import {useUserRoleCache} from "../../../services/CacheService/userRoleCache";

export const News = (logic: HomeLogic) => {
    const {isBasicUser} = useUserRoleCache();

    const hideNews = !logic.messageOfTheDay.show && isBasicUser;

    if (logic.showSearchResults || hideNews) return null;

    const showButtons = (logic.messageOfTheDay.buttons?.length ?? 0) > 0;

    return <>
        {!isBasicUser &&
            <EditMessageDialog
                externalRef={logic.editMessageDialogRef}
                onMessageEdited={logic.dispatch("handleMessageOfTheDayEdited")}
                message={logic.messageOfTheDay}
            />
        }
        <Flex column className={"home-message-of-the-day-part"}>
            <Flex gap={"gap.small"} vAlign={"center"}>
                {!logic.messageOfTheDay.show &&
                    <EyeSlashIcon className={"primary-text"}/>
                }
                <Text
                    className={"home-view-content-title"}
                    size={"larger"}
                    weight={"bold"}
                    content={translations.get("News")}
                />
                {!isBasicUser &&
                    <Button
                        styles={{marginTop: "2px", marginLeft: "-2px"}}
                        primary
                        iconOnly
                        icon={<EditIcon/>}
                        text
                        onClick={logic.dispatch("handleEditMessageOfTheDay")}
                    />
                }
            </Flex>
            <Text
                className={"home-view-content-subtitle"}
                content={translations.get("HomeViewMessageOfTheDayPartSubtitle")}
            />
            <Flex column className={"home-message-of-the-day-container"}>
                <MarkdownView className={"markdown-view"} markdown={logic.messageOfTheDay.message ?? ""}/>
                {showButtons &&
                    <Flex gap={"gap.small"}>
                        {logic.messageOfTheDay.buttons?.map(b => (
                            <Button
                                key={b.id}
                                primary
                                className={"custom-button"}
                                styles={{
                                    backgroundColor: b.color + " !important",
                                    color: ColorModule.getContrastingTextColor(b.color) + " !important",
                                }}
                                content={b.title}
                                onClick={logic.dispatch("handleOpenLink", b.link)}
                            />
                        ))}
                    </Flex>
                }
            </Flex>
        </Flex>
    </>
}