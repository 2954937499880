import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import {AxiosError} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";

const prepareData = () => new Promise<void>(async (resolve) => {
    try {
        await AxiosConfig.instance.get("/prepare");
        return resolve();
    } catch (error) {
        ErrorModule.showErrorAlert("Can't prepare data", error as AxiosError);
        resolve();
    }
});

export const PrepareApi = {
    prepareData
}