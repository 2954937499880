import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./MeetingCard.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {canJoinMeeting, initialState, reducer} from "./MeetingCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./MeetingCard.styles.scss";
import moment from "moment/moment";
import {Button, CallVideoIcon, ChevronEndIcon, Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {useMsTeamsCache} from "../../services/CacheService/msTeamsCache";
import {translations} from "../../translations";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const MeetingCard = memo((props: Props): ReactElement | null => {
    const [, dispatch] = useMagicReducer(reducer(props), initialState);

    const {isOnMobile} = useMsTeamsSelector("isOnMobile");

    const {isTeamsApp} = useMsTeamsCache();

    const isImportant = props.meeting?.isImportant ?? false;

    const hoursLabel = useMemo(() => {
        const startHour = !props.meeting ? undefined : moment(props.meeting?.start).format("HH:mm");
        const endHour = !props.meeting ? undefined : moment(props.meeting?.end).format("HH:mm");
        let label = startHour + " - " + endHour;
        if (props.showDay) {
            const day = !props.meeting ? undefined : moment(props.meeting?.start).format("MMMM Do");
            if (day) label = day + ", " + label;
        }
        return label;
    }, [props.meeting, props.showDay]);

    const showJoinButton = canJoinMeeting(props.meeting);

    const content = (
        <Flex className={"meeting-card-content"} vAlign={"center"}
              onClick={isOnMobile ? undefined : dispatch("handleShowMeetingDetails", isTeamsApp)}>
            <Flex fill column className={"overflow-hidden"}>
                {!props.meeting ?
                    <Skeleton.Line styles={{minHeight: "15px", marginBottom: "5px"}}/>
                    :
                    <Text truncated weight={"semibold"} content={props.meeting.title}/>
                }
                {!props.meeting ?
                    <Skeleton.Line styles={{minHeight: "17px", margin: 0}}/>
                    :
                    <Text truncated size={"small"} content={hoursLabel}/>
                }
            </Flex>
            {!isOnMobile &&
                <Flex styles={{margin: "0 -5px -5px 0"}}>
                    <Button text iconOnly icon={<ChevronEndIcon/>}
                            onClick={dispatch("handleShowMeetingDetails", isTeamsApp)}/>
                </Flex>
            }
        </Flex>
    )

    const joinButton = !showJoinButton ? null : (
        <Flex className={"meeting-card-join-button"} onClick={dispatch("handleJoinMeeting", isTeamsApp)}>
            <Text content={translations.get("Join")}/>
            <CallVideoIcon/>
        </Flex>
    )

    const className = [
        "meeting-card",
        isImportant && "important-meeting-card",
    ].filter(i => i).join(" ");

    return (
        <Skeleton animation={"pulse"}>
            <Flex column className={className}>
                {content}
                {joinButton}
            </Flex>
        </Skeleton>
    )
}, CompareModule.areObjectsEqual);