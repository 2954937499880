import {State} from "./Dialog.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";

export const initialState: State = {
    initialized: false,
    isOpen: false,
    hideBlurMask: true,
}

export const reducer = {
    open: ({setState}) => {
        setState({isOpen: true, hideBlurMask: false});
    },
    close: ({setState}, _, callback?: () => void) => {
        setState({isOpen: false});
        setTimeout(() => {
            setState({hideBlurMask: true});
            if (typeof callback === "function") callback();
        }, 150);
    },
    initialize: ({state, setState}) => {
        if (state.initialized) return;
        setState({initialized: true}, false);
    },
} satisfies MagicReducerObject<State>;