import axios from "axios";
import {Configuration} from "interfaces/Configuration";
import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import {MessageOfTheDay} from "../../interfaces/MessageOfTheDay";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";

const getConfiguration = () => new Promise<Configuration>(async (resolve, reject) => {
    try {
        const response = await axios.get("/api/settings");
        return resolve(response.data);
    } catch (error) {
        console.error(error);
        ErrorModule.showErrorAlert("Can't get client configuration", error as Error);
        reject("Can't get client configuration");
    }
});

const updateMessageOfTheDay = (message: MessageOfTheDay) => new Promise<void>(async (resolve) => {
    try {
        await AxiosConfig.instance.post("/settings/messageOfTheDay", message);
        return resolve();
    } catch (error) {
        console.error(error);
        ErrorModule.showErrorAlert("Can't update message of the day", error as Error);
        resolve();
    }
});

export const ConfigurationApi = {
    getConfiguration,
    updateMessageOfTheDay,
}