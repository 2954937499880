const getContrastingTextColor = (hexColor: string): 'black' | 'white' => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const luminosity = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminosity > 186 ? 'black' : 'white';
}

const colors = ["#eb5932", "#FF8C00", "#FFA500", "#FFD700", "#FF69B4", "#FF1493", "#FF00FF", "#FF00CC", "#FF69B4", "#9400D3", "#8A2BE2", "#800080", "#9932CC", "#8B008B", "#4B0082", "#483D8B", "#0000FF", "#0000CC", "#0000AA", "#000080", "#4169E1", "#00BFFF", "#87CEEB", "#00CED1", "#20B2AA", "#008B8B", "#00FFFF", "#00CED1", "#00FFFF", "#40E0D0", "#00FA9A", "#00FF7F", "#3CB371", "#2E8B57", "#008000", "#008080", "#228B22", "#32CD32", "#ADFF2F", "#7FFF00", "#556B2F", "#006400", "#00FA9A", "#00FF00", "#98FB98", "#90EE90", "#00FF00", "#7CFC00", "#00FF7F", "#FFFF00", "#FFD700", "#FFA500", "#FF8C00", "#FF6347", "#FF4500", "#FFA07A", "#FF7F50", "#FF6B4F", "#FF4500", "#FF4500", "#FF6347", "#FF4040", "#FF3030", "#A52A2A", "#8B4513", "#D2691E", "#CD853F", "#DEB887", "#F5DEB3", "#FFFACD", "#FFE4B5", "#FFE4C4", "#FFF8DC", "#FFE4E1", "#FFF0F5", "#FAEBD7", "#FFF5EE", "#FDF5E6", "#F5F5DC"];

const getColorFromIndex = (index: number): string => {
    return colors[index % colors.length] ?? "#FFF";
};

export const ColorModule = {
    getContrastingTextColor,
    getColorFromIndex,
}