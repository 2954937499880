import {TelemetryService} from "../services/TelemetryService/TelemetryService";
import {ItemDataType} from "../interfaces/ItemData";
import {AppView} from "../interfaces/AppView/AppView";
import {HostClientType} from "@microsoft/teams-js";

const notifySession = (
    userId: string | undefined,
    durationInSeconds: number,
    device: HostClientType = HostClientType.web,
    sessionId: string,
) => {
    if (!userId) return;
    TelemetryService.trackEvent("Session", {
        userId,
        duration: durationInSeconds,
        device,
        sessionId,
    });
}

const notifyQuery = (userId: string | undefined, query: string, view: AppView) => {
    if (!userId || !query) return;
    TelemetryService.trackEvent("Query", {
        userId,
        query: query.toLowerCase(),
        view: AppView[view],
    });
}

const notifyItemOpened = (userId: string | undefined, itemId: string | undefined, type: ItemDataType | undefined) => {
    if (!userId || itemId === undefined || type === undefined) return;
    TelemetryService.trackEvent("ItemOpened", {
        userId,
        id: itemId,
        type: ItemDataType[type],
    });
}

const notifyTopicSelected = (userId: string | undefined, topic: string | undefined, view: AppView) => {
    if (!userId || topic === undefined || topic.toLowerCase() === "all") return;
    TelemetryService.trackEvent("TopicSelected", {
        userId,
        topic,
        view: AppView[view],
    });
}

export const ReportingModule = {
    notifySession,
    notifyQuery,
    notifyItemOpened,
    notifyTopicSelected
}