import {AppViewData} from "interfaces/AppView/AppViewData";
import {AppView} from "interfaces/AppView/AppView";
import {Home} from "../views/Home/Home";
import {BestPractices} from "../views/BestPractices/BestPractices";
import {Trainings} from "../views/Trainings/Trainings";
import {HomeIcon, KnowledgeIcon, MegaphoneIcon, ReportIcon, TrainingIcon} from "../assets/icons";
import {Reporting} from "../views/Reporting/Reporting";
import {Notifications} from "../views/Notifications/Notifications";
import {UserThumbnail} from "../components/UserThumbnail/UserThumbnail";
import {Dashboard} from "../views/Dashboard/Dashboard";

const HomeView: AppViewData = {
    id: AppView.Home,
    path: "/",
    element: Home,
    icon: HomeIcon,
    titleId: "Home",
}

const BestPracticesView: AppViewData = {
    id: AppView.BestPractices,
    path: "/best-practices",
    element: BestPractices,
    icon: KnowledgeIcon,
    titleId: "BestPractices",
}

const TrainingsView: AppViewData = {
    id: AppView.Trainings,
    path: "/trainings",
    element: Trainings,
    icon: TrainingIcon,
    titleId: "Trainings",
}

const ReportingView: AppViewData = {
    id: AppView.Reporting,
    path: "/reporting",
    element: Reporting,
    icon: ReportIcon,
    titleId: "Reporting",
}

const NotificationsView: AppViewData = {
    id: AppView.Notifications,
    path: "/notifications",
    element: Notifications,
    icon: MegaphoneIcon,
    titleId: "Notifications",
}

const DashboardView: AppViewData = {
    id: AppView.Dashboard,
    path: "/dashboard",
    element: Dashboard,
    icon: () => <UserThumbnail size={"small"}/>,
    titleId: "MyDashboard",
}

export const AppViews: Record<AppView, AppViewData> = {
    [AppView.Home]: HomeView,
    [AppView.BestPractices]: BestPracticesView,
    [AppView.Trainings]: TrainingsView,
    [AppView.Reporting]: ReportingView,
    [AppView.Notifications]: NotificationsView,
    [AppView.Dashboard]: DashboardView,
}