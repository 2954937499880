import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./Carousel.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Carousel.reducer";
import "./Carousel.styles.scss";
import SwiperCore from 'swiper';
import {Swiper, SwiperSlide} from "swiper/react";
import {Grid, Virtual} from 'swiper/modules';

SwiperCore.use([Virtual]);

export const Carousel = memo((props: Props): ReactElement | null => {
    const [, dispatch] = useMagicReducer(reducer(props), initialState, props.externalRef);

    useEffect(() => {
        dispatch("handleIndexChanged")();
    }, [props.items]);

    const modules = [Virtual];
    if (props.grid) modules.push(Grid);

    return (
        <Swiper
            onSwiper={dispatch("swiperRef")}
            onSlideChange={dispatch("handleSlideChange")}
            virtual
            modules={modules}
            {...props.cssMode && {cssMode: props.cssMode}}
            {...props.slidesPerView && {slidesPerView: props.slidesPerView}}
            {...props.slidesPerGroup && {slidesPerGroup: props.slidesPerGroup}}
            {...props.spaceBetween && {spaceBetween: props.spaceBetween}}
            {...props.className && {className: props.className}}>
            {props.items.map((i, index) => (
                <SwiperSlide key={i.key} virtualIndex={index}>
                    {i}
                </SwiperSlide>
            ))}
        </Swiper>
    )
}, (prevProps, nextProps) => (
    prevProps.cssMode === nextProps.cssMode &&
    prevProps.slidesPerView === nextProps.slidesPerView &&
    prevProps.slidesPerGroup === nextProps.slidesPerGroup &&
    prevProps.spaceBetween === nextProps.spaceBetween &&
    prevProps.className === nextProps.className &&
    prevProps.externalRef === nextProps.externalRef &&
    prevProps.items === nextProps.items
));