import React, {memo, ReactElement} from "react";
import {Props} from "./Preview.interfaces";
import {CompareModule} from "modules/Compare.module";
import "./Preview.styles.scss";
import {Avatar, Flex, Image, Text} from "@fluentui/react-northstar";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useUserThumbnailCache} from "../../../services/CacheService/userThumbnailCache";
import {translations} from "../../../translations";
import AppIcon from "assets/images/app_icon.jpg";
import moment from "moment";

export const Preview = memo((props: Props): ReactElement | null => {
    const {userId, userName} = useMsTeamsSelector("userId", "userName");
    const {imageUrl} = useUserThumbnailCache(userId);

    const title = !props.title ? translations.get("Title") : props.title;
    const subtitle = !props.subtitle ? translations.get("Subtitle") : props.subtitle;

    return (
        <Flex className={"notification-preview"}>
            <Flex className={"no-shrink pos-relative"} styles={{marginRight: "8px"}}>
                <Avatar image={imageUrl} name={userName}/>
                <Image src={AppIcon} className={"notification-app-icon"}/>
            </Flex>
            <Flex fill column className={"overflow-hidden"} styles={{marginTop: "-3px"}}>
                <Flex className={"overflow-hidden"} gap={"gap.smaller"}>
                    <Text
                        weight={"bold"}
                        className={"w-100 notification-title"}
                        content={title}
                    />
                    <Text
                        className={"no-shrink notification-subtitle"}
                        size={"small"} content={moment().format("LT")}
                        styles={{marginTop: "2px"}}
                    />
                </Flex>
                <Flex className={"overflow-hidden"}>
                    <Text className={"notification-subtitle"} content={subtitle}/>
                </Flex>
                <Flex className={"overflow-hidden"}>
                    <Text size={"small"} weight={"light"} className={"notification-app-title"} content={"Starvoice"}/>
                </Flex>
            </Flex>
        </Flex>
    );
}, CompareModule.areObjectsEqual);