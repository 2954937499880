import {useQuery} from "react-query";
import {TrainingApi} from "../../apis/Training/TrainingApi";

export const trainingsCacheKey = "trainings";

export const useTrainingsCache = (enabled: boolean = false) => {
    const {data: trainings} = useQuery(trainingsCacheKey, TrainingApi.getAll, {
        staleTime: Infinity,
        enabled,
    });

    return {trainings};
};