import {MentionItem} from "@witivio_teamspro/northstar-form";
import {GraphService} from "../services/GraphService/GraphService";

export const fetchSuggestions = (userId: string) => async (query: string): Promise<Array<MentionItem>> => {
    const users = !query ?
        await GraphService.getRelevantPeople(userId, 3) : await GraphService.searchForUsersAsync(query);
    return users.map(u => ({
        id: u.userPrincipalName,
        name: u.displayName,
        pictureUrl: u.picture,
    }));
}

const extractMentions = (inputText: string): Array<{ mentionId: string, mentionText: string }> => {
    const mentionsRegex = /<span mentionid="(.+?)">(.+?)<\/span>/g;
    const matches = Array.from(inputText.matchAll(mentionsRegex));
    return matches.filter(match => !!match[1] && !!match[2]).map(match => ({
        mentionId: match[1] ?? "", mentionText: match[2] ?? ""
    }));
};

export const MentionModule = {
    fetchSuggestions,
    extractMentions
}