import React, {memo, ReactElement} from "react";
import {Props} from "./ItemDialog.interfaces";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./ItemDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ItemDialog.styles.scss";
import {useForm} from "@witivio_teamspro/northstar-form";
import {translations} from "translations";
import {Button, ChatIcon, ComponentSlotStyle, Divider, Flex, Loader, SendIcon, Text} from "@fluentui/react-northstar";
import {ItemData} from "interfaces/ItemData";
import {ItemCard} from "../ItemCard/ItemCard";
import {DialogRef} from "../Dialog/Dialog.interfaces";
import {Dialog} from "../Dialog/Dialog";
import {ScreenModule} from "../../modules/Screen.module";
import scssVariables from "../../variables.module.scss";
import {MentionModule} from "../../modules/Mention.module";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {SharepointComment} from "../../interfaces/SharepointComment";
import {CommentCard} from "../CommentCard/CommentCard";
import {useItemCommentsCache} from "../../services/CacheService/commentsCache";
import {useMyRecommendationsCache} from "../../services/CacheService/recommendationsCache";

export const ItemDialog = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const dialogRef = useMagicReducerRef<DialogRef>();
    const {canRecommendItem} = useMyRecommendationsCache();

    const isSmallScreen = ScreenModule.isSmallScreen();

    const formData = useForm({
        locale: "en",
        fluid: true,
        items: [
            {
                id: "comment",
                required: true,
                type: "richTextInput",
                placeholder: translations.get("WriteYourComment"),
                height: isSmallScreen ? "100px" : "150px",
                maxLength: 300,
                showMentions: true,
                onSuggestMentions: MentionModule.fetchSuggestions(userId),
                mentionTextColor: scssVariables.orangeColor,
                hideToolbar: true,
            },
        ]
    }, []);

    const [state, dispatch] = useMagicReducer(reducer(formData, dialogRef), initialState, props.externalRef);

    const {comments} = useItemCommentsCache(state.item?.type, state.item?.id);

    const canPublish = !state.isPublishing && formData.isValid;

    return (
        <Dialog
            width={800}
            title={translations.get("NewComment")}
            externalRef={dialogRef}
            renderContent={() => (
                <Flex fill column={isSmallScreen} className={"overflow-hidden"}>
                    <Flex fill column gap={"gap.medium"} className={"overflow-hidden x-padding y-padding"}>
                        <ItemCard inline item={state.item as ItemData} hideActions
                                  hideRecommendButton={!canRecommendItem}/>
                        <Flex styles={{marginTop: "-10px"}}>
                            {formData.form}
                        </Flex>
                    </Flex>
                    {isSmallScreen ?
                        <Divider/>
                        :
                        <Flex>
                            <Divider vertical/>
                        </Flex>
                    }
                    {renderCommentsPart(comments, isSmallScreen)}
                </Flex>
            )}
            renderFooter={() => (
                <Flex className={"w-100"} hAlign={"end"}>
                    <Button
                        fluid={isSmallScreen}
                        primary icon={<SendIcon outline/>}
                        content={translations.get("Publish")}
                        onClick={dispatch("handlePublish")}
                        disabled={!canPublish}
                        loading={state.isPublishing}
                    />
                </Flex>
            )}
        />
    )
}, CompareModule.areObjectsEqual);

const renderCommentsPart = (comments: Immutable<Array<SharepointComment>> | undefined, isSmallScreen: boolean) => {
    const containerStyles: ComponentSlotStyle = {
        height: isSmallScreen ? "auto" : "380px",
        marginTop: isSmallScreen ? "-10px" : "0",
    }
    return (
        <Flex fill column className={"overflow-hidden x-padding top-padding"} gap={"gap.small"}
              styles={containerStyles}>
            <Text weight={"semibold"} size={"large"} content={translations.get("RecentComments")}/>
            <Flex fill column gap={"gap.medium"} styles={{paddingBottom: "15px"}}
                  className={isSmallScreen ? "" : "overflow-scroll"}>
                {!comments ?
                    <Loader className={"h-100"}/>
                    :
                    comments.length === 0 ?
                        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.smaller"}
                              styles={{padding: "20px"}}>
                            <ChatIcon styles={{color: "darkgray"}} size={"larger"} outline/>
                            <Text styles={{color: "darkgray"}} content={translations.get("NoComments")}/>
                        </Flex>
                        :
                        comments.slice(0, isSmallScreen ? 5 : 15).map(c => <CommentCard key={c.id} comment={c}/>)
                }
            </Flex>
        </Flex>
    )
}