import React, {ReactElement, useCallback, useEffect, useMemo} from "react";
import {ITableItem, Logic, Props} from "./Table.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Table.reducer";
import "./Table.styles.scss";
import {useMsTeamsSelector} from "redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useTableHeader} from "./hooks/useTableHeader";
import {useTableItems} from "./hooks/useTableItems";
import {Flex, Text} from "@fluentui/react-northstar";
import {Table as FUITable} from "@fluentui/react-northstar/dist/es/components/Table/Table";
import {translations} from "translations";

export const Table = <T, >(props: Props<T>): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState(props), props.externalRef);
    const {isTouchScreen} = useMsTeamsSelector("isTouchScreen");

    useEffect(dispatch("initialize"), []);
    useEffect(dispatch("stopEditOnOutsideClick"), []);

    const getColumnByField = useCallback((field: string) => {
        const column = props.columns.find(c => c.field === field);
        if (!column) throw new Error("Can't find column of field : " + field);
        return column;
    }, [props.columns]);

    const filteredItems = useMemo(() => {
        if (!props.items) state.table?.scrollTo(0, 0);
        let skip: number | undefined = (!props.items || props.items?.length === 0) ? 0 : props.items?.length + 1;
        const stopFetchOnScroll = state.prevSkip === skip;
        if (stopFetchOnScroll) skip = undefined;
        dispatch({type: "setSkip", payload: [skip]});
        let items = props.sortLocally ?
            sortItems(props.columns, props.items, state.columnSort, state.sortAscending)
            :
            (props.items ?? []);
        items = items?.filter(i => i);
        if (props.items) items.push(...props.items.filter(i => i === null));
        const showNextItemsSkeletons = props.fetchOnScroll && props.fetchNextItems && state.skip;
        if (!props.items || showNextItemsSkeletons) {
            const visibleItemsCountDivider = !props.items ? 1 : 3;
            let skeletonsCount = Math.floor(state.visibleItemsCount / visibleItemsCountDivider);
            if (skeletonsCount <= 0) skeletonsCount = 1;
            items.push(...Array.from({length: skeletonsCount}).map(_ => null));
        }
        return items;
    }, [props.columns, props.items, state.columnSort, state.sortAscending, state.visibleItemsCount]);

    useEffect(function onVisibleRangeChange() {
        if (!state.mounted) return;
        dispatch("checkIfNeedToFetchNextItems")({items: props.items, filteredItems});
    }, [filteredItems, state.visibleRange]);

    const showActionColumn = !!props.actions && props.actions.items.length > 0;

    const actionColumnWidth = 50;

    const hideFilters = !props.onFilterByColumn;

    const logic: Logic<T> = {
        show: props.show ?? true,
        showActionColumn,
        items: filteredItems,
        columnSort: state.columnSort,
        sortAscending: state.sortAscending,
        columns: props.columns,
        visibleRange: state.visibleRange,
        actions: props.actions,
        getColumnByField,
        actionColumnWidth,
        editedItem: state.editedItem,
        columnFilters: state.columnFilters,
        isTouchScreen,
        hideFilters,
        dispatch,
    }

    const header = useTableHeader(logic);
    const items = useTableItems(logic);

    if (!logic.show) return null;
    return (
        <Flex fill column gap={"gap.large"} className={"table-container"} ref={dispatch("tableContainerRef")}>
            <FUITable
                ref={dispatch("tableRef")}
                className={"table"}
                styles={{height: items.length <= 0 ? "auto" : "100%"}}
                header={{
                    className: "pos-sticky",
                    items: header
                }}
                rows={items}
                onScroll={dispatch("handleScrollTable")}
            />
            {items.length <= 0 && renderNoItemsMessage()}
        </Flex>
    )
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const sortItems = <T, >(
    columns: Props<T>["columns"],
    items: Array<ITableItem<T>> | undefined,
    columnSort: string | null,
    sortAscending: boolean
): Array<ITableItem<T>> => {
    if (!items) return [];
    const column = columns.find(c => c.field === columnSort);
    if (!column?.sort) return items;
    let itemsCopy: typeof items = [...items];
    itemsCopy.sort(column.sort);
    if (!sortAscending) itemsCopy = itemsCopy.reverse();
    return [...itemsCopy];
}

const renderNoItemsMessage = () => (
    <Flex fill column vAlign={"center"} hAlign={"center"}>
        <Text
            size={"large"} align={"center"}
            content={translations.get("NoResult")}
            styles={{color: "darkgray"}}
        />
    </Flex>
)