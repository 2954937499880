import {State} from "./Notifications.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {ConfigurationRef} from "./Configuration/Configuration.interfaces";
import {Notification} from "../../interfaces/Notification";

export const initialState: State = {}

export const reducer = (config: {
    configurationRef: ConfigurationRef,
}) => ({
    notificationSelected: (_, [notification]: [Immutable<Notification>]) => {
        config.configurationRef.dispatch("applyTemplate")(notification);
    }
}) satisfies MagicReducerObject<State>;