import React, {useEffect, useState} from "react";
import "./ErrorBoundary.styles.scss";
import {
    Alert,
    CloseIcon,
    Dialog,
    Divider,
    ExclamationTriangleIcon,
    Flex,
    Text,
    Tooltip
} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import {AxiosError} from "axios";

type ErrorData = {
    title?: string,
    subtitle?: string,
    inDialog: boolean,
    canDismiss?: boolean,
    error?: Error | undefined,
}

const defaultShowErrorAlertFunc: ((title?: string | undefined, error?: Error) => void) = (title) => {
    if (title) console.error(title);
}

const defaultShowErrorDialogFunc: ((data: {
    title?: string,
    subtitle?: string,
    canDismiss?: boolean,
    error?: Error | undefined,
}) => void) = (data) => {
    console.error(data.title, ":", data.subtitle);
}

export const ErrorModule = {
    showErrorAlert: defaultShowErrorAlertFunc,
    showErrorDialog: defaultShowErrorDialogFunc,
}

const ErrorBoundary = () => {
    const [errorData, setErrorData] = useState<ErrorData>();

    useEffect(function onMount() {
        ErrorModule.showErrorAlert = (title, error) => setErrorData({...(title && {title}), error, inDialog: false});
        ErrorModule.showErrorDialog = (data) => setErrorData({...data, inDialog: true});
        return () => {
            ErrorModule.showErrorAlert = defaultShowErrorAlertFunc;
            ErrorModule.showErrorDialog = defaultShowErrorDialogFunc;
        }
    }, []);

    const handleClose = (e?: React.SyntheticEvent | undefined) => {
        e?.stopPropagation();
        setErrorData(undefined);
    };

    return renderError(errorData, handleClose);
}

const renderError = (errorData: ErrorData | undefined, onClose: (e?: React.SyntheticEvent | undefined) => void) => {
    if (!errorData) return null;
    return errorData.inDialog ? renderErrorDialog(errorData, onClose) : renderErrorAlert(errorData, onClose);
}

const renderAxiosErrorDetails = (error: AxiosError) => {
    const endpoint = error.config.method?.toUpperCase() + " " + error.request?.responseURL;
    const body = JSON.stringify(error.config.data);
    const responseData = (error.response?.data as any)?.detail;
    return (
        <Flex column gap={"gap.smaller"}>
            <Divider/>
            {endpoint && <Text size={"medium"} content={endpoint}/>}
            {body && <Text size={"medium"} content={body}/>}
            {endpoint && responseData && <Divider/>}
            {responseData && <Text size={"medium"} content={responseData}/>}
        </Flex>
    )
}

const renderErrorDetails = (errorData: ErrorData) => {
    const axiosError = errorData.error?.name === "AxiosError" ? errorData.error as AxiosError : undefined;
    return (
        <Flex column gap={"gap.smaller"} style={{maxHeight: "50vh", maxWidth: "100vw", overflow: "scroll"}}>
            {!errorData.title ? null :
                <Text
                    weight={"semibold"} size={"medium"}
                    content={errorData.title}
                />
            }
            {!errorData.error ? null :
                <>
                    <Text size={"medium"} content={errorData.error?.message}/>
                    {!axiosError ? null : renderAxiosErrorDetails(axiosError)}
                </>
            }
        </Flex>
    )
}

const renderErrorAlert = (errorData: ErrorData, onClose: (e?: React.SyntheticEvent | undefined) => void) => {
    return (
        <div className={"errors-container"}>
            <Tooltip
                mouseLeaveDelay={500}
                trigger={
                    <Alert
                        styles={{padding: "0 5px"}}
                        danger
                        content={
                            <Flex vAlign={"center"} styles={{gap: "-10px"}}>
                                <Text styles={{padding: "5px"}} content={translations.get("AnErrorOccured")}/>
                                <CloseIcon styles={{padding: "5px"}} className={"cursor-pointer"} size={"small"}
                                           onClick={onClose}/>
                            </Flex>
                        }
                    />
                }
                content={renderErrorDetails(errorData)}
                position={"above"}
                align={"end"}
            />
        </div>
    )
}

const renderErrorDialog = (errorData: ErrorData, onClose: () => void) => {
    const title = !errorData.title ? translations.get("AnErrorOccured") : errorData.title;
    return (
        <Dialog
            open
            styles={{width: "400px"}}
            content={
                <Flex fill className={"h-100"} column vAlign={"center"} hAlign={"center"} gap={"gap.small"}>
                    <ExclamationTriangleIcon
                        size={"largest"}
                        styles={{color: "rgb(196, 49, 75)", transform: "scale(2)", padding: "40px 0 30px 0"}}
                    />
                    <Text content={title} size={"large"} align={"center"}/>
                    {!errorData.subtitle ? null : <Text content={errorData.subtitle} align={"center"}/>}
                </Flex>
            }
            headerAction={errorData.canDismiss === false ? null : {
                icon: <CloseIcon/>,
                onClick: onClose,
            }}
        />
    )
}

let errorBoundaryInstance: JSX.Element | null = null;

export const wrapWithErrorBoundary = (children: JSX.Element) => {
    if (!errorBoundaryInstance) errorBoundaryInstance = <ErrorBoundary/>;
    return <>
        {children}
        {errorBoundaryInstance}
    </>
}