import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./reducers/AuthReducer/AuthReducer";
import configurationReducer from "./reducers/ConfigurationReducer/ConfigurationReducer";
import msTeamsReducer from "./reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import scopesReducer from "./reducers/ScopesReducer/ScopesReducer";

const reducer = {
    configuration: configurationReducer,
    msTeams: msTeamsReducer,
    auth: authReducer,
    scopes: scopesReducer,
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export type ReduxStoreState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;

export default store;