import React, {ReactElement} from "react";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Notifications.reducer";
import "./Notifications.styles.scss";
import {Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../translations";
import {TemplateList} from "./TemplateList/TemplateList";
import {Configuration} from "./Configuration/Configuration";
import {ConfigurationRef} from "./Configuration/Configuration.interfaces";
import {useNotificationTemplatesCache} from "../../services/CacheService/notificationTemplatesCache";

export const Notifications = (): ReactElement => {
    const configurationRef = useMagicReducerRef<ConfigurationRef>();
    const [state, dispatch] = useMagicReducer(reducer({configurationRef}), initialState);

    const {templates} = useNotificationTemplatesCache();

    return (
        <Flex fill className={"notifications-view no-select"}>
            <Flex fill column gap={"gap.small"}>
                <Flex column styles={{gap: "3px"}}>
                    <Text
                        className={"primary-text"}
                        size={"larger"}
                        weight={"bold"}
                        content={translations.get("Configuration")}
                    />
                    <Text
                        className={"primary-text"}
                        content={translations.get("ConfigureNotificationSubtitle")}
                    />
                </Flex>
                <Configuration externalRef={configurationRef}/>
            </Flex>
            <Flex fill column gap={"gap.small"}>
                <Flex column styles={{gap: "3px"}}>
                    <Text
                        className={"primary-text"}
                        size={"larger"}
                        weight={"bold"}
                        content={translations.get("Templates")}
                    />
                    <Text
                        className={"primary-text"}
                        content={translations.get("NotificationTemplateSubtitle")}
                    />
                </Flex>
                <TemplateList templates={templates} onNotificationSelected={dispatch("notificationSelected")}/>
            </Flex>
        </Flex>
    );
}