import React, {memo, ReactElement, useEffect, useRef} from "react";
import {Props} from "./TemplateList.interfaces";
import {MagicDispatch, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./TemplateList.reducer";
import {CompareModule} from "modules/Compare.module";
import "./TemplateList.styles.scss";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import {Flex, Text, TranscriptIcon} from "@fluentui/react-northstar";
import {Virtual} from 'swiper/modules';
import SwiperCore from "swiper";
import {translations} from "../../../translations";
import {MeetingCard} from "../../../components/MeetingCard/MeetingCard";
import {TemplateCard} from "../TemplateCard/TemplateCard";
import {DeleteTemplateDialog} from "../DeleteTemplateDialog/DeleteTemplateDialog";
import {DeleteTemplateDialogRef} from "../DeleteTemplateDialog/DeleteTemplateDialog.interfaces";

SwiperCore.use([Virtual]);

export const TemplateList = memo((props: Props): ReactElement | null => {
    const deleteDialogRef = useMagicReducerRef<DeleteTemplateDialogRef>();
    const [state, dispatch] = useMagicReducer(reducer(deleteDialogRef), initialState);

    const swiperRef = useRef<SwiperRef | null>(null);

    useEffect(function onMount() {
        swiperRef.current?.swiper?.update();
    }, []);

    const deleteDialog = (
        <DeleteTemplateDialog
            externalRef={deleteDialogRef}
            onDeleteTemplate={() => 0}
        />
    )

    return (
        <Flex fill column className={"notification-template-list"} vAlign={"center"}>
            {renderVerticalCarousel(props, dispatch, swiperRef)}
            {deleteDialog}
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const renderVerticalCarousel = (
    props: Props,
    dispatch: MagicDispatch<typeof reducer>,
    swiperRef: React.MutableRefObject<SwiperRef | null>,
) => {
    if (props.templates?.length === 0) return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.small"} className={"no-template-container"}>
            <TranscriptIcon outline size={"largest"}/>
            <Text
                size={"large"}
                content={translations.get("NoTemplate")}
            />
        </Flex>
    )
    const skeletons = props.templates ? null : Array.from({length: 15}).map((_, i) => (
        <MeetingCard key={"skeleton" + i} meeting={undefined}/>
    ));
    return (
        <Swiper
            ref={swiperRef}
            cssMode
            direction={"vertical"}
            slidesPerView={"auto"}
            slidesPerGroupAuto
            slidesOffsetAfter={10}
            slidesOffsetBefore={10}
            modules={[Virtual]}
            className={"w-100 h-100"}>
            <SwiperSlide virtualIndex={0} style={{height: "10px", width: "100%"}}/>
            {skeletons?.map((s, i) => (
                <SwiperSlide key={"skeleton" + i} virtualIndex={i + 1} style={{height: "auto", paddingTop: "10px"}}>
                    {s}
                </SwiperSlide>
            ))}
            {props.templates?.map((t, i) => (
                <SwiperSlide
                    virtualIndex={i}
                    key={t.id}
                    style={{height: "auto", paddingBottom: "10px"}}>
                    <TemplateCard
                        notification={t}
                        onSelected={props.onNotificationSelected}
                        onDelete={dispatch("openDeleteDialog")}
                    />
                </SwiperSlide>
            ))
            }
            <SwiperSlide
                virtualIndex={(skeletons?.length ?? 0) + (props.templates?.length ?? 0) + 2}
                style={{height: "0px", width: "100%"}}
            />
        </Swiper>
    )
}