import React, {memo, ReactElement} from "react";
import {Props} from "./ItemCard.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer, stopPropagation} from "./ItemCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./ItemCard.styles.scss";
import {
    Button,
    ChatIcon,
    Flex,
    FlexItem,
    Image,
    ShareGenericIcon,
    Skeleton,
    StarIcon,
    Text
} from "@fluentui/react-northstar";
import {ThumbFillIcon, ThumbIcon} from "assets/icons";
import {translations} from "translations";
import {useItemLikeCache} from "../../services/CacheService/likesCache";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {useThumbnailsCache} from "../../services/CacheService/thumbnailsCache";
import {useUserVisitedItemCache} from "../../services/CacheService/userDataCache";
import {useItemRecommendationCache} from "../../services/CacheService/recommendationsCache";

export const ItemCard = memo((props: Props): ReactElement | null => {
    const {userId, userMail, isOnMobile} = useMsTeamsSelector("userId", "userMail", "isOnMobile");
    const {isLiked, toggleLike} = useItemLikeCache(userMail, props.item?.type, props.item?.id);
    const {isVisited, markItemVisited} = useUserVisitedItemCache(props.item?.type, props.item?.id);
    const {isRecommended, toggleRecommendation} = useItemRecommendationCache(props.item?.type, props.item?.id);
    const {imageUrl} = useThumbnailsCache(props.item);
    const [state, dispatch] = useMagicReducer(reducer(props, toggleLike, userId, markItemVisited), initialState);

    const hideLikeButton = !props.item?.canInteract;
    const hideCommentButton = hideLikeButton || !props.itemDialogRef;
    const hideShareButton = isOnMobile;
    const hideFooter = hideLikeButton && hideCommentButton && hideShareButton;

    const picture = (
        <Flex className={"item-card-picture-container"}>
            {!props.item ?
                <Skeleton.Shape className={"item-card-picture"}/>
                :
                <Image
                    key={props.overrideImage ?? imageUrl}
                    className={"item-card-picture"}
                    src={props.overrideImage ?? imageUrl ?? ""}
                    styles={{opacity: state.imageLoaded ? 1 : 0}}
                    onLoad={dispatch("handleImageLoaded")}
                />
            }
        </Flex>
    )

    const content = (
        <Flex column fill className={"item-card-content"}>
            {!props.item ?
                <Skeleton.Line height={"20px"} width={"100%"}/>
                :
                <Text size={"large"} weight={"semibold"}
                      className={"item-card-title " + (isVisited ? "item-card-title-visited" : "")}
                      content={props.item.title}/>
            }
            {!props.item ?
                <Skeleton.Line height={"30px"} width={"100%"}/>
                :
                <Text className={"item-card-description"} size={"small"} content={props.item.description}/>
            }
        </Flex>
    )

    const footer = (props.hideActions || hideFooter) ? null : (
        <Flex className={"item-card-footer"} onClick={stopPropagation}>
            {!props.item ?
                <Flex fill wrap styles={{gap: "15px"}}>
                    {!hideLikeButton && <Skeleton.Shape height={"25px"} width={"50px"}/>}
                    {!hideCommentButton && <Skeleton.Shape height={"25px"} width={"100px"}/>}
                    {!hideShareButton && <Skeleton.Shape height={"25px"} width={"80px"}/>}
                </Flex>
                :
                <Flex fill wrap styles={{marginLeft: "-18px", marginTop: "-12px"}} vAlign={"center"}>
                    {!hideLikeButton &&
                        <Button
                            text
                            className={"item-card-action"}
                            icon={
                                isLiked ?
                                    <ThumbFillIcon width={15} height={15}/> :
                                    <ThumbIcon width={15} height={15}/>
                            }
                            content={props.item.likes.length + ""}
                            onClick={dispatch("handleToggleLike")}
                        />
                    }
                    {!hideCommentButton &&
                        <Button
                            text
                            className={"item-card-action"}
                            icon={<ChatIcon outline/>}
                            content={props.item.comments + ""}
                            onClick={dispatch("handleAddComment")}
                        />
                    }
                    {!hideShareButton &&
                        <Button
                            text
                            className={"item-card-action"}
                            icon={<ShareGenericIcon outline/>}
                            content={translations.get("Share")}
                            onClick={dispatch("handleShare", props.item.link)}
                        />
                    }
                    {!props.hideRecommendButton &&
                        <FlexItem push>
                            <button title={translations.get("Recommend")} className={"recommendation-button"}
                                    onClick={toggleRecommendation}>
                                <StarIcon outline={!isRecommended}/>
                            </button>
                        </FlexItem>
                    }
                </Flex>
            }
        </Flex>
    )

    const className = [
        "item-card",
        props.inline && "item-card-inline"
    ].filter(i => i).join(" ");

    return (
        <Skeleton animation={"pulse"} className={className} onClick={dispatch("handleClick")}>
            <Flex className={"overflow-hidden"} fill column gap={"gap.small"}>
                {picture}
                {content}
                {footer}
            </Flex>
        </Skeleton>
    )
}, CompareModule.areObjectsEqual);