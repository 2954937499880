import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConfigurationReducerState} from "./ConfigurationReducer.interfaces";
import {getSliceSelector} from "../../utils";

const initialState: ConfigurationReducerState = {
    apiVersion: "",
    clientId: "",
    graphScopes: "",
    appInsightKey: "",
    manifestId: "",
    applicationVersion: "",
    needToPrepare: false,
    messageOfTheDay: undefined,
};

const slice = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setConfiguration: (state, action: PayloadAction<Partial<ConfigurationReducerState>>) => {
            Object.assign(state, action.payload);
        },
    },
});

export const {setConfiguration} = slice.actions;

export const useConfigurationSelector = getSliceSelector(slice);

export default slice.reducer;