import {memo, ReactElement, useRef} from "react";
import {Props} from "./Configuration.interfaces";
import {Immutable, useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Configuration.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Configuration.styles.scss";
import {Button, Flex, SaveIcon, SendIcon, Text} from "@fluentui/react-northstar";
import {useForm} from "@witivio_teamspro/northstar-form";
import {useMsTeamsSelector} from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {translations} from "../../../translations";
import {Preview} from "../Preview/Preview";
import {Notification} from "interfaces/Notification";
import {useNotificationTemplatesCache} from "../../../services/CacheService/notificationTemplatesCache";
import {NotificationSentDialogRef} from "../NotificationSentDialog/NotificationSentDialog.interfaces";
import {NotificationSentDialog} from "../NotificationSentDialog/NotificationSentDialog";

export const Configuration = memo((props: Props): ReactElement | null => {
    const {locale} = useMsTeamsSelector("locale");
    const templateRef = useRef<Immutable<Notification>>();
    const notificationSentDialogRef = useMagicReducerRef<NotificationSentDialogRef>();

    const {addTemplate} = useNotificationTemplatesCache();

    const {form, isValid, dataRef, reset} = useForm({
        locale,
        fluid: true,
        items: [
            {
                id: "title",
                type: "input",
                label: translations.get("Title"),
                inputMode: "text",
                required: true,
                maxLength: 128,
                placeholder: translations.get("EnterTitle"),
                initialValue: templateRef.current?.title,
            },
            {
                id: "subtitle",
                type: "textArea",
                label: translations.get("Subtitle"),
                required: true,
                maxLength: 128,
                minHeight: "100px",
                placeholder: translations.get("EnterSubtitle"),
                initialValue: templateRef.current?.subtitle,
            }
        ]
    }, []);

    const [state, dispatch] = useMagicReducer(reducer({
        dataRef,
        resetForm: reset,
        templateRef,
        addTemplate,
        notificationSentDialogRef,
    }), initialState, props.externalRef);

    const title = (dataRef.current["title"] ?? "") + "";
    const subtitle = (dataRef.current["subtitle"] ?? "") + "";

    const disableButtons = !isValid || state.isSendingNotification || state.isAddingTemplate;

    return (
        <Flex column fill className={"notification-configuration"}>
            <Flex className={"no-shrink"}>
                {form}
            </Flex>
            <Flex column fill>
                <Text content={translations.get("Preview")}/>
                <Flex className={"notification-preview-container"} fill vAlign={"center"} hAlign={"center"}>
                    <Preview title={title} subtitle={subtitle}/>
                </Flex>
            </Flex>
            <Flex gap={"gap.small"} space={"between"}>
                <Button
                    icon={<SaveIcon size={"large"} outline/>}
                    content={translations.get("SaveTemplate")}
                    disabled={disableButtons}
                    loading={state.isAddingTemplate}
                    onClick={dispatch("saveTemplate")}
                />
                <Button
                    icon={<SendIcon outline/>}
                    content={translations.get("SendNotification")} primary
                    disabled={disableButtons}
                    loading={state.isSendingNotification}
                    onClick={dispatch("sendNotification")}
                />
            </Flex>
            <NotificationSentDialog externalRef={notificationSentDialogRef}/>
        </Flex>
    )
}, CompareModule.areObjectsEqual);