import {UserRole} from "../../interfaces/UserRole";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import {UserData} from "../../interfaces/UserData";

const getUserData = () => new Promise<UserData | undefined>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get("/user/data");
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user data");
        return resolve(undefined);
    }
});

const createUserData = (userData: UserData) => new Promise<UserData | undefined>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.post("/user/data", userData);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't create user data");
        return resolve(undefined);
    }
});

const updateUserData = (userData: UserData) => new Promise<UserData | undefined>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.put("/user/data", userData);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't update user data");
        return resolve(undefined);
    }
});

const getUserRole = () => new Promise<UserRole>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get("/user/role");
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user role");
        return resolve(UserRole.User);
    }
});

export const UserApi = {
    getUserRole,
    getUserData,
    createUserData,
    updateUserData,
}