import {State} from "./TemplateList.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DeleteTemplateDialogRef} from "../DeleteTemplateDialog/DeleteTemplateDialog.interfaces";
import {Notification} from "../../../interfaces/Notification";

export const initialState: State = {}

export const reducer = (deleteDialogRef: DeleteTemplateDialogRef) => ({
    openDeleteDialog: (_, [notification]: [Immutable<Notification>]) => {
        deleteDialogRef.dispatch("open")(notification);
    }
}) satisfies MagicReducerObject<State>;