import {GraphBatchRequest, GraphUserData} from "./GraphService.interfaces";
import {GuidModule} from "../../modules/Guid.module";
import {teamsFxGraphBetaClient, teamsFxGraphClient} from "../../redux/reducers/ScopesReducer/ScopesReducer";
import {getAppConfiguration} from "../ConfigurationService/ConfigurationService";
import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import {SessionCacheService} from "../CacheService/CacheService.hook";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {DeeplinkContext} from "../../interfaces/DeeplinkContext";

const userDataCacheKey = "user_data_";

const searchForUsersAsync = (query: string): Promise<Array<GraphUserData>> => {
    return new Promise<Array<GraphUserData>>(async resolve => {
        if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined")
        const usersRawData = (await teamsFxGraphClient
            .api('/users')
            .filter(`startswith(displayName, '${query}')`)
            .select("id,displayName,userPrincipalName")
            .get()).value;
        const usersData: Array<GraphUserData> = usersRawData.map((data: any) => data as GraphUserData);
        await attachPicturesToUsersDataAsync(usersData);
        saveRemoteDataInCache(usersData);
        return resolve(usersData);
    })
}

const getMe = (): Promise<GraphUserData> => {
    return new Promise<GraphUserData>(async resolve => {
        if (!teamsFxGraphBetaClient) return new Error("TeamxFx graph client is undefined")
        let userData = await teamsFxGraphBetaClient
            .api("/me")
            .select("id,displayName,userPrincipalName,jobTitle,department,country,officeLocation")
            .get();
        await attachPicturesToUsersDataAsync([userData]);
        saveRemoteDataInCache([userData]);
        return resolve(userData);
    })
}

const getRelevantPeople = (userId: string, top: number = 10): Promise<Array<GraphUserData>> => {
    return new Promise<Array<GraphUserData>>(async resolve => {
        if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined")
        let usersData = (await teamsFxGraphClient
                .api("/me/people")
                .filter("personType/class eq 'Person'")
                .select("id,displayName,userPrincipalName")
                .top(top + 1)
                .get()
        ).value as Array<GraphUserData>;
        usersData = usersData.filter(u => u.id !== userId);
        await attachPicturesToUsersDataAsync(usersData);
        saveRemoteDataInCache(usersData);
        return resolve(usersData);
    })
}

const areUsersInTeam = async (userIds: Array<string>, teamId: string): Promise<Array<string> | Error> => {
    if (userIds.length === 0) return new Array<string>();
    if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined")
    const batchUsersRequests = {
        requests: userIds.map((id: string) => ({
            id: id,
            method: "GET",
            url: `/teams/${teamId}/members?$filter=(microsoft.graph.aadUserConversationMember/userId eq '${id}')&$select=id`
        }))
    }
    const usersRawData = (await teamsFxGraphClient
        .api('/$batch')
        .post(JSON.stringify(batchUsersRequests))).responses;
    const validUserIds = new Array<string>();
    usersRawData.forEach((request: any) => {
        const count = request.body["@odata.count"];
        if (count > 0) validUserIds.push(request.id);
    });
    return validUserIds;
}

const getUsersAsync = (usersIds: Array<string>): Promise<Array<GraphUserData>> => {
    return new Promise<Array<GraphUserData>>(async resolve => {
        if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined");
        const validIds = usersIds.filter(id => GuidModule.isValidGuid(id));
        const localUsersData = new Array<GraphUserData>();
        validIds.forEach(id => {
            const user = SessionCacheService.getItem<GraphUserData>(userDataCacheKey + id);
            if (!!user) localUsersData.push(user);
        })
        if (localUsersData.length === validIds.length) {
            resolve(localUsersData);
            return;
        }
        const localUsersIds = localUsersData.map(u => u.id);
        let usersIdsToFetch = validIds.filter(id => !localUsersIds.includes(id));
        const usersData = new Array<GraphUserData>();
        const batchUsersRequests = new Array<{ requests: Array<GraphBatchRequest> }>();
        batchUsersRequests.push({requests: new Array<GraphBatchRequest>()});
        let requestCount = 0;
        while (usersIdsToFetch.length > 0) {
            // 15 conditions is the limit for odata filter
            const idsToFetch = usersIdsToFetch.slice(0, 15);
            usersIdsToFetch = usersIdsToFetch.slice(15, usersIdsToFetch.length - 1);
            const filter = idsToFetch.map(id => "id eq '" + id + "'").join(" or ");
            const request: GraphBatchRequest = {
                id: "request-" + requestCount,
                url: `/users?$filter=(${encodeURIComponent(filter)})&$select=id,displayName,userPrincipalName`,
                method: "GET"
            };
            let lastBatchRequest = batchUsersRequests[batchUsersRequests.length - 1];
            if (!lastBatchRequest) return new Error("Batch request is undefined");
            // 20 is the limit of steps in a batch request
            if (lastBatchRequest.requests.length < 20) {
                lastBatchRequest.requests.push(request);
            } else {
                lastBatchRequest = {requests: new Array<GraphBatchRequest>()};
                lastBatchRequest.requests.push(request);
                batchUsersRequests.push(lastBatchRequest);
            }
            requestCount += 1;
        }
        for (const batchRequest of batchUsersRequests) {
            if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined");
            const responses = (await teamsFxGraphClient.api('/$batch').post(JSON.stringify(batchRequest))).responses;
            responses.forEach((request: { body: { value?: Array<GraphUserData> } }) => {
                if (request.body.value) usersData.push(...request.body.value)
            });
        }
        await attachPicturesToUsersDataAsync(usersData);
        saveRemoteDataInCache(usersData);
        usersData.push(...localUsersData);
        return resolve(usersData);
    })
}

const saveRemoteDataInCache = (usersData: Array<GraphUserData>) => {
    usersData.forEach(u => SessionCacheService.setItem(userDataCacheKey + u.id, JSON.stringify(u)));
}

const attachPicturesToUsersDataAsync = (usersData: Array<GraphUserData>): Promise<void> => {
    return new Promise<void>(async resolve => {
        if (!usersData || usersData.length === 0) {
            resolve();
            return;
        }
        const pictures = await getUsersPicturesAsync(usersData.map(u => u.id));
        usersData.forEach((user: GraphUserData) => {
            const picture = pictures.get(user.id);
            if (!!picture) user.picture = picture;
        });
        resolve();
    })
}

const getUserPictureAsync = async (userId: string): Promise<string> => {
    const lowerId = userId.toLowerCase();
    const pictures = await getUsersPicturesAsync([lowerId]);
    return pictures.get(lowerId) ?? "";
}

const getUsersPicturesAsync = async (usersIds: Array<string>): Promise<Map<string, string>> => {
    return new Promise<Map<string, string>>(async resolve => {
        if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined")
        const pictures = new Map<string, string>();
        const cachedPictures = getUsersPicturesFromCache(usersIds);
        cachedPictures.forEach((value, key) => pictures.set(key, value));
        let usersIdsToFetch = usersIds.filter(id => !pictures.get(id));
        if (usersIdsToFetch.length === 0) return resolve(pictures);
        const batchPicturesRequests = new Array<{ requests: Array<GraphBatchRequest> }>();
        while (usersIdsToFetch.length > 0) {
            // 20 is the limit of steps in a batch request
            const idsToFetch = usersIdsToFetch.slice(0, 20);
            usersIdsToFetch = usersIdsToFetch.slice(20, usersIdsToFetch.length - 1);
            const requests: { requests: Array<GraphBatchRequest> } = {
                requests: idsToFetch.map((id: string) => ({
                    id: id,
                    method: "GET",
                    url: `/users/${id}/photos/120x120/$value`
                }))
            }
            batchPicturesRequests.push(requests);
        }
        for (const batchRequest of batchPicturesRequests) {
            if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined");
            const responses = (await teamsFxGraphClient.api('/$batch').post(JSON.stringify(batchRequest)))?.responses;
            if (!responses) return new Error("Can't fetch batch request from graph");
            responses.forEach((request: any) => {
                let base64Image = `data:${request.headers["Content-Type"]};base64,${request.body}`;
                if (!(request.headers["Content-Type"] + "").startsWith("image/")) base64Image = "";
                pictures.set(request.id, base64Image);
            });
        }
        return resolve(pictures);
    })
}

const getUsersPicturesFromCache = (usersIds: Array<string>) => {
    const pictures = new Map<string, string>();
    usersIds.forEach(id => {
        const user = SessionCacheService.getItem<GraphUserData>(userDataCacheKey + id);
        if (!!user) pictures.set(id, user.picture ?? "");
    })
    return pictures;
}

const getFilePreviewUrl = async (driveId: string, itemId: string) => {
    if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(`/drives/${driveId}/items/${itemId}/microsoft.graph.preview`).post("");
    return response.getUrl;
}

const getFileDownloadUrl = async (driveId: string, itemId: string) => {
    if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(`/drives/${driveId}/items/${itemId}`).get();
    return response["@microsoft.graph.downloadUrl"];
}

const getMessageContent = async (messageId: string) => {
    if (!teamsFxGraphClient) {
        console.error("TeamxFx graph client is undefined");
        return null;
    }
    const response = await teamsFxGraphClient.api(`/me/messages/${messageId}/$value`).get();
    return response as ArrayBuffer;
}

const sendNotification = async (config: {
    appName: string,
    title: string,
    userIds: Immutable<Array<string>>,
    subtitle?: string | undefined,
    deeplinkContext?: DeeplinkContext,
    entityId?: string | undefined
}) => {
    return new Promise<boolean>(async resolve => {
        if (!teamsFxGraphClient) return new Error("TeamxFx graph client is undefined");
        const appConfiguration = getAppConfiguration();
        if (!appConfiguration) return ErrorModule.showErrorAlert("Can't get app configuration");

        const {title, subtitle, userIds, deeplinkContext, appName, entityId} = config;

        const uniqueUserIds = Array.from(new Set(userIds));

        let deeplink = `https://teams.microsoft.com/l/entity/${appConfiguration?.manifestId}`;

        if (!!entityId) deeplink += `/${entityId}`;

        if (!!deeplinkContext) {
            const context = encodeURIComponent(JSON.stringify({
                subEntityId: JSON.stringify(deeplinkContext),
            }));
            deeplink += `?context=${context}`;
        }

        const content = {
            topic: {
                source: "text",
                value: appName,
                webUrl: deeplink,
            },
            activityType: "systemDefault",
            ...(subtitle && {
                previewText: {
                    content: subtitle
                }
            }),
            recipients: uniqueUserIds.map(id => ({
                "@odata.type": "microsoft.graph.aadUserNotificationRecipient",
                userId: id,
            })),
            templateParameters: [
                {
                    name: "systemDefaultText",
                    value: title
                }
            ]
        };

        await teamsFxGraphClient
            .api('/teamwork/sendActivityNotificationToRecipients')
            .post(content, (error) => resolve(!error));
    })
}

export const GraphService = {
    searchForUsersAsync,
    areUsersInTeam,
    getUsersAsync,
    getFilePreviewUrl,
    getMessageContent,
    getFileDownloadUrl,
    getRelevantPeople,
    sendNotification,
    getMe,
    getUserPictureAsync,
    getUsersPicturesAsync,
}