import axios from "axios";
import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import {translations} from "../../translations";

const instance = axios.create();

const setAxiosInstanceApiBaseUrl = (baseUrl: string) => {
    instance.defaults.baseURL = baseUrl;
}

const setAxiosRequestMiddleware = (accessToken: string) => {
    instance.interceptors.request.use(async function (config) {
        if (!config.headers) config.headers = {};
        config.headers["Content-Type"] = "application/json";
        config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
}

instance.interceptors.response.use(async function (config) {
    if (config.status === 401) ErrorModule.showErrorDialog({
        title: translations.get("TokenExpired"),
        subtitle: translations.get("PleaseRefreshTab"),
        canDismiss: false,
    });
    return config;
}, function (error) {
    return Promise.reject(error);
});

export const AxiosConfig = {
    instance,
    setAxiosInstanceApiBaseUrl,
    setAxiosRequestMiddleware,
}