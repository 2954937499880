export const translations = {
    locale: "en",
    get: (id: string, parameters?: Record<string, string | number>): string => {
        if (!id) return "";
        const object = (translations as any)[translations.locale] ?? (translations as any).en;
        let translation = object[id] ?? (translations as any).en[id] ?? "";
        if (!translation) {
            console.error("Missing translation for '" + id + "'");
            translation = id;
        }
        if (parameters) {
            for (const param in parameters)
                translation = translation.replaceAll("{" + param + "}", parameters[param] + "");
        }
        return translation;
    },
    en: {
        SendMessage: "Send message",
        Call: "Call",
        System: "System",
        Unknown: "Unknown",
        TokenExpired: "Token expired",
        PleaseRefreshTab: "Please refresh tab",
        AnErrorOccured: "An error occured",
        Consent: "Consent",
        NeedToConsent: "Need to consent",
        ClickButtonToContinue: "Click button to continue",
        BestPractices: "Best practices",
        Training: "Training",
        Searches: "Searches",
        HomeViewSearchPartSubtitle: "Everything you’re looking for, here and now",
        BestPracticesSubtitle: "The very best of the knowledge gathered by your peers",
        Meetings: "Meetings",
        HomeViewMeetPartSubtitle: "The next meetings you have scheduled",
        Help: "Help",
        SearchByKeyword: "Search by keyword",
        Join: "Join",
        Today: "Today",
        TrainingsSubtitle: "The best place to upskill, grow and develop",
        Trainings: "Trainings",
        TrainingsPart1: "Recommended trainings by your entity",
        TrainingsPart2: "Global training pool available",
        Share: "Share",
        NewComment: "New comment",
        WriteYourComment: "Write your comment",
        Publish: "Publish",
        Filter: "Filter",
        Comment: "Comment",
        More: "More",
        Filters: "Filters",
        Reset: "Reset",
        News: "News",
        HomeViewMessageOfTheDayPartSubtitle: "The latest news from your entity",
        Edit: "Edit",
        WriteYourMessage: "Write your message",
        Save: "Save",
        ShowMessage: "Show message",
        TeamsMeetingLink: "Teams meeting link",
        EnterTeamsMeetingLink: "Enter Teams meeting link",
        ContentLink: "Content link",
        EnterContentLink: "Enter content link",
        CustomButton: "Custom button",
        EnterTitle: "Enter title",
        EnterLink: "Enter link",
        JoinMeeting: "Join meeting",
        AddButton: "Add button",
        Important: "Important",
        All: "All",
        NoResults: "No results",
        MessageOfTheDay: "Message of the day",
        InitializationInProgress: "Initialization in progress",
        ShowAllResults: "Show all results",
        Item: "Item",
        Items: "Items",
        ShowAllItems: "Show all {name}",
        FromDate: "From date",
        ToDate: "To date",
        NothingScheduled: "Nothing scheduled",
        Home: "Home",
        Show: "Show",
        Affiliates: "Affiliates",
        Rating: "Rating",
        JobFamily: "Job family",
        Type: "Type",
        Topic: "Topic",
        Source: "Source",
        Brand: "Brand",
        ShowAllMeetings: "Show all meetings",
        ScheduledMeetings: "Scheduled meetings",
        CheckOutMessageOfTheDay: "Check out message of the day",
        TotalQueries: "Total queries",
        QueriesPerDay: "Queries per day",
        MostActiveUsers: "Most active users",
        VisitsPerDay: "Visits per day",
        TotalVisits: "Total visits",
        MostPopularQueries: "Most popular queries",
        MostOpenedFiles: "Most opened files",
        UsersActionsPerDay: "Users actions per day",
        Reporting: "Reporting",
        Visits: "Visits",
        Visit: "Visit",
        NoDataAvailable: "No data available",
        NoResult: "No result",
        Day: "Day",
        Week: "Week",
        Month: "Month",
        Custom: "Custom",
        ReportingSubtitle: "Visualize all application statistics",
        FilesStatistics: "Files statistics",
        Title: "Title",
        Likes: "Likes",
        Comments: "Comments",
        Clicks: "Clicks",
        SearchFile: "Search for a file",
        ClicksPerFileType: "Clicks per file type",
        TotalUniqueVisits: "Total unique visits",
        UniqueVisit: "Unique visit",
        UniqueVisits: "Unique visits",
        UsagePerJob: "Usage per job",
        UsagePerDepartment: "Usage per department",
        UsagePerLocation: "Usage per location",
        ClicksPerTopic: "Clicks per topic",
        ReturnRate: "Return rate",
        Download: "Download",
        ClickSaveToContinue: "Click save to continue",
        MeetingSidePanelMessage: "Start the application by clicking on the button above represented by",
        RecentComments: "Recent comments",
        NoComments: "No comments",
        RetrieveDraft: "Retrieve draft",
        Notifications: "Notifications",
        Configuration: "Configuration",
        ConfigureNotificationSubtitle: "Configure your notification",
        Templates: "Templates",
        NotificationTemplateSubtitle: "Select a pre-defined template to configure your notification",
        Subtitle: "Subtitle",
        SaveTemplate: "Save template",
        SendNotification: "Send notification",
        Preview: "Preview",
        DeleteTemplate: "Delete template",
        AreYouSureToDeleteTheTemplate: "Are you sure to delete the template :",
        EnterSubtitle: "Enter subtitle",
        Delete: "Delete",
        NotificationSent: "Notification sent",
        NoTemplate: "No template",
        MyDashboard: "My dashboard",
        ConsultedOrLikedContent: "Consulted or liked content",
        ConsultedOrLikedContentSubtitle: "Find content that you have consulted or liked",
        RecommendedContent: "Recommended content",
        RecommendedContentSubtitle: "The very best of the content gathered by your local management team",
        LikedBestPractices: "Liked best practices",
        LikedTrainings: "Liked trainings",
        ViewedBestPractices: "Viewed best practices",
        ViewedTrainings: "Viewed trainings",
        NoItemsAvailable: "No items available",
        EnrichLibrary: "Enrich library",
        MostLiked: "Most liked",
        AlreadyLikedOrConsulted: "Already liked or consulted",
        ManagementRecommendations: "Mgmt. recommendations",
        Recommend: "Recommend",
    },
    fr: {
        SendMessage: "Envoyer un message",
        Call: "Appeler",
        System: "Système",
        Unknown: "Inconnu",
        TokenExpired: "Token expiré",
        PleaseRefreshTab: "Veuillez rafraîchir l'onglet",
        AnErrorOccured: "Une erreur est survenue",
        Consent: "Consentir",
        NeedToConsent: "Vous devez donner votre accord",
        ClickButtonToContinue: "Cliquez sur le bouton pour continuer",
        BestPractices: "Bonnes pratiques",
        Training: "Formation",
        Searches: "Recherches",
        HomeViewSearchPartSubtitle: "Tout ce que vous souhaitez, ici et maintenant",
        BestPracticesSubtitle: "Le meilleur de la connaissance rassemblée par vos pairs",
        Meetings: "Réunions",
        HomeViewMeetPartSubtitle: "Les prochaines réunions que vous avez programmées",
        Help: "Aide",
        SearchByKeyword: "Rechercher par mot-clé",
        Join: "Rejoindre",
        Today: "Aujourd'hui",
        TrainingsSubtitle: "Le meilleur endroit pour se perfectionner, grandir et se développer",
        Trainings: "Formations",
        TrainingsPart1: "Formations recommandées par votre entité",
        TrainingsPart2: "Pool de formations global disponible",
        Share: "Partager",
        NewComment: "Nouveau commentaire",
        WriteYourComment: "Écrivez votre commentaire",
        Publish: "Publier",
        Filter: "Filtrer",
        Comment: "Commentaire",
        More: "Plus",
        Filters: "Filtres",
        Reset: "Réinitialiser",
        News: "Actualités",
        HomeViewMessageOfTheDayPartSubtitle: "Les dernières nouvelles de votre entité",
        Edit: "Modifier",
        WriteYourMessage: "Écrivez votre message",
        Save: "Sauvegarder",
        ShowMessage: "Afficher le message",
        TeamsMeetingLink: "Lien de la réunion Teams",
        EnterTeamsMeetingLink: "Saisir le lien de la réunion Teams",
        ContentLink: "Lien du contenu",
        EnterContentLink: "Saisir le lien vers le contenu",
        CustomButton: "Bouton personnalisé",
        EnterTitle: "Saisir le titre",
        EnterLink: "Saisir le lien",
        JoinMeeting: "Rejoindre la réunion",
        AddButton: "Ajouter un bouton",
        Important: "Important",
        All: "Tous",
        NoResults: "Aucun résultat",
        MessageOfTheDay: "Message du jour",
        InitializationInProgress: "Initialisation en cours",
        ShowAllResults: "Afficher tous les résultats",
        Item: "Élément",
        Items: "Éléments",
        ShowAllItems: "Afficher tous les {name}",
        FromDate: "À partir du",
        ToDate: "Jusqu'au",
        NothingScheduled: "Rien de prévu",
        Home: "Accueil",
        Show: "Afficher",
        Affiliates: "Affiliés",
        Rating: "Note",
        JobFamily: "Famille",
        Type: "Type",
        Topic: "Sujet",
        Source: "Source",
        Brand: "Marque",
        ShowAllMeetings: "Afficher toutes les réunions",
        ScheduledMeetings: "Réunions planifiées",
        CheckOutMessageOfTheDay: "Voir le message du jour",
        TotalQueries: "Requêtes totales",
        QueriesPerDay: "Requêtes par jour",
        MostActiveUsers: "Utilisateurs les plus actifs",
        VisitsPerDay: "Visites par jour",
        TotalVisits: "Visites totales",
        MostPopularQueries: "Requêtes les plus populaires",
        MostOpenedFiles: "Fichiers les plus ouverts",
        UsersActionsPerDay: "Actions des utilisateurs par jour",
        Reporting: "Reporting",
        Visits: "Visites",
        Visit: "Visite",
        NoDataAvailable: "Aucune donnée disponible",
        NoResult: "Aucun résultat",
        Day: "Jour",
        Week: "Semaine",
        Month: "Mois",
        Custom: "Personnalisé",
        ReportingSubtitle: "Visualisez toutes les statistiques de l'application",
        FilesStatistics: "Statistiques des fichiers",
        Title: "Titre",
        Likes: "Likes",
        Comments: "Commentaires",
        Clicks: "Clics",
        SearchFile: "Rechercher un fichier",
        ClicksPerFileType: "Clics par type de fichier",
        TotalUniqueVisits: "Visites uniques totales",
        UniqueVisit: "Visite unique",
        UniqueVisits: "Visites uniques",
        UsagePerJob: "Utilisation par emploi",
        UsagePerDepartment: "Utilisation par département",
        UsagePerLocation: "Utilisation par lieu",
        ClicksPerTopic: "Clics par sujet",
        ReturnRate: "Taux de retour",
        Download: "Télécharger",
        ClickSaveToContinue: "Cliquez sur enregistrer pour continuer",
        MeetingSidePanelMessage: "Démarrez l'application en cliquant sur le bouton ci-dessus représenté par",
        RecentComments: "Commentaires récents",
        NoComments: "Aucun commentaire",
        RetrieveDraft: "Récupérer le brouillon",
        Notifications: "Notifications",
        NotificationsSubtitle: "Envoyez des notifications à vos utilisateurs",
        Configuration: "Configuration",
        ConfigureNotificationSubtitle: "Configurez votre notification",
        Templates: "Modèles",
        NotificationTemplateSubtitle: "Sélectionnez un modèle prédéfini pour configurer votre notification",
        Subtitle: "Sous-titre",
        SaveTemplate: "Enregistrer le modèle",
        SendNotification: "Envoyer la notification",
        Preview: "Prévisualisation",
        DeleteTemplate: "Supprimer le modèle",
        AreYouSureToDeleteTheTemplate: "Êtes-vous sûr de vouloir supprimer le modèle :",
        EnterSubtitle: "Saisir le sous-titre",
        Delete: "Supprimer",
        NotificationSent: "Notification envoyée",
        NoTemplate: "Aucun modèle",
        MyDashboard: "Mon tableau de bord",
        ConsultedOrLikedContent: "Contenu consulté ou aimé",
        ConsultedOrLikedContentSubtitle: "Trouvez le contenu que vous avez consulté ou aimé",
        RecommendedContent: "Contenu recommandé",
        RecommendedContentSubtitle: "Le meilleur du contenu rassemblé par votre équipe de direction locale",
        LikedBestPractices: "Bonnes pratiques aimées",
        LikedTrainings: "Formations aimées",
        ViewedBestPractices: "Bonnes pratiques consultées",
        ViewedTrainings: "Formations consultées",
        NoItemsAvailable: "Aucun élément disponible",
        EnrichLibrary: "Enrichir la bibliothèque",
        MostLiked: "Les plus aimés",
        AlreadyLikedOrConsulted: "Déjà aimés ou consultés",
        ManagementRecommendations: "Recommandations de la direction",
        Recommend: "Recommander",
    }
}