import {Component, PropsWithChildren} from 'react';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import withRouter from "./withRouter.hook";
import store from "../../redux/store";
import {ReactPlugin, withAITracking} from "@microsoft/applicationinsights-react-js";
import {createBrowserHistory} from "history";

const browserHistory = createBrowserHistory();

const reactPlugin: any = new ReactPlugin();

export class TelemetryService extends Component<PropsWithChildren> {

    public static appInsights: ApplicationInsights | undefined = undefined;

    public static trackEvent(name: string, properties: Record<string, any>) {
        if (!this.appInsights) return console.error("Cannot track event, appInsights is not initialized");
        this.appInsights?.trackEvent({name, properties});
    }

    override componentDidMount() {
        const configuration = store.getState().configuration;
        const key = configuration?.appInsightKey;
        if (!key) return;
        TelemetryService.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: key,
                maxBatchInterval: 0,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });
        TelemetryService.appInsights.loadAppInsights();
        TelemetryService.appInsights.addTelemetryInitializer(envelope => {
            const {baseType, baseData} = envelope;
            if (!baseType || !baseData) return;
            // Log custom events
            if (baseType === "EventData") return true;
            // Log browser config and user location
            if (baseType === "PageviewData") return true;
            // Log only errors for network requests
            if (baseType === "RemoteDependencyData") return !baseData?.success;
            // Don't log anything else
            return false;
        });
    }

    override render() {
        return this.props.children;
    }
}

export default withRouter(withAITracking(reactPlugin, TelemetryService));