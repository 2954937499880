import React, {memo, ReactElement, useEffect} from "react";
import {Props} from "./Dialog.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./Dialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./Dialog.styles.scss";
import {Button, CloseIcon, ComponentSlotStyle, Flex, Text} from "@fluentui/react-northstar";

const defaultStyles: ComponentSlotStyle = {
    borderRadius: "10px",
    minWidth: "200px",
    maxWidth: "calc(100% - 50px)",
    maxHeight: "calc(100% - 50px)",
}

const fullScreenStyles: ComponentSlotStyle = {
    width: "100vw",
    height: "100vh",
}

export const Dialog = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer, initialState, props.externalRef);

    useEffect(function onBlurMaskVisibilityChange() {
        if (state.initialized) {
            if (state.hideBlurMask) props.onClose?.();
            else props.onOpen?.();
        }
        dispatch("initialize")();
    }, [state.hideBlurMask]);

    const canRenderContent = !state.hideBlurMask;

    const hideHeader = !canRenderContent || (!props.title && !props.icon);

    const dialogStyles: ComponentSlotStyle = props.fullscreen ? fullScreenStyles : defaultStyles;
    if (!props.fullscreen) {
        dialogStyles.width = (props.width ?? 200) + "px";
        dialogStyles.height = !props.height ? "fit-content" : props.height + "px";
    }

    const dialogClassName = [
        "dialog",
        "no-select",
        !props.showOverflow && "overflow-hidden",
    ].filter(i => i).join(" ");

    const dialogMaskClassName = [
        "dialog-mask",
        !state.isOpen && "dialog-mask-close"
    ].filter(i => i).join(" ");

    const dialogBlurMaskClassName = [
        "dialog-blur-mask",
        state.isOpen && "dialog-blur-mask-show",
        state.hideBlurMask && "dialog-blur-mask-hide",
    ].filter(i => i).join(" ");

    const dialogHeader = hideHeader ? null : (
        <Flex gap={"gap.small"} vAlign={"center"} className={"x-padding top-padding dialog-header"}>
            <Flex fill gap={"gap.small"} vAlign={"center"}>
                {props.icon && <props.icon width={24} height={24}/>}
                <Text size={"large"} weight={"semibold"} content={props.title}/>
            </Flex>
            <Button onClick={dispatch("close")} styles={{marginRight: "-5px"}} icon={<CloseIcon/>} iconOnly text/>
        </Flex>
    )

    const dialog = (
        <Flex column styles={dialogStyles} className={dialogClassName}
              onClick={props.closeOnClick ? dispatch("close") : stopPropagation}>
            <Flex column fill className={!props.showOverflow ? "overflow-hidden" : ""}>
                {dialogHeader}
                {canRenderContent &&
                    <Flex fill={props.fullscreen ?? false} className={!props.showOverflow ? "overflow-scroll" : ""}>
                        {props.renderContent()}
                    </Flex>
                }
                {!props.renderFooter ? null : (
                    <Flex column className={"dialog-footer"}>
                        {props.renderFooter()}
                    </Flex>
                )}
            </Flex>
        </Flex>
    )

    return <>
        <div className={dialogBlurMaskClassName}/>
        <Flex className={dialogMaskClassName}
              onClick={props.closeOnOutsideClick === false ? stopPropagation : dispatch("close")}>
            {dialog}
        </Flex>
    </>
}, CompareModule.areObjectsEqual);

const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();