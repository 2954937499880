import React, {memo, ReactElement, useEffect} from "react";
import {MenuItem, Props} from "./AutoWidthMenu.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./AutoWidthMenu.reducer";
import "./AutoWidthMenu.styles.scss";
import {ScreenModule} from "modules/Screen.module";
import {translations} from "translations";
import {Button, ChevronDownIcon, Flex, Menu, Skeleton, Text} from "@fluentui/react-northstar";
import {PopupMenuButton} from "components/PopupMenuButton/PopupMenuButton";

export const AutoWidthMenu = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState(props), props.externalRef);

    useEffect(() => {
        window.addEventListener("resize", dispatch("autoSizeTabs"));
        dispatch("autoSizeTabs")();
        return () => window.removeEventListener("resize", dispatch("autoSizeTabs"));
    }, [props.items]);

    const isSmallScreen = ScreenModule.isSmallScreen();

    const generateMenuTabs = () => {
        if (isSmallScreen) return [];
        if (!state.visibleItemsCount) return props.items;
        return props.items?.slice(0, state.visibleItemsCount);
    }

    const generateMoreMenuTabs = () => {
        if (isSmallScreen) return props.items;
        const items = props.items?.slice(state.visibleItemsCount);
        const activeTabIndex = (state.activeMenuIndex ?? -1) - state.visibleItemsCount;
        let activeTab = items?.[activeTabIndex];
        if (items && activeTab) {
            const customActiveTab = {...activeTab} as MenuItem;
            customActiveTab.styles = {fontWeight: "bold"};
            customActiveTab.className += " primary-color";
            items[activeTabIndex] = customActiveTab;
        }
        return items;
    }

    const menuTabs = generateMenuTabs();
    const showMenuActiveIndex = state.activeMenuIndex !== undefined && state.activeMenuIndex < state.visibleItemsCount;
    const moreMenuTabs = generateMoreMenuTabs();

    const tabs = isSmallScreen ? props.items : props.items?.slice(state.visibleItemsCount);
    const activeTabIndex = (state.activeMenuIndex ?? -1) - (isSmallScreen ? 0 : state.visibleItemsCount);
    let activeTab = tabs?.[activeTabIndex];

    const moreTabsText = isSmallScreen ?
        activeTab?.content ?? ""
        :
        ((props.items?.length ?? 0) - state.visibleItemsCount) + " " + translations.get("More").toLowerCase();

    if (!props.items) return (
        <Skeleton animation={"pulse"}>
            <Flex gap={"gap.small"} hAlign={props.align ?? "start"}>
                {Array.from({length: 5}).map((_, i) => <Skeleton.Shape key={i} height={"21px"} width={"80px"}/>)}
            </Flex>
        </Skeleton>
    )

    return (
        <Flex ref={dispatch("containerRef")} fill hAlign={props.align ?? "start"}>
            {isSmallScreen ? null :
                <Menu
                    styles={!!state.visibleItemsCount ? {} : {
                        marginLeft: "-999999px",
                        visibility: "hidden"
                    }}
                    className={"auto-width-menu"}
                    activeIndex={showMenuActiveIndex ? state.activeMenuIndex! : -1}
                    items={menuTabs as any}
                    onItemClick={dispatch("handleSelectItem", false)}
                    underlined
                    primary
                    ref={dispatch("handleMenuRefChange")}
                />
            }
            {state.visibleItemsCount >= (props.items?.length ?? 0) ? null :
                <PopupMenuButton
                    closeOnClick
                    position={"below"}
                    align={isSmallScreen ? "start" : "end"}
                    trigger={{
                        fill: true,
                        trigger: (
                            <Button
                                fluid={isSmallScreen}
                                primary={isSmallScreen}
                                text={!isSmallScreen}
                                styles={isSmallScreen ?
                                    {minWidth: "0"}
                                    :
                                    {margin: "0 4px", padding: "0 4px 4px 4px", minWidth: "0"}}
                                content={<Text weight={"regular"} content={moreTabsText}/>}
                                icon={<ChevronDownIcon/>}
                                iconPosition={"after"}
                            />
                        )
                    }}
                    menu={{
                        items: moreMenuTabs as any,
                        styles: isSmallScreen ? {width: "calc(100vw - 40px)"} : {},
                        onItemClick: dispatch("handleSelectItem", !isSmallScreen),
                        onTouchMove: stopPropagation,
                    }}
                />
            }
        </Flex>
    );
}, (pp, np) => {
    return (
        pp.items === np.items &&
        pp.align === np.align &&
        pp.externalRef === np.externalRef
    )
});

const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();