export enum ItemDataType {
    BestPractice, Training
}

export type ItemData = {
    type: ItemDataType,
    id: string,
    title: string,
    description: string,
    pictureUrl: string,
    likes: Array<string>,
    comments: number,
    link: string,
    rating: number,
    jobFamilies: Array<string>,
    topics: Array<string>,
    types: Array<string>,
    affiliate: string | undefined,
    creationDate: string,
    lastUpdate: string,
    brand: string | undefined,
    source: string | undefined,
    clicks: number,
    returnRate: number,
    canInteract: boolean,
}