import React, {memo, ReactElement, useMemo} from "react";
import {Props} from "./SideMenu.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {generateMenuItems, initialState, reducer} from "./SideMenu.reducer";
import {CompareModule} from "modules/Compare.module";
import "./SideMenu.styles.scss";
import {Flex, Image, Text} from "@fluentui/react-northstar";
import SideMenuItem from "./SideMenuItem/SideMenuItem";
import StarvoiceLogo from "../../../assets/images/starvoice_logo.svg";
import {QuestionCircleIcon} from "../../../assets/icons";
import {translations} from "../../../translations";

export const SideMenu = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState(props), props.externalRef);

    const items = useMemo(() => generateMenuItems(), []);

    const renderedItems = useMemo(() => {
        const renderedItems = items.map(item => {
            const isSelected = state.selectedView === item.id;
            return (
                <React.Fragment key={item.path}>
                    <SideMenuItem
                        onItemSelected={dispatch("handleItemSelected", item.id)}
                        item={item}
                        isSelected={isSelected}
                    />
                </React.Fragment>
            )
        });
        return (
            <Flex fill column className={"overflow-scroll"} gap={"gap.smaller"}>
                {renderedItems}
            </Flex>
        )
    }, [items, state.selectedView]);

    const menuClassName = [
        "menu",
        "no-select"
    ].join(" ");

    const menuMaskClassName = [
        "menu-mask",
        state.show ? "menu-mask-open" : "menu-mask-close"
    ].join(" ");

    const menuBlurMaskClassName = [
        "menu-blur-mask",
        state.show && "menu-blur-mask-show",
        state.hideBlurMask && "menu-blur-mask-hide",
    ].filter(i => i).join(" ");

    const menu = (
        <Flex column className={menuClassName}>
            <Flex fill column gap={"gap.large"} className={"overflow-hidden"}>
                <Image className={"menu-brand-icon"} src={StarvoiceLogo}/>
                {renderedItems}
                <Flex vAlign={"center"} gap={"gap.smaller"} onClick={dispatch("openHelpPage")}>
                    <QuestionCircleIcon fill={"white"} width={40} height={40}/>
                    <Text size={"large"} content={translations.get("Help")}/>
                </Flex>
            </Flex>
        </Flex>
    )

    return <>
        <div className={menuBlurMaskClassName}/>
        <Flex className={menuMaskClassName} onClick={dispatch("handleClose")}>
            {menu}
        </Flex>
    </>
}, CompareModule.areObjectsEqual);