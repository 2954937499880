import {State} from "./Dashboard.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {HistoryService} from "../../services/HistoryService/HistoryService.hook";

export const dashboardViewCacheKey = "dashboard-view";

export const initialState = (): State => {
    const historyState = HistoryService.getComponentState<State>(dashboardViewCacheKey);
    if (historyState) return {
        ...historyState,
        slidesPerView: getSlidesPerView(),
    }
    return {
        slidesPerView: getSlidesPerView(),
    }
}

export const reducer = () => ({
    windowResize: ({state, setState}) => {
        const slidesPerView = getSlidesPerView();
        if (state.slidesPerView === slidesPerView) return;
        setState({slidesPerView});
    },
}) satisfies MagicReducerObject<State>;

export const getSlidesPerView = () => {
    return Math.round(window.innerWidth / 450);
}