import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import axios, {AxiosError, CancelTokenSource} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ReportingData} from "../../interfaces/ReportingData";
import {UserReport} from "../../interfaces/UserReport";

let getReportCancelToken: CancelTokenSource | undefined = undefined;
let downloadReportCancelToken: CancelTokenSource | undefined = undefined;

const getReport = (start: string, end: string) => new Promise<Immutable<ReportingData> | undefined>(async (resolve) => {
    if (getReportCancelToken) getReportCancelToken.cancel();
    getReportCancelToken = axios.CancelToken.source();
    try {
        const response = await AxiosConfig.instance.post("/reporting", {
            start,
            end
        }, {cancelToken: getReportCancelToken.token});
        return resolve(response.data);
    } catch (error) {
        if (axios.isCancel(error)) return resolve(undefined);
        ErrorModule.showErrorAlert("Can't get reporting data", error as AxiosError);
        resolve(undefined);
    }
});

const downloadReport = (start: string, end: string) => new Promise<Blob | undefined>(async (resolve) => {
    if (downloadReportCancelToken) downloadReportCancelToken.cancel();
    downloadReportCancelToken = axios.CancelToken.source();
    try {
        const response = await AxiosConfig.instance.post("/reporting/download", {
            start,
            end
        }, {
            cancelToken: downloadReportCancelToken.token,
            responseType: "blob"
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        return resolve(blob);
    } catch (error) {
        if (axios.isCancel(error)) return resolve(undefined);
        ErrorModule.showErrorAlert("Can't download reporting data", error as AxiosError);
        return resolve(undefined);
    }
});

const getSessionsUserIds = () => new Promise<Immutable<Array<string>>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get(`/reporting/sessions/userIds`);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user ids", error as AxiosError);
        resolve([]);
    }
});

const getMyReport = () => new Promise<Immutable<UserReport | undefined>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get(`/reporting/users/me`);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get user report", error as AxiosError);
        resolve(undefined);
    }
});

export const ReportingApi = {
    getReport,
    downloadReport,
    getSessionsUserIds,
    getMyReport
}