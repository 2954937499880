import {useQuery} from "react-query";
import {GraphService} from "../GraphService/GraphService";
import {UserApi} from "../../apis/User/UserApi";
import {LocalCacheService} from "./CacheService.hook";
import {UserData, UserVisitedItems} from "../../interfaces/UserData";
import {queryClient} from "../../index";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {ItemDataType} from "../../interfaces/ItemData";
import {UserReportCacheActions} from "./userReportCache";
import {useEffect, useState} from "react";
import {ObjectModule} from "../../modules/Object.module";
import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";

export const userDataCacheKey = "current_user_data";

const getOldCachedVisitedItems = (): UserVisitedItems => {
    const cachedVisitedItems = LocalCacheService.getItem<Array<string>>("visited_items") ?? [];
    const bestPractices = cachedVisitedItems
        .filter(item => item.startsWith(ItemDataType.BestPractice + "_"))
        .map(item => item.split("_")[1] ?? "")
        .filter(i => i);
    const trainings = cachedVisitedItems
        .filter(item => item.startsWith(ItemDataType.Training + "_"))
        .map(item => item.split("_")[1] ?? "")
        .filter(i => i);
    return {
        bestPractices,
        trainings,
    }
}

const fetchUserData = async (): Promise<Immutable<UserData> | undefined> => {
    let userData = await UserApi.getUserData();
    if (userData) return userData;
    const graphUserData = await GraphService.getMe();
    userData = await UserApi.createUserData({
        id: graphUserData.id,
        upn: graphUserData.userPrincipalName.toLowerCase(),
        name: graphUserData.displayName,
        jobTitle: graphUserData.jobTitle,
        department: graphUserData.department,
        country: graphUserData.country,
        visitedItems: getOldCachedVisitedItems(),
    });
    return userData;
}

const getUserData = (): Immutable<UserData> | undefined => {
    return queryClient.getQueryData<Immutable<UserData>>(userDataCacheKey);
}

const updateUserData = async (userData: UserData) => {
    queryClient.setQueryData(userDataCacheKey, userData);
    await UserApi.updateUserData(userData);
};

const hasUserVisitedItem = (type: ItemDataType | undefined, itemId: string | undefined): boolean => {
    if (type === undefined || itemId === undefined) return false;
    const userData = getUserData();
    if (!userData) return false;
    switch (type) {
        case ItemDataType.BestPractice:
            return userData.visitedItems.bestPractices.includes(itemId);
        case ItemDataType.Training:
            return userData.visitedItems.trainings.includes(itemId);
    }
}

const markItemVisitedByUser = (setIsVisited: (isVisited: boolean) => void, type: ItemDataType | undefined, itemId: string | undefined) => () => {
    if (type === undefined || itemId === undefined) return;
    const userData = getUserData();
    if (!userData) return ErrorModule.showErrorAlert("Can't mark item as visited");
    const clonedUserData = ObjectModule.deepClone(userData);
    switch (type) {
        case ItemDataType.BestPractice:
            if (!clonedUserData.visitedItems.bestPractices.includes(itemId)) {
                clonedUserData.visitedItems.bestPractices.push(itemId);
                UserReportCacheActions.incrementReportValue("exploredBestPractices");
            }
            break;
        case ItemDataType.Training:
            if (!clonedUserData.visitedItems.trainings.includes(itemId)) {
                clonedUserData.visitedItems.trainings.push(itemId);
                UserReportCacheActions.incrementReportValue("exploredTrainings");
            }
            break;
    }
    setIsVisited(true);
    updateUserData(clonedUserData).then();
}

export const useUserDataCache = (fetchData: boolean = false) => {
    const {data: userData} = useQuery(userDataCacheKey, fetchUserData, {
        staleTime: Infinity,
        enabled: fetchData,
    });
    return {
        userData,
        hasUserVisitedItem,
    };
};

export const useUserVisitedItemCache = (type: ItemDataType | undefined, itemId: string | undefined) => {
    const [isVisited, setIsVisited] = useState<boolean>(false);

    useEffect(() => {
        setIsVisited(hasUserVisitedItem(type, itemId));
    }, [type, itemId]);

    return {
        isVisited,
        markItemVisited: markItemVisitedByUser(setIsVisited, type, itemId),
    };
};