import {Props, State} from "./ItemCard.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React, {startTransition} from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import {ReportingModule} from "../../modules/Reporting.module";
import {useItemLikeCache} from "../../services/CacheService/likesCache";

export const initialState: State = {
    imageLoaded: false,
}

export const reducer = (
    props: Props,
    toggleLike: ReturnType<typeof useItemLikeCache>["toggleLike"],
    userId: string,
    markItemVisited: () => void,
) => ({
    handleClick: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        const link = props.item?.link;
        if (!link) return;
        window.open(link, "_blank");
        ReportingModule.notifyItemOpened(userId, props.item?.id, props.item?.type);
        markItemVisited();
    },
    handleToggleLike: async (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        if (!props.item?.id) return;
        await toggleLike();
    },
    handleShare: async (_, [e]: [React.SyntheticEvent], link: string) => {
        e.stopPropagation();
        await microsoftTeams.sharing.shareWebContent({
            content: [{
                url: link,
                type: "URL",
                preview: true,
            }]
        }).catch(() => 0);
    },
    handleAddComment: (_, [e]: [React.SyntheticEvent]) => {
        e.stopPropagation();
        if (!props.item) return;
        props.itemDialogRef?.dispatch("handleOpen")(props.item);
    },
    handleImageLoaded: (reducerData) => {
        startTransition(() => reducerData.setState({imageLoaded: true}));
    }
}) satisfies MagicReducerObject<State>;

export const stopPropagation = (e: React.SyntheticEvent) => e.stopPropagation();