import {ThemeInput} from "@fluentui/react-northstar";
import variables from "variables.module.scss";
import {ThemeClass} from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer.interfaces";

const lightBrandColors = {
    50: "#ccd9e5",
    100: "#99b3cb",
    200: "#668eb0",
    300: "#336896",
    400: variables.blueColorLight,
    450: variables.blueColorLight,
    500: variables.blueColorLight,
    600: variables.blueColorLight,
    700: "#003563",
    800: "#00284a",
    900: "#001a32",
    1000: "#000d19",
}

const darkBrandColors = {
    50: "#cce7ff",
    100: "#99cfff",
    200: "#66b8ff",
    300: "#33a0ff",
    400: variables.blueColorDark,
    450: variables.blueColorDark,
    500: variables.blueColorDark,
    600: variables.blueColorDark,
    700: "#006dcc",
    800: "#005299",
    900: "#003666",
    1000: "#001b33",
}

export const getBrandColors = (theme: ThemeClass) => {
    return theme === ThemeClass.Default ? lightBrandColors : darkBrandColors;
}

export const ThemeCustomizations = (theme: ThemeClass): ThemeInput => {
    const brandColors = getBrandColors(theme);
    const isLightTheme = theme === ThemeClass.Default;
    const calendarCellSelectedColor = isLightTheme ? "white" : "black";
    const calendarCellHoverBackgroundColor = isLightTheme ? "#eee" : "#333";
    return {
        componentVariables: {
            DatepickerCalendarCellButton: {
                calendarCellSelectedColor,
                calendarCellSelectedBackgroundColor: brandColors[500],
                calendarCellTodayBackgroundColor: variables.orangeColor,
                calendarCellHoverBackgroundColor,
            },
            DatepickerCalendarGridRow: {
                calendarCellHoverBackgroundColor,
            }
        },
        animations: {
            fadein: {
                keyframe: {
                    '0%': {
                        opacity: 0,
                        transform: "scale(0.95)"
                    },
                    '100%': {
                        opacity: 1,
                        transform: "scale(1)"
                    },
                },
                duration: '200ms',
            },
            fadeout: {
                keyframe: {
                    '0%': {
                        opacity: 1,
                        transform: "scale(1)"
                    },
                    '100%': {
                        opacity: 0,
                        transform: "scale(0.95)"
                    },
                },
                duration: '200ms',
            },
        },
        siteVariables: {
            colors: {brand: brandColors},
            colorScheme: {
                brand: {
                    foreground: brandColors[600],
                    foreground1: brandColors[500],
                    foreground2: brandColors[700],
                    foreground3: brandColors[200],
                    background: brandColors[600],
                    background1: brandColors[100],
                    background2: brandColors[900],
                    background3: brandColors[1000],
                    background4: brandColors[800],
                    background5: brandColors[100],
                    background6: brandColors[600],
                    border1: brandColors[200],
                    border2: "transparent",
                    foregroundHover: brandColors[600],
                    foregroundHover2: brandColors[200],
                    borderHover: brandColors[300],
                    backgroundHover: brandColors[700],
                    backgroundHover1: brandColors[50],
                    backgroundHover2: brandColors[100],
                    backgroundHover3: brandColors[100],
                    foregroundPressed: brandColors[800],
                    backgroundPressed: brandColors[800],
                    backgroundPressed1: brandColors[100],
                    backgroundPressed2: brandColors[100],
                    borderPressed: brandColors[300],
                    foregroundActive: brandColors[600],
                    foregroundActive1: brandColors[600],
                    foregroundActive2: brandColors[200],
                    backgroundActive: brandColors[600],
                    backgroundActive1: brandColors[600],
                    borderActive1: brandColors[200],
                    borderActive2: brandColors[300],
                    foregroundFocus: brandColors[600],
                    foregroundFocus1: brandColors[600],
                    foregroundFocus2: brandColors[700],
                    foregroundFocus3: brandColors[200],
                    backgroundFocus: brandColors[600],
                    backgroundFocus1: brandColors[100],
                    backgroundFocus2: brandColors[900],
                    backgroundFocus3: brandColors[1000],
                    borderFocus1: brandColors[600],
                },
            }
        }
    }
}