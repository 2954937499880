import {DateRangeType, Props, State} from "./DateRangeSelector.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {DatepickerProps, PopperRefHandle} from "@fluentui/react-northstar";
import moment from "moment/moment";

export const initialState: State = {
    selectionMode: DateRangeType.Week,
    isCustomEndDateSelected: false,
    selectedDate: moment().startOf("day").toISOString(),
}

export const reducer = (config: {
    props: Props,
    datePickerPopperRef: React.MutableRefObject<PopperRefHandle | null>,
    popupContentRef: React.MutableRefObject<HTMLDivElement | null>,
}) => ({
    handleClickTrigger: () => {
        setTimeout(() => {
            if (config.popupContentRef.current) config.popupContentRef.current!.className += " date-range-selector-picker";
            config.datePickerPopperRef.current?.updatePosition();
        }, 0);
    },
    handleSwitchMode: ({state, setState}, _, mode: State["selectionMode"]) => {
        setTimeout(() => config.datePickerPopperRef.current?.updatePosition(), 0);
        setState({selectionMode: mode});
        const {start, end} = getRangeFromDate({
            date: state.selectedDate,
            selectionMode: state.selectionMode,
            isCustomEndDateSelected: state.isCustomEndDateSelected,
            dateRange: config.props.dateRange,
        });
        config.props.onDateRangeChange(start, end);
    },
    handleChangeDate: ({state, setState}, [, datePickerData]: [React.SyntheticEvent, DatepickerProps | undefined]) => {
        const value = (datePickerData as DatepickerProps & { value: Date })?.value?.toISOString();
        setState({selectedDate: value}, false);
        const {start, end} = getRangeFromDate({
            date: value,
            selectionMode: state.selectionMode,
            isCustomEndDateSelected: state.isCustomEndDateSelected,
            dateRange: config.props.dateRange,
        })
        config.props.onDateRangeChange(start, end);
    },
    handleToggleSelectedCustomDate: ({setState}, _, isEndDate: boolean) => {
        setState({isCustomEndDateSelected: isEndDate});
    },
    handleChangeMonth: ({state, setState}, _, action: "next" | "previous") => {
        if (state.selectionMode !== DateRangeType.Month) return;
        const date = moment(state.selectedDate).add(action === "next" ? 1 : -1, "month").toISOString();
        setState({selectedDate: date}, false);
        const {start, end} = getRangeFromDate({
            date,
            selectionMode: state.selectionMode,
            isCustomEndDateSelected: state.isCustomEndDateSelected,
            dateRange: config.props.dateRange,
        })
        config.props.onDateRangeChange(start, end);
    }
}) satisfies MagicReducerObject<State>;

const getRangeFromDate = (data: {
    date: string,
    selectionMode: State["selectionMode"],
    isCustomEndDateSelected: State["isCustomEndDateSelected"],
    dateRange: Props["dateRange"],
}) => {
    const {date, selectionMode, isCustomEndDateSelected, dateRange} = data;
    let start = "";
    let end = "";
    switch (selectionMode) {
        case DateRangeType.Day:
            start = date;
            end = moment(date).endOf("day").toISOString();
            break;
        case DateRangeType.Week:
            start = moment(date).startOf("week").toISOString();
            end = moment(date).endOf("week").toISOString();
            break;
        case DateRangeType.Month:
            start = moment(date).startOf("month").toISOString();
            end = moment(date).endOf("month").toISOString();
            break;
        case DateRangeType.Custom:
            start = moment(dateRange?.start).startOf("day").toISOString(false);
            end = moment(dateRange?.end).endOf("day").toISOString(false);
            if (isCustomEndDateSelected) {
                end = moment(date).endOf("day").toISOString(false);
                if (end <= start) start = moment(end).add(-1, "day").toISOString(false);
            } else {
                start = date;
                if (start >= end) end = moment(start).add(1, "day").toISOString(false);
            }
            break;
        default:
            break;
    }
    return {start, end};
}

const getShortDaysNames = (locale: string) => {
    const prevLocale = moment.locale();
    moment.locale(locale);
    const shortDaysNames = moment.weekdays(false).map(day => day.slice(0, 3));
    moment.locale(prevLocale);
    return shortDaysNames;
}

const getMonthsNames = (locale: string) => {
    return Array.from(Array(12).keys()).map(key => moment().locale(locale).month(key).format('MMMM'))
}

export const DateRangeSelectorHelpers = {
    getRangeFromDate,
    getShortDaysNames,
    getMonthsNames,
}