import React, {memo, ReactElement} from "react";
import {Props} from "./DeleteTemplateDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./DeleteTemplateDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./DeleteTemplateDialog.styles.scss";
import {Dialog} from "../../../components/Dialog/Dialog";
import {DialogRef} from "../../../components/Dialog/Dialog.interfaces";
import {Button, Flex, Text, TrashCanIcon} from "@fluentui/react-northstar";
import {translations} from "../../../translations";
import {useNotificationTemplatesCache} from "../../../services/CacheService/notificationTemplatesCache";

export const DeleteTemplateDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef<DialogRef>();
    const {deleteTemplate} = useNotificationTemplatesCache();
    const [state, dispatch] = useMagicReducer(reducer({dialogRef, deleteTemplate}), initialState, props.externalRef);

    return (
        <Dialog
            width={450}
            title={translations.get("DeleteTemplate")}
            externalRef={dialogRef}
            renderContent={() => (
                <Flex fill column className={"x-padding y-padding overflow-hidden"} styles={{gap: "3px"}}>
                    <Text content={translations.get("AreYouSureToDeleteTheTemplate")}/>
                    <Text truncated content={state.notification?.title} weight={"semibold"}/>
                </Flex>
            )}
            renderFooter={() => (
                <Flex hAlign={"end"}>
                    <Button
                        icon={<TrashCanIcon outline/>}
                        className={state.isDeleting ? "" : "delete-btn"} primary
                        content={translations.get("Delete")}
                        onClick={dispatch("confirmDelete")}
                        disabled={state.isDeleting}
                        loading={state.isDeleting}
                    />
                </Flex>
            )}
        />
    )
}, CompareModule.areObjectsEqual);