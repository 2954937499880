import React, {ReactElement} from "react";
import {Props, TopKeywordData} from "./KeywordsKpi.interfaces";
import {Flex, Loader, Text} from "@fluentui/react-northstar";
import {translations} from "../../../../translations";
import {Immutable} from "@witivio_teamspro/use-reducer";

export const KeywordsKpi = (props: Props): ReactElement | null => {
    const sortedKeywords = [...(props.keywords ?? [])].sort((a, b) => b.count - a.count).slice(0, 5);

    const requestsClassName = [
        "overflow-hidden",
        props.onClick ? "cursor-pointer" : "",
    ].join(" ");

    const requests = sortedKeywords.map((r, i) => {
        const indicator = props.renderCustomIndicator?.(r.count) ?? "x" + r.count;
        return (
            <Flex key={r.name} gap={"gap.small"} vAlign={"center"} className={requestsClassName}
                  onClick={handleClickItem(r, props.onClick)}>
                <Text
                    styles={{whiteSpace: "nowrap"}}
                    className={"primary-text"}
                    truncated
                    content={(i + 1) + ". " + r.name}
                    weight={"bold"}
                    size={"larger"}
                    title={r.name}
                />
                {!props.hideIndicator &&
                    <Text
                        title={indicator}
                        content={indicator}
                        styles={{color: "darkgray", whiteSpace: "nowrap"}}
                        size={"large"}
                    />
                }
            </Flex>
        )
    });

    return (
        <Flex column fill className={"overflow-hidden"}>
            {!props.title ? null : (
                <Flex>
                    <Text truncated weight={"semibold"} size={"larger"} content={props.title}/>
                </Flex>
            )}
            {props.showSkeletons ?
                <Loader styles={{height: "100%"}}/>
                :
                <Flex fill vAlign={"center"} hAlign={"center"} className={"overflow-hidden"}>
                    <Flex column gap={"gap.medium"} className={"overflow-hidden"}>
                        {requests.length === 0 ?
                            <Text truncated size={"large"} content={translations.get("NoResult")}/>
                            :
                            requests
                        }
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}

const handleClickItem = (item: Immutable<TopKeywordData>, onClick: Props["onClick"]) => () => {
    if (!onClick) return;
    onClick(item);
}