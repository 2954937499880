import React, {memo, ReactElement} from "react";
import {Props} from "./TemplateCard.interfaces";
import {useMagicReducer} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./TemplateCard.reducer";
import {CompareModule} from "modules/Compare.module";
import "./TemplateCard.styles.scss";
import {Button, Flex, Text, TrashCanIcon} from "@fluentui/react-northstar";

export const TemplateCard = memo((props: Props): ReactElement | null => {
    const [state, dispatch] = useMagicReducer(reducer(props), initialState);

    const {title, subtitle} = props.notification;

    return (
        <Flex className={"template-card"} vAlign={"center"} onClick={dispatch("selectCard")}>
            <Flex fill column className={"overflow-hidden"}>
                <Text weight={"semibold"} content={title}/>
                <Text content={subtitle}/>
            </Flex>
            <Flex className={"template-card-actions"}>
                <Button iconOnly text icon={<TrashCanIcon outline/>} onClick={dispatch("deleteCard")}/>
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);