import {cloneElement, isValidElement} from "react";
import {Mutable} from "@witivio_teamspro/use-reducer";

const deepClone = <T>(source: T): Mutable<T> => {
    if (source === null || typeof source !== 'object') return source as Mutable<T>;
    if (isValidElement(source)) return cloneElement(source) as Mutable<T>;
    if (Array.isArray(source)) return source.map((item) => deepClone(item)) as Mutable<T>;
    const clonedObj = {} as Mutable<T>;
    for (const key in source) {
        // @ts-ignore
        if (source.hasOwnProperty(key)) clonedObj[key] = deepClone(source[key]);
    }
    return clonedObj;
}

export const ObjectModule = {
    deepClone
}