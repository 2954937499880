import React, {memo, ReactElement} from "react";
import {Props} from "./StatItem.interfaces";
import {CompareModule} from "modules/Compare.module";
import "./StatItem.styles.scss";
import {Flex, Skeleton, Text} from "@fluentui/react-northstar";

export const StatItem = memo((props: Props): ReactElement | null => {
    const iconSize = props.iconSize ?? 50;
    return (
        <Skeleton animation={"pulse"}>
            <Flex className={"user-profile-stat-item"} vAlign={"center"} hAlign={"center"} styles={{gap: "8px"}}>
                <Flex className={"user-profile-stat-item-icon-container"}>
                    <props.icon className={"primary-text"} height={iconSize} width={iconSize}/>
                </Flex>
                <Flex column>
                    <Flex styles={{gap: "3px"}} vAlign={"end"}>
                        {props.skeleton ?
                            <>
                                <Skeleton.Text size={"larger"}/>
                                <Skeleton.Text/>
                            </>
                            :
                            <>
                                <Text
                                    size={"larger"} weight={"bold"} className={"primary-text"}
                                    content={props.value}
                                />
                                <Text
                                    className={"user-profile-stat-item-sub-value"}
                                    content={props.subValue}
                                />
                            </>
                        }
                    </Flex>
                    <Flex>
                        <Text
                            className={"user-profile-stat-item-description primary-text"}
                            content={props.description}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Skeleton>
    );
}, CompareModule.areObjectsEqual);