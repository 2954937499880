import {Props, State} from "./AutoWidthMenu.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import React from "react";
import {MenuItemProps} from "@fluentui/react-northstar";

export const initialState = (props: Props): State => ({
    loaded: false,
    activeMenuIndex: props.defaultMenuIndex,
    visibleItemsCount: 0,
    tabsWidth: [],
    container: null,
})

export const reducer = (props: Props) => ({
    handleSelectItem: (reducerData, [, data]: [React.SyntheticEvent<HTMLElement>, MenuItemProps | undefined], fromMoreMenu?: boolean) => {
        const {state} = reducerData;
        let itemPosition = data?.itemPosition;
        if (itemPosition === undefined) return;
        if (fromMoreMenu) itemPosition += state.visibleItemsCount;
        const itemIndex = itemPosition - 1;
        reducer(props).setActiveMenuIndex(reducerData, [itemIndex]);
    },
    handleMenuRefChange: (reducerData, [menuRef]: [HTMLUListElement | null]) => {
        const {state, setState} = reducerData;
        const tabsItems = menuRef?.children ?? [];
        if (tabsItems.length === 0 || state.tabsWidth.length > 0) return;
        const tabsWidth = new Array<number>();
        for (let i = 0; i < tabsItems.length; i++) {
            const childWidth = tabsItems[i]?.clientWidth ?? 0;
            tabsWidth.push(childWidth);
        }
        setState({tabsWidth}, false);
        reducer(props).autoSizeTabs(reducerData);
    },
    autoSizeTabs: ({state, setState}) => {
        const containerWidth = (state.container?.clientWidth ?? 0);
        const flexGap = 8;
        const availableFreeSpace = containerWidth - flexGap - 150;
        let tabsWidth = 0;
        let visibleItemsCount = 0;
        for (let i = 0; i < state.tabsWidth.length; i++) {
            const childWidth = state.tabsWidth[i] ?? 0;
            if ((tabsWidth + childWidth) <= availableFreeSpace) {
                tabsWidth += childWidth;
                visibleItemsCount += 1;
            } else break;
        }
        setState({visibleItemsCount});
    },
    setActiveMenuIndex: (reducerData, [index]: [number]) => {
        const {setState} = reducerData;
        setState({activeMenuIndex: index});
        reducer(props).scrollTop(reducerData);
        props.onIndexChange(index);
    },
    scrollTop: ({state}) => {
        state.container?.scrollTo({top: 0, left: 0});
    },
    containerRef: ({setState}, [container]: [HTMLDivElement | null]) => {
        setState({container}, false);
    }
}) satisfies MagicReducerObject<State>;