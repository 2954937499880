import {Immutable} from "@witivio_teamspro/use-reducer";
import {ItemData, ItemDataType} from "../interfaces/ItemData";
import {FilterItemsData} from "../components/Filter/Filter.interfaces";
import moment from "moment/moment";
import {translations} from "../translations";
import {useUserDataCache} from "../services/CacheService/userDataCache";

const getCheckedItems = (filterItems: Immutable<FilterItemsData> | undefined, filterKey: string) => {
    if (!filterItems) return [];
    return Object.keys(filterItems[filterKey] ?? {})
        // @ts-ignore
        .filter(key => filterItems[filterKey]?.[key].isChecked);
}

const removeEmojis = (str: string): string => {
    return str.replace(/\p{Emoji}/gu, "").replace(/^\W+/g, "");
};

const sortItemsByTitle = (items: Immutable<Array<ItemData>>) => {
    return [...items].sort((a, b) => removeEmojis(a.title).localeCompare(removeEmojis(b.title)));
}

const searchItems = (items: Immutable<Array<ItemData>> | undefined, query: string | undefined) => {
    if (!items || !query) return items ?? [];
    const keywords = query.toLowerCase().trim().replace(/-/g, " ").split(" ");
    const isValidString = (str: string | undefined) => {
        return keywords.every(k => str?.toLowerCase().replace(/-/g, " ").includes(k.toLowerCase()));
    }
    return items.filter(i => (
        isValidString(i.title) ||
        isValidString(i.description) ||
        i.topics.some(t => isValidString(t)) ||
        i.jobFamilies.some(t => isValidString(t)) ||
        i.types.some(t => isValidString(t)) ||
        isValidString(i.brand) ||
        isValidString(i.source) ||
        isValidString(i.affiliate)
    ));
}

const filterItems = (data: {
    items: Immutable<Array<ItemData>> | undefined,
    userUpn: string,
    selectedCategoryKey?: string | undefined,
    query?: string | undefined,
    filterItems?: Immutable<FilterItemsData> | undefined,
    hasUserVisitedItem?: ReturnType<typeof useUserDataCache>["hasUserVisitedItem"],
}) => {
    const {items, selectedCategoryKey, filterItems, userUpn} = data;
    if (!items) return undefined;
    let filteredItems = [...items];
    if (selectedCategoryKey) filteredItems = filteredItems.filter(bp => bp.topics.includes(selectedCategoryKey));
    filteredItems = [...searchItems(filteredItems, data.query)];
    if (!filterItems) {
        filteredItems = sortItemsByRank(filteredItems);
        return filteredItems;
    }
    const checkedAffiliates = getCheckedItems(filterItems, "affiliates");
    if (checkedAffiliates.length > 0) filteredItems = filteredItems.filter(bp => checkedAffiliates.includes(bp.affiliate ?? ""));
    const checkedTopics = getCheckedItems(filterItems, "topic");
    if (checkedTopics.length > 0) filteredItems = filteredItems.filter(bp => bp.topics.some(t => checkedTopics.includes(t)));
    const checkedJobFamilies = getCheckedItems(filterItems, "job-family");
    if (checkedJobFamilies.length > 0) filteredItems = filteredItems.filter(bp => bp.jobFamilies.some(t => checkedJobFamilies.includes(t)));
    const checkedTypes = getCheckedItems(filterItems, "type");
    if (checkedTypes.length > 0) filteredItems = filteredItems.filter(bp => bp.types.some(t => checkedTypes.includes(t)));
    const checkedRatings = getCheckedItems(filterItems, "rating");
    if (checkedRatings.length > 0) filteredItems = filteredItems.filter(bp => checkedRatings.includes(bp.rating.toString()));
    const checkedSources = getCheckedItems(filterItems, "source");
    if (checkedSources.length > 0) filteredItems = filteredItems.filter(bp => checkedSources.includes(bp.source ?? ""));
    const checkedBrands = getCheckedItems(filterItems, "brand");
    if (checkedBrands.length > 0) filteredItems = filteredItems.filter(bp => checkedBrands.includes(bp.brand ?? ""));
    const checkedFromDate = (filterItems["from-date"]?.isChecked ? filterItems["from-date"].inputValue : "") as string;
    if (checkedFromDate) filteredItems = filteredItems
        .filter(bp => moment(bp.creationDate).startOf("day").valueOf() >= moment(checkedFromDate).startOf("day").valueOf());
    const checkedToDate = (filterItems["to-date"]?.isChecked ? filterItems["to-date"].inputValue : "") as string;
    if (checkedToDate) filteredItems = filteredItems
        .filter(bp => moment(bp.creationDate).startOf("day").valueOf() <= moment(checkedToDate).startOf("day").valueOf());

    if (userUpn && filterItems["already-liked-or-consulted"]?.isChecked) {
        filteredItems = filteredItems.filter(bp => data.hasUserVisitedItem?.(bp.type, bp.id) || bp.likes.includes(userUpn));
    }

    if (filterItems["most-liked"]?.isChecked) {
        filteredItems = filteredItems.filter(bp => bp.likes.length > 0);
        filteredItems.sort((a, b) => b.likes.length - a.likes.length);
    } else {
        filteredItems = sortItemsByRank(filteredItems);
    }

    return filteredItems;
}

const generateCategories = (items: Immutable<Array<ItemData>> | undefined) => {
    if (!items) return undefined;
    const categories = Array.from(new Set(items.map(i => i.topics).flat()));
    const categoryItems = categories.map(c => ({key: c, content: c}));
    categoryItems.sort((a, b) => a.content.localeCompare(b.content));
    return [
        {key: "all", content: translations.get("All")},
        ...categoryItems,
    ]
}

const mapItemsToSearchDataItems = (items: Immutable<Array<ItemData>> | undefined, type: ItemDataType) => {
    if (!items) return [];
    return items?.map(i => ({
        id: i.id,
        title: i.title,
        description: i.description,
        link: i.link,
        type,
    }))
}

const sortItemsByRank = (items: Immutable<Array<ItemData>>) => {
    if (!items) return [];
    return [...items].sort((a, b) => {
        const aScore = getItemScore(a);
        const bScore = getItemScore(b);
        return bScore - aScore;
    });
}

const getItemScore = (item: Immutable<ItemData>) => {
    let score = new Date(item.lastUpdate).getTime();
    const dayTimeInMs = 1000 * 60 * 60 * 24;
    score += item.likes.length * dayTimeInMs;
    score += item.comments * dayTimeInMs;
    return score;
}

export const ItemDataModule = {
    filterItems,
    generateCategories,
    mapItemsToSearchDataItems,
    sortItemsByTitle,
    removeEmojis,
    sortItemsByRank,
}