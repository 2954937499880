import {State} from "./ItemDialog.interfaces";
import {Immutable, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useForm} from "@witivio_teamspro/northstar-form";
import {ItemData, ItemDataType} from "../../interfaces/ItemData";
import {DialogRef} from "../Dialog/Dialog.interfaces";
import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";
import {MentionModule} from "../../modules/Mention.module";
import {TrainingApi} from "../../apis/Training/TrainingApi";
import {UserReportCacheActions} from "../../services/CacheService/userReportCache";
import {CommentsCacheActions} from "../../services/CacheService/commentsCache";

export const initialState: State = {
    isOpen: false,
    hideBlurMask: true,
    item: undefined,
    isPublishing: false,
}

export const reducer = (
    formData: ReturnType<typeof useForm>,
    dialogRef: DialogRef,
) => ({
    handleOpen: ({setState}, [item]: [Immutable<ItemData>]) => {
        formData.reset();
        setState({item, isPublishing: false});
        dialogRef.dispatch("open")();
    },
    handleClose: () => {
        dialogRef.dispatch("close")();
    },
    handlePublish: async (reducerData) => {
        const {setState} = reducerData;
        const comment = (formData.dataRef.current?.comment ?? "") + "";
        if (!reducerData.state.item?.id || !comment) return;
        const formattedComment = formatCommentToSharepoint(comment);
        setState({isPublishing: true});
        switch (reducerData.state.item?.type) {
            case ItemDataType.BestPractice:
                await BestPracticeApi.postComment(reducerData.state.item?.id, formattedComment);
                break;
            case ItemDataType.Training:
                await TrainingApi.postComment(reducerData.state.item?.id, formattedComment);
                break;
        }
        UserReportCacheActions.incrementReportValue("comments");
        CommentsCacheActions.incrementCommentsCount(reducerData.state.item?.type, reducerData.state.item?.id);
        setState({isPublishing: false});
        dialogRef.dispatch("close")();
    }
}) satisfies MagicReducerObject<State>;

const convertHtmlToPlainText = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

const formatCommentToSharepoint = (comment: string): string => {
    const extractedMentions = MentionModule.extractMentions(comment);
    let plainText = convertHtmlToPlainText(comment);
    extractedMentions.forEach(mention => {
        const mentionRegex = new RegExp(mention.mentionText, "g");
        const replaceText = `<a data-sp-mention-user-id=\\\"${mention.mentionId}\\\">${mention.mentionText}</a>`;
        plainText = plainText.replace(mentionRegex, replaceText);
    });
    return plainText;
}