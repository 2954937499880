import React, {memo, ReactElement} from "react";
import {KpiPropsUnion} from "./Kpi.interfaces";
import "./Kpi.styles.scss";
import {ComponentSlotStyle, Flex} from "@fluentui/react-northstar";
import NumberKpi from "./NumberKpi/NumberKpi";
import {LineChartKpi} from "./LineChartKpi/LineChartKpi";
import {BarChartKpi} from "./BarChartKpi/BarChartKpi";
import {KeywordsKpi} from "./KeywordsKpi/KeywordsKpi";
import {CompareModule} from "../../../modules/Compare.module";
import {ItemsStatsKpi} from "./ItemsStatsKpi/ItemsStatsKpi";
import {PieChartKpi} from "./PieChartKpi/PieChartKpi";

export const Kpi = memo((props: KpiPropsUnion): ReactElement | null => {
    let content: ReactElement | null = null;
    switch (props.type) {
        case "number":
            content = <NumberKpi {...props}/>;
            break;
        case "lineChart":
            content = <LineChartKpi {...props}/>;
            break;
        case "barChart":
            content = <BarChartKpi {...props}/>;
            break;
        case "keywords":
            content = <KeywordsKpi {...props}/>;
            break;
        case "itemsStats":
            content = <ItemsStatsKpi {...props}/>;
            break;
        case "pieChart":
            content = <PieChartKpi {...props}/>;
            break;
    }

    const styles: ComponentSlotStyle = {};
    if (props.width) styles.gridColumn = `span ${props.width}`;
    if (props.height) styles.gridRow = `span ${props.height}`;

    return (
        <Flex fill className={"kpi"} styles={styles}>
            {content}
        </Flex>
    )
}, CompareModule.areObjectsEqual);