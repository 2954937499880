import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as microsoftTeams from "@microsoft/teams-js";
import {SCOPE_GET_GRAPHCLIENT, SCOPE_INIT, ScopesReducerState} from "./ScopesReducer.interfaces";
import {InitArgs, TeamsFxConfig} from "services/ScopesService/ScopesService.interfaces";
import {TeamsUserCredential} from "@microsoft/teamsfx";
import {checkConfig} from "services/ScopesService/ScopesService";
import {translations} from "translations";
import {login} from "../AuthReducer/AuthReducer";
import {ErrorModule} from "components/ErrorBoundary/ErrorBoundary";
import {getSliceSelector} from "../../utils";
import {TokenApi} from "../../../apis/Token/TokenApi";
import {Client} from "@microsoft/microsoft-graph-client";
import {AuthProvider} from "@microsoft/microsoft-graph-client/src/IAuthProvider";

export let teamsFxGraphClient: Client | undefined = undefined;
export let teamsFxGraphBetaClient: Client | undefined = undefined;

export let scopesServiceButtonAction: (() => Promise<void>) | undefined = undefined;

const initialState: ScopesReducerState = {
    loaded: false,
    needToConsent: false,
    isConsenting: false,
    teamsFxConfig: undefined,
}

const slice = createSlice({
    name: "scopes",
    initialState,
    reducers: {
        setScope: (state, action: PayloadAction<Partial<ScopesReducerState>>) => {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeScope.fulfilled, (state, action) => {
            if (!action.payload) return ErrorModule.showErrorAlert("Can't initialize scopes reducer");
            Object.assign(state, action.payload);
        });
        builder.addCase(getGraphClient.fulfilled, (state, action) => {
            if (!action.payload) return;
            Object.assign(state, action.payload);
        });
    }
});

export const initializeScope = createAsyncThunk(SCOPE_INIT, async (data: { initArgs: InitArgs }, {dispatch}) => {
    const {initArgs} = data;
    checkConfig(initArgs);
    const scopes = initArgs.scopes?.map(s => "https://graph.microsoft.com/" + s) ?? [];
    const teamsFxConfig: TeamsFxConfig = {
        initiateLoginEndpoint: initArgs.apiBaseUrl + "/auth-start.html",
        apiEndpoint: initArgs.apiBaseUrl + "",
        clientId: initArgs.clientId + "",
        tenantId: initArgs.tenantId + "",
        scopes: scopes.join(" "),
        authorityHost: "https://login.microsoftonline.com/common/v2.0",
        applicationIdUri: initArgs.apiBaseUrl + "/auth-start.html",
        loginPageTitle: initArgs.loginPageTitle ?? translations.get("NeedToConsent"),
        loginPageSubtitle: initArgs.loginPageSubtitle ?? translations.get("ClickButtonToContinue"),
    };
    const teamsFxCredential = new TeamsUserCredential(teamsFxConfig);
    dispatch(getGraphClient({teamsFxCredential, scopes, initArgs}))
    return {teamsFxConfig};
});

export const initGraphClient = async () => {
    const graphToken = await TokenApi.getGraphToken();
    if (!graphToken) throw new Error("Invalid graph token");
    const authProvider: AuthProvider = (callback) => callback(undefined, graphToken);
    teamsFxGraphClient = Client.init({authProvider});
    teamsFxGraphBetaClient = Client.init({authProvider, defaultVersion: "beta"});
    return {loaded: true, needToConsent: false, isConsenting: false};
}

export const getGraphClient = createAsyncThunk(SCOPE_GET_GRAPHCLIENT, async (data: {
    initArgs: InitArgs,
    scopes: Array<string>,
    teamsFxCredential: TeamsUserCredential,
}, {dispatch}) => {
    const {teamsFxCredential, scopes, initArgs} = data;
    try {
        return await initGraphClient();
    } catch (error) {
        try {
            if (initArgs.isOnMobile && initArgs.inTeams) {
                await teamsFxCredential.login(scopes);
            } else {
                scopesServiceButtonAction = async (): Promise<void> => {
                    dispatch(setScope({isConsenting: true}));
                    try {
                        if (initArgs.inTeams) await teamsFxCredential.login(scopes);
                        else dispatch(login());
                    } catch (e: any) {
                        window.location.reload();
                    }
                }
                dispatch(setScope({needToConsent: true}));
                microsoftTeams.app.notifySuccess();
            }
            const initGraphClientInterval = setInterval(async () => {
                try {
                    const state = await initGraphClient();
                    clearInterval(initGraphClientInterval);
                    dispatch(setScope(state));
                } catch (_) {
                    return;
                }
            }, 5000);
        } catch (loginError: any) {
            window.location.reload();
        }
        return;
    }
});

export const {setScope} = slice.actions;

export const useScopesSelector = getSliceSelector(slice);

export default slice.reducer;