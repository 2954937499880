import {useQuery} from "react-query";
import {TrainingApi} from "../../apis/Training/TrainingApi";
import {ItemData, ItemDataType} from "../../interfaces/ItemData";
import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";
import {ObjectModule} from "../../modules/Object.module";
import {queryClient} from "../../index";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {bestPracticesCacheKey} from "./bestPracticesCache";
import {trainingsCacheKey} from "./trainingsCache";

export const commentsCacheKey = "comments";

const fetchItemComments = (type: ItemDataType | undefined, id: string | undefined) => async () => {
    if (type === undefined || id === undefined) return undefined;
    switch (type) {
        case ItemDataType.BestPractice:
            return await BestPracticeApi.getComments(id);
        case ItemDataType.Training:
            return await TrainingApi.getComments(id);
        default:
            return [];
    }
}

export const useItemCommentsCache = (type: ItemDataType | undefined, id: string | undefined) => {
    const {data: comments} = useQuery(commentsCacheKey + type + id, fetchItemComments(type, id), {
        staleTime: 300000,
        enabled: type !== undefined && id !== undefined,
    });

    return {comments};
};

const incrementCommentsCount = (type: ItemDataType | undefined, id: string | undefined) => {
    if (type === undefined || id === undefined) return;
    switch (type) {
        case ItemDataType.BestPractice:
            const bestPractices = ObjectModule.deepClone(queryClient.getQueryData<Immutable<Array<ItemData>>>(bestPracticesCacheKey));
            if (!bestPractices) return;
            const bestPractice = bestPractices.find(item => item.id === id);
            if (!bestPractice) return;
            bestPractice.comments++;
            queryClient.setQueryData(bestPracticesCacheKey, bestPractices);
            break;
        case ItemDataType.Training:
            const trainings = ObjectModule.deepClone(queryClient.getQueryData<Immutable<Array<ItemData>>>(trainingsCacheKey));
            if (!trainings) return;
            const training = trainings.find(item => item.id === id);
            if (!training) return;
            training.comments++;
            queryClient.setQueryData(trainingsCacheKey, trainings);
            break;
    }
}

export const CommentsCacheActions = {
    incrementCommentsCount
}