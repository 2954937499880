import {AxiosConfig} from "../AxiosConfig/AxiosConfig";

const getGraphToken = () => new Promise<string | null>(async resolve => {
    try {
        const response = await AxiosConfig.instance.get("/graphtoken");
        return resolve(response.data);
    } catch (_) {
        return resolve(null);
    }
});

const saveToken = () => new Promise<void>(async resolve => {
    try {
        // TODO - currently not implemented in backend
        // await AxiosConfig.instance.get("/StoreToken");
        return resolve();
    } catch (_) {
        console.error("Can't save token");
        return resolve();
    }
});

export const TokenApi = {
    saveToken,
    getGraphToken,
}