import React, {memo, ReactElement} from "react";
import {Props, SearchItemData} from "./SearchItem.interfaces";
import "./SearchItem.styles.scss";
import {Flex, Skeleton, Text} from "@fluentui/react-northstar";
import {ReportingModule} from "../../modules/Reporting.module";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const SearchItem = memo((props: Props): ReactElement | null => {
    const {userId} = useMsTeamsSelector("userId");
    const showSkeletons = !props.data;
    return (
        <Flex column className={"search-item"} onClick={openItem(props.data, userId)}
              styles={{gap: showSkeletons ? "4px" : "0"}}>
            {showSkeletons ?
                <Skeleton animation={"pulse"} className={"overflow-hidden"}>
                    <Skeleton.Shape height={"22px"} width={"30%"}/>
                </Skeleton>
                :
                <Text
                    truncated size={"large"} weight={"semibold"}
                    content={props.data!.title}
                    className={"search-item-title"}
                />
            }
            {showSkeletons ?
                <Skeleton animation={"pulse"} className={"overflow-hidden"}>
                    <Skeleton.Shape height={"18px"} width={"80%"}/>
                </Skeleton>
                :
                <Text
                    truncated content={props.data!.description}
                    className={"search-item-subtitle"}
                />
            }
        </Flex>
    )
}, (pp, np) => pp.data?.id === np.data?.id);

const openItem = (data: SearchItemData | undefined, userId: string) => () => {
    if (!data?.link) return;
    window.open(data.link, "_blank");
    ReportingModule.notifyItemOpened(userId, data.id, data.type);
}