import {State} from "./PopupMenuButton.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import * as React from "react";
import {MouseEventHandler} from "react";
import {PopupProps} from "@fluentui/react-northstar";

export const initialState: State = {
    open: false,
    trigger: null,
}

export const reducer = {
    open: ({setState}, [e]: [React.MouseEvent | undefined]) => {
        e?.stopPropagation();
        setState({open: true});
    },
    close: ({setState}, [e]: [React.MouseEvent | MouseEvent | TouchEvent | undefined]) => {
        e?.stopPropagation();
        setState({open: false});
    },
    toggleOpen: ({state, setState}, [e]: [React.MouseEvent | undefined]) => {
        e?.stopPropagation();
        setState({open: !state.open});
    },
    handlePopupHoverOpenChange: (reducerData, payload: [React.SyntheticEvent, PopupProps | undefined]) => {
        const {state} = reducerData;
        const [e, data] = payload;
        if (e.type !== "mouseleave") return;
        const triggerRect = state.trigger?.getBoundingClientRect();
        if (!triggerRect) return;
        const mouseEvent = e as unknown as Parameters<MouseEventHandler<HTMLDivElement>>[0];
        const x = mouseEvent.clientX;
        const y = mouseEvent.clientY;
        const isCursorInTrigger = x >= triggerRect.left && x <= triggerRect.right && y >= triggerRect.top && y <= triggerRect.bottom;
        if (data?.open || isCursorInTrigger) return;
        reducer.close(reducerData, [mouseEvent]);
    },
    handleLeaveTrigger: (reducerData, [e]: [Parameters<MouseEventHandler<HTMLDivElement>>[0]]) => {
        if (!e.relatedTarget || !("className" in e.relatedTarget) || !(e.relatedTarget as any).className) {
            reducer.close(reducerData, [e]);
            return;
        }
        const classList = (e.relatedTarget as any).className as string;
        if (classList.includes("ui-popup__content__content") || classList.includes("ui-menu")) return;
        reducer.close(reducerData, [e]);
    },
    triggerRef: ({setState}, [ref]: [HTMLDivElement | null]) => {
        setState({trigger: ref}, false);
    }
} satisfies MagicReducerObject<State>;

export const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
}