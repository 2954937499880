import {IFilterItem} from "../components/Filter/Filter.interfaces";
import moment from "moment";
import {ItemData} from "../interfaces/ItemData";
import {translations} from "../translations";
import {Immutable} from "@witivio_teamspro/use-reducer";

const generateFilterItems = (trainings: Immutable<Array<ItemData>> | undefined): Immutable<Array<IFilterItem>> => {
    if (!trainings) return [];

    const brands = Array.from(new Set(trainings.map(b => b.brand))).filter(i => i).sort((a, b) => a!.localeCompare(b!));
    const sources = Array.from(new Set(trainings.map(b => b.source))).filter(i => i).sort((a, b) => a!.localeCompare(b!));
    const jobFamilies = Array.from(new Set(trainings.map(b => b.jobFamilies).flat())).filter(i => i).sort((a, b) => a.localeCompare(b));
    const topics = Array.from(new Set(trainings.map(b => b.topics).flat())).filter(i => i).sort((a, b) => a.localeCompare(b));

    return [
        {
            key: "source",
            label: translations.get("Source"),
            menu: {
                items: sources.map(i => ({key: i ?? "", label: i ?? ""})),
            }
        },
        {
            key: "job-family",
            label: translations.get("JobFamily"),
            menu: {
                items: jobFamilies.map(i => ({key: i, label: i,})),
            }
        },
        {
            key: "brand",
            label: translations.get("Brand"),
            menu: {
                items: brands.map(i => ({key: i ?? "", label: i ?? ""})),
            }
        },
        {
            key: "topic",
            label: translations.get("Topic"),
            menu: {
                items: topics.map(i => ({key: i, label: i})),
            }
        },
        {
            key: "from-date",
            label: translations.get("FromDate"),
            input: {
                unit: {
                    label: "",
                    width: 0,
                },
                type: "date",
                value: moment().startOf("day").add(-1, "year").format("YYYY-MM-DD"),
            }
        },
        {
            key: "to-date",
            label: translations.get("ToDate"),
            input: {
                unit: {
                    label: "",
                    width: 0,
                },
                type: "date",
                value: moment().startOf("day").format("YYYY-MM-DD"),
            }
        }
    ]
}

const getTopicPictureUrl = (trainings: Array<Immutable<ItemData>> | undefined, topic: string | undefined) => {
    if (!trainings || !topic) return "";
    const topics = Array.from(new Set(trainings?.map(t => t.topics).flat()));
    const topicsColors = ["blue", "green", "orange", "red", "yellow"];
    return window.location.origin + "/assets/trainings/training_" + topicsColors[topics.indexOf(topic)] + ".jpg"
}

export const TrainingsModule = {
    generateFilterItems,
    getTopicPictureUrl,
}