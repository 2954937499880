import {Immutable} from "@witivio_teamspro/use-reducer";
import {SharepointComment} from "../../interfaces/SharepointComment";
import "./CommentCard.styles.scss";
import React, {memo} from "react";
import {Avatar, Flex, Text} from "@fluentui/react-northstar";
import moment from "moment";
import {CompareModule} from "../../modules/Compare.module";
import {useUserThumbnailCache} from "../../services/CacheService/userThumbnailCache";

export const CommentCard = memo((props: {
    comment: Immutable<SharepointComment>
}) => {
    const {imageUrl} = useUserThumbnailCache(props.comment.author?.email);

    if (!props.comment.author || !props.comment.createdDate || !props.comment.text) return null;

    const commentWithoutMentions = clearMentionsFromComment(props.comment.text);

    return (
        <Flex className="comment-card" column styles={{gap: "5px"}}>
            <Flex className={"overflow-hidden"} gap={"gap.small"} vAlign={"center"}>
                <Flex fill gap={"gap.smaller"} vAlign={"center"} className={"overflow-hidden"}>
                    <Avatar className={"no-shrink"} image={imageUrl} size={"smallest"}
                            name={props.comment.author?.name ?? ""}/>
                    <Text
                        styles={{marginTop: "-2px"}}
                        truncated size={"small"}
                        content={props.comment.author?.name}
                    />
                </Flex>
                <Text
                    className={"no-shrink"}
                    size={"small"} styles={{color: "darkgray", marginTop: "-2px"}}
                    content={moment(props.comment.createdDate).calendar()}
                />
            </Flex>
            <Flex className={"overflow-hidden"}>
                <Text styles={{wordBreak: "break-word"}} content={commentWithoutMentions}/>
            </Flex>
        </Flex>
    )
}, CompareModule.areObjectsEqual);

const mentionRegex = /<a data-sp-mention-user-id=".*">(.*)<\/a>/g;

const clearMentionsFromComment = (comment: string): string => {
    return comment.replace(mentionRegex, "$1");
}