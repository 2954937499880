export type State = {
    slidesPerView: number,
}

export enum ConsultedOrLikedContentCategory {
    All = "all",
    LikedBestPractices = "liked-best-practices",
    LikedTrainings = "liked-trainings",
    ViewedBestPractices = "viewed-best-practices",
    ViewedTrainings = "viewed-trainings",
}

export enum RecommendedContentCategory {
    All = "all",
    BestPractices = "best-practices",
    Trainings = "trainings",
}