import moment from "moment";
import {WeekDay} from "interfaces/WeekDay";

const getWeekDaysOrderPerCulture = () => {
    const currentLocaleData = moment.localeData();
    return moment.weekdays(true).map(d => currentLocaleData.weekdaysParse(d, "dddd", false) as WeekDay);
}

const defaultBusinessDays = new Array<WeekDay>(
    WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday
);

const getDateOfDayXWeeksAfter = (weeksToAdd: number, date?: string) => {
    const momDate = !date ? moment() : moment(date);
    momDate.add(weeksToAdd, "weeks").startOf("day");
    return momDate.toISOString(false);
}

const getFirstDayOfWeek = (): WeekDay => {
    return getWeekDaysOrderPerCulture()[0]!;
}

export const WeekDaysModule = {
    getWeekDaysOrderPerCulture,
    defaultBusinessDays,
    getDateOfDayXWeeksAfter,
    getFirstDayOfWeek,
}