import {Configuration} from "../../interfaces/Configuration";
import {ConfigurationApi} from "../../apis/Configuration/ConfigurationApi";
import {AxiosConfig} from "../../apis/AxiosConfig/AxiosConfig";
import {LocalCacheService, SessionCacheService} from "../CacheService/CacheService.hook";

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

export const getConfig = async () => {
    const configuration: Configuration | null = await ConfigurationApi.getConfiguration();
    SessionCacheService.setItem("app_configuration", configuration);
    const apiBaseUrl = `https://${window.location.host}/api/${configuration.apiVersion}`;
    AxiosConfig.setAxiosInstanceApiBaseUrl(apiBaseUrl);
    checkVersion(configuration.applicationVersion);
    return configuration;
};

const checkVersion = async (lastVersion: string): Promise<boolean> => {
    const currentVersion = LocalCacheService.getItem("app_version");
    const isLastVersion = lastVersion === currentVersion;
    if (isLastVersion) return true;
    LocalCacheService.setItem("app_version", lastVersion);
    await handleHardReload(window.location.href);
    return false;
}

export const handleHardReload = async (url: string) => {
    if ("caches" in window) {
        const cachedKeys = await window.caches.keys();
        await Promise.all(cachedKeys.map((key: string) => window.caches.delete(key)));
    }
    await fetch(url, {headers: {Pragma: 'no-cache', Expires: '-1', 'Cache-Control': 'no-cache'}});
    window.location.href = url;
    window.location.reload();
}

export const getAppConfiguration = () => {
    return SessionCacheService.getItem<Configuration>("app_configuration");
}

export const getAppVersion = (): string => {
    const version = LocalCacheService.getItem<string>("app_version") ?? "";
    if (!version) return "";
    return "v" + version;
}