import {State} from "./NotificationSentDialog.interfaces";
import {MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {DialogRef} from "../../../components/Dialog/Dialog.interfaces";

export const initialState: State = {}

export const reducer = (dialogRef: DialogRef) => ({
    open: () => {
        dialogRef.dispatch("open")();
    }
}) satisfies MagicReducerObject<State>;