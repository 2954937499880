import moment from "moment";
import {SessionCacheService} from "../CacheService/CacheService.hook";

const cacheKey = "history-service_";

const retrievedStates = new Array<string>();

const getComponentState = <T extends object>(componentId: string): T | null => {
    if (retrievedStates.includes(componentId)) return null;
    retrievedStates.push(componentId);
    return SessionCacheService.getItem<T>(cacheKey + componentId);
}

const saveComponentState = <T extends object>(componentId: string, state: T, durationInSeconds: number) => {
    const expirationDate = moment().add(durationInSeconds, "seconds").toDate();
    SessionCacheService.setItem<T>(cacheKey + componentId, state, expirationDate);
}

const clearAllStates = (beginKey: string = "") => {
    SessionCacheService.clearFromBeginKey(cacheKey + beginKey);
}

export const HistoryService = {
    getComponentState,
    saveComponentState,
    clearAllStates,
}