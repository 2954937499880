import {useQuery, useQueryClient} from "react-query";
import {Notification} from "interfaces/Notification";
import {NotificationsApi} from "../../apis/Notifications/NotificationsApi";
import {Immutable} from "@witivio_teamspro/use-reducer";

export const useNotificationTemplatesCache = () => {
    const queryClient = useQueryClient();
    const {data: templates} = useQuery("notification-templates", NotificationsApi.getTemplates, {staleTime: Infinity});

    const addTemplate = async (newTemplate: Notification) => {
        const templates = await NotificationsApi.addTemplate(newTemplate);
        updateTemplates(templates);
    }

    const deleteTemplate = async (templateId: string) => {
        const templates = await NotificationsApi.deleteTemplate(templateId);
        updateTemplates(templates);
    }

    const updateTemplates = (newTemplates: Immutable<Array<Notification>>) => {
        queryClient.setQueryData("notification-templates", newTemplates);
    };

    return {
        templates,
        addTemplate,
        deleteTemplate,
    }
}