import {ErrorModule} from "../../components/ErrorBoundary/ErrorBoundary";
import axios, {AxiosError, CancelTokenSource} from "axios";
import {AxiosConfig} from "../AxiosConfig/AxiosConfig";
import {BestPractice} from "../../interfaces/BestPractice";
import {ItemData, ItemDataType} from "../../interfaces/ItemData";
import {ItemDataModule} from "../../modules/ItemData.module";
import {Immutable} from "@witivio_teamspro/use-reducer";
import {SharepointComment} from "../../interfaces/SharepointComment";

let getAllBestPracticesCancelToken: CancelTokenSource | undefined = undefined;

const getAll = () => new Promise<Array<Immutable<ItemData>> | undefined>(async (resolve) => {
    if (getAllBestPracticesCancelToken) getAllBestPracticesCancelToken.cancel();
    getAllBestPracticesCancelToken = axios.CancelToken.source();
    try {
        const response = await AxiosConfig.instance.get("/bestPractices", {cancelToken: getAllBestPracticesCancelToken.token});
        const bestPractices: Array<BestPractice> = response.data;
        let items: Array<Immutable<ItemData>> = bestPractices.map(b => {
            return {
                type: ItemDataType.BestPractice,
                id: b.id,
                title: b.title,
                description: b.description,
                pictureUrl: b.thumbnailUrl.url,
                likes: b.reactions.likes,
                link: b.link.url,
                rating: Math.round(b.yourRating),
                jobFamilies: b.jobFamily,
                topics: b.topics,
                types: b.type,
                affiliate: b.affiliate,
                creationDate: b.created,
                lastUpdate: b.modified,
                brand: undefined,
                source: undefined,
                comments: b.reactions.commentCount,
                clicks: 0,
                returnRate: 0,
                canInteract: !!b.sharepointPageId
            }
        });
        items = ItemDataModule.sortItemsByTitle(items);
        return resolve(items);
    } catch (error) {
        if (axios.isCancel(error)) return resolve(undefined);
        ErrorModule.showErrorAlert("Can't get best practices", error as AxiosError);
        resolve(undefined);
    }
});

const postComment = (bestPracticeId: string, comment: string) => new Promise<boolean>(async (resolve) => {
    try {
        await AxiosConfig.instance.post(`/bestPractices/${bestPracticeId}/comment`, {text: comment});
        return resolve(true);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't post comment", error as AxiosError);
        resolve(true);
    }
});

const like = (bestPracticeId: string) => new Promise<boolean>(async (resolve) => {
    try {
        await AxiosConfig.instance.get(`/bestPractices/${bestPracticeId}/like`);
        return resolve(true);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't like best practice", error as AxiosError);
        resolve(true);
    }
});

const unlike = (bestPracticeId: string) => new Promise<boolean>(async (resolve) => {
    try {
        await AxiosConfig.instance.get(`/bestPractices/${bestPracticeId}/unlike`);
        return resolve(true);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't dislike best practice", error as AxiosError);
        resolve(true);
    }
});

const getThumbnail = (bestPracticeId: string) => new Promise<string | undefined>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get(`/bestPractices/${bestPracticeId}/thumbnail`);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get best practice thumbnail", error as AxiosError);
        resolve(undefined);
    }
});

const getComments = (bestPracticeId: string) => new Promise<Array<SharepointComment>>(async (resolve) => {
    try {
        const response = await AxiosConfig.instance.get(`/bestPractices/${bestPracticeId}/comments`);
        return resolve(response.data);
    } catch (error) {
        ErrorModule.showErrorAlert("Can't get comments of best practice", error as AxiosError);
        resolve([]);
    }
});

export const BestPracticeApi = {
    getAll,
    postComment,
    like,
    unlike,
    getThumbnail,
    getComments,
}