export type ManagementDataRecommendedItems = {
    bestPractices: Array<string>,
    trainings: Array<string>,
}

export enum ManagementUserRole {
    Manager = "Manager",
    Both = "Both",
    Report = "Report"
}

export type ManagementUserData = {
    role: ManagementUserRole,
    recommendedItems: ManagementDataRecommendedItems,
}