import * as microsoftTeams from "@microsoft/teams-js";
import {UserTeamRole} from "@microsoft/teams-js";
import {MicrosoftTeamsReducerState, MSTEAMS_INIT, ThemeClass} from "./MicrosoftTeamsReducer.interfaces";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {teamsTheme} from "@fluentui/react-northstar";
import {TokenResult} from "interfaces/TokenResult";
import {IAppDispatch} from "redux/store";
import {
    getCurrentTheme,
    initializeFromTeams,
    initializeOutsideOfTeams
} from "services/MicrosoftTeamsService/MicrosoftTeamsService";
import {getSliceSelector} from "../../utils";

const initialState: MicrosoftTeamsReducerState = {
    loaded: false,
    locale: "en",
    fullLocale: "en-US",
    themeClass: ThemeClass.Default,
    groupId: "",
    channelId: "",
    teamId: "",
    teamName: "",
    tenantId: "",
    channelName: "",
    userMail: "",
    userName: "",
    userId: "",
    hostClientType: undefined,
    isConfiguringApp: false,
    isDeletingApp: false,
    isOnMobile: false,
    isInPersonalApp: false,
    subEntityId: "",
    isInTaskModule: false,
    entityId: "",
    isInMeeting: false,
    meetingId: "",
    isInMeetingSidePanel: false,
    userRole: UserTeamRole.User,
    isTouchScreen: false,
    isTeamsIframe: true,
    isLightTheme: true,
    theme: teamsTheme,
}

const slice = createSlice({
    name: "msTeams",
    initialState,
    reducers: {
        setMsTeams: (state, action: PayloadAction<Partial<MicrosoftTeamsReducerState>>) => {
            Object.assign(state, action.payload);
        },
        setTheme(state, action: PayloadAction<string>) {
            let themeClass = ThemeClass.Default;
            switch (action.payload) {
                case "dark":
                    themeClass = ThemeClass.Dark;
                    break;
                case "contrast":
                    themeClass = ThemeClass.HighContrast;
                    break;
                default:
                    break;
            }
            window.document.body.id = themeClass;
            state.theme = getCurrentTheme(themeClass);
            state.themeClass = themeClass;
            state.isLightTheme = state.themeClass === ThemeClass.Default;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeMSTeams.fulfilled, (state, action) => {
            if (!action.payload) return;
            Object.assign(state, action.payload);
        });
    }
});

export const initializeMSTeams = createAsyncThunk(MSTEAMS_INIT, async (data: {
    isTeamsApp: boolean,
    accessToken?: TokenResult | undefined
}, {dispatch}) => {
    let state;
    let theme = "light";
    if (data.isTeamsApp) {
        state = await initializeFromTeams(data.accessToken);
        microsoftTeams.app.registerOnThemeChangeHandler((theme) => dispatch(setTheme(theme)));
        const context = await microsoftTeams.app.getContext();
        dispatch(setTheme(context.app.theme));
    } else {
        state = await initializeOutsideOfTeams(data.accessToken)
        dispatch(setTheme(theme));
    }
    if (!data.accessToken) return;
    if (data.isTeamsApp) microsoftTeams.app.notifySuccess();
    return state;
});

export const handleMouseDown = (e: MouseEvent) => (dispatch: IAppDispatch) => {
    const isTouchScreen = (e as MouseEvent & { sourceCapabilities?: { firesTouchEvents: boolean } })
        .sourceCapabilities?.firesTouchEvents;
    if (isTouchScreen === undefined) return;
    dispatch(setMsTeams({isTouchScreen}));
}

export const handleTouchStart = () => (dispatch: IAppDispatch) => {
    dispatch(setMsTeams({isTouchScreen: true}));
}

export const clearListeners = () => (dispatch: IAppDispatch) => {
    window.document.body.removeEventListener("mousedown", (e: MouseEvent) => dispatch(handleMouseDown(e)));
    window.document.body.removeEventListener("touchstart", () => dispatch(handleTouchStart()));
}

export const setListeners = () => (dispatch: IAppDispatch) => {
    window.document.body.addEventListener("mousedown", (e: MouseEvent) => dispatch(handleMouseDown(e)));
    window.document.body.addEventListener("touchstart", () => dispatch(handleTouchStart()));
}

export const {setMsTeams, setTheme} = slice.actions;

export const useMsTeamsSelector = getSliceSelector(slice);

export default slice.reducer;