import {ITableColumn, ITableItem, ITableRowAction, Logic} from "../Table.interfaces";
import React, {CSSProperties, ReactElement, useMemo} from "react";
import {Button, Flex, MoreIcon, Text} from "@fluentui/react-northstar";
import {PopupMenuButton} from "components/PopupMenuButton/PopupMenuButton";
import {stopPropagation} from "../Table.reducer";

export const useTableItems = <T, >(logic: Logic<T>): Array<{
    key: string,
    items: Array<Record<string, unknown | ReactElement | null>>
}> => {
    return useMemo(() => (
        logic.items.map(generateItem(logic))
    ), [logic.columns, logic.items, logic.visibleRange, logic.editedItem]);
}

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const generateItem = <T, >(logic: Logic<T>) => (item: ITableItem<any>, index: number) => {
    const itemId = item?.id ?? ("null-item-" + index);
    if (index < (logic.visibleRange[0] ?? 0) || index > (logic.visibleRange[1] ?? 0))
        return {key: "item-" + itemId, items: []};
    if (!item?.id) return renderSkeleton(logic);
    const items = logic.columns.map(generateItemField(logic, item, itemId));
    if (!!logic.actions && logic.actions.items.length > 0) {
        const itemActions = generateItemActions(logic, item, itemId);
        if (itemActions) items.push(itemActions);
    }
    return {key: "item-" + itemId, items};
}

const generateItemField = <T, >(logic: Logic<T>, item: ITableItem<any>, itemId: string) => (c: ITableColumn<ITableItem<T>>): Record<string, unknown | ReactElement | null> => {
    const isEditMode = logic.editedItem?.itemId === itemId && logic.editedItem?.field === c.field;
    return {
        key: "item-" + c.field + "-" + itemId,
        styles: {
            minWidth: c.minWidth,
            maxWidth: `calc(${c.maxWidth} - ${logic.actionColumnWidth / logic.columns.length}px)`,
            backgroundColor: typeof c.backgroundColor === "function" ?
                c.backgroundColor(item) : c.backgroundColor
        },
        content: renderItemField(logic, item, c.field, isEditMode),
        truncateContent: true,
    }
}

const generateItemActions = <T, >(logic: Logic<T>, item: ITableItem<any>, itemId: string) => {
    if (!logic.actions) return;
    return {
        key: "item-actions-" + itemId,
        styles: {
            maxWidth: logic.actionColumnWidth + "px",
            backgroundColor: typeof logic.actions.backgroundColor === "function" ?
                logic.actions.backgroundColor(item) : logic.actions.backgroundColor
        },
        content: renderItemActions(logic, item),
        truncateContent: true,
    }
}

const renderItemActions = <T, >(logic: Logic<T>, item: ITableItem<any>) => {
    const actions = (logic.actions?.items ?? [])
        .map(a => (!a.isVisible || a.isVisible(item)) ? a : null)
        .filter(a => !!a) as Array<ITableRowAction<T>>;
    if (actions.length === 0) return null;
    const formatAction = (a: ITableRowAction<T>): any => ({
        key: "action" + a.label,
        content: typeof a.label === "function" ? a.label(item) : a.label,
        icon: typeof a.icon === "function" ? a.icon(item) : a.icon,
        onClick: () => a.onClick?.(item),
        menu: a.menu?.map(i => formatAction(i)),
    });
    const formattedActions = actions.map(a => formatAction(a));
    let icon: ReactElement | undefined = <MoreIcon size={"large"}/>;
    if (!!logic.actions?.icon)
        icon = typeof logic.actions?.icon === "function" ? logic.actions?.icon(item) : logic.actions?.icon;
    return (
        <Flex fill vAlign={"center"} hAlign={"end"}>
            <PopupMenuButton
                offset={[0, -3]}
                position={"below"}
                align={"end"}
                trigger={<Button text iconOnly icon={icon}/>}
                menu={formattedActions}
                closeOnClick
                mouseLeaveDelay={500}
            />
        </Flex>
    )
}

const renderSkeleton = <T, >(logic: Logic<T>) => {
    const items: Array<{
        key: string,
        content: ReactElement,
        styles: CSSProperties
    }> = logic.columns.map((c, j) => ({
        key: "skeleton-" + j + "-" + c.field,
        content: renderItemFieldSkeleton(logic, j, c.field),
        styles: {
            minWidth: c.minWidth,
            maxWidth: `calc(${c.maxWidth} - ${logic.actionColumnWidth / logic.columns.length}px)`,
        },
    }))
    if (logic.showActionColumn) items.push({
        content: <></>,
        styles: {maxWidth: logic.actionColumnWidth + "px"},
        key: "skeleton-action",
    });
    return {
        key: "skeleton-" + Math.random(),
        items
    };
}

const renderItemFieldSkeleton = <T, >(logic: Logic<T>, itemIndex: number, field: string) => (
    <Flex fill key={"skeleton" + field + itemIndex} vAlign={"center"}>
        {logic.getColumnByField(field).skeleton}
    </Flex>
)

const renderItemField = <T, >(logic: Logic<T>, item: ITableItem<any>, field: string, isEditMode: boolean) => {
    const columnData = logic.getColumnByField(field);
    let renderedItem = isEditMode && !!columnData.renderEditField ?
        columnData.renderEditField(item, logic.dispatch("clearEditedItem")) : columnData.render(item);
    const isStringOrNumber = ["string", "number"].includes(typeof renderedItem);
    if (isStringOrNumber) renderedItem = <Text content={renderedItem} title={renderedItem + ""} truncated/>
    let onClickItem = undefined;
    if (isEditMode) onClickItem = stopPropagation;
    else if (!!columnData.renderEditField) onClickItem = logic.dispatch("handleEditItem", {itemId: item.id, field});
    else if (columnData.onClick) onClickItem = () => columnData.onClick!(item);
    return (
        <Flex
            fill onClick={onClickItem}
            className={!!onClickItem ? "cursor-pointer" : ""}
            vAlign={"center"}
            styles={{maxWidth: "100%"}}>
            {renderedItem}
        </Flex>
    )
}