import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AUTH_INIT, AUTH_LOGIN, AUTH_SIGN_OUT, AuthReducerState} from "./AuthReducer.interfaces";
import {AxiosConfig} from "apis/AxiosConfig/AxiosConfig";
import {ReduxStoreState} from "redux/store";
import {ErrorModule} from "components/ErrorBoundary/ErrorBoundary";
import {AccountInfo} from "@azure/msal-browser";
import {getAccessToken, initMsalInstance} from "services/AuthService/AuthService";
import {setScope} from "../ScopesReducer/ScopesReducer";
import {getSliceSelector} from "../../utils";

const initialState: AuthReducerState = {
    inIframe: window.self !== window.top,
    accessToken: undefined,
    isTeamsApp: true,
    msalInstance: undefined,
    accessTokenInterval: undefined,
    clientId: undefined,
}

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<Partial<AuthReducerState>>) => {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeAuth.fulfilled, (state, action) => {
            if (!action.payload) throw new Error("Can't get current user profile");
            Object.assign(state, action.payload);
        });
    }
});

export const initializeAuth = createAsyncThunk(AUTH_INIT, async (data: { isTeamsApp: boolean }, {
    getState,
    dispatch
}) => {
    const state = getState() as ReduxStoreState;
    const config = state.configuration;
    const auth = state.auth;
    const msalInstance = initMsalInstance(config.clientId, auth.inIframe);
    const refreshAccessToken = async (needDispatch = true) => {
        const accessToken = await getAccessToken(config.clientId, data.isTeamsApp, msalInstance, auth.inIframe);
        AxiosConfig.setAxiosRequestMiddleware(accessToken?.token ?? "");
        if (needDispatch) dispatch(setAuth({accessToken}));
        return accessToken;
    }
    const accessToken = await refreshAccessToken(false);
    // Refresh access token every 30 minutes
    const accessTokenInterval = setInterval(refreshAccessToken, 1800000);
    return {msalInstance, isTeamApp: data.isTeamsApp, clientId: config.clientId, accessToken, accessTokenInterval}
});

export const login = createAsyncThunk(AUTH_LOGIN, async (_, {getState, dispatch}) => {
    try {
        const state = getState() as ReduxStoreState;
        const auth = state.auth;
        const scopes = state.configuration.graphScopes.split(" ");
        if (!auth.clientId) return ErrorModule.showErrorAlert("Can't consent scopes, client id is not defined");
        if (auth.msalInstance === undefined) initMsalInstance(auth.clientId, auth.inIframe);
        await auth.msalInstance?.loginRedirect({scopes, prompt: "consent"});
    } catch (e: any) {
        // Refresh window in case of invalid response
        // This issue happens only on Android devices, can't parse result
        // After reload it should work fine thanks to SSO
        if (e.code === "InvalidResponse") return window.location.reload();
        else if (e.code === "ConsentFailed")
            ErrorModule.showErrorAlert("User cancelled consent", e);
        else ErrorModule.showErrorAlert("Consent failed", e);
        return;
    }
    dispatch(setScope({needToConsent: true}));
});

export const signOut = createAsyncThunk(AUTH_SIGN_OUT, async (_, {getState}) => {
    const state = getState() as ReduxStoreState;
    const auth = state.auth;
    if (!auth.msalInstance) return ErrorModule.showErrorAlert("MSAL instance is not initialized");
    const logoutRequest = {
        account: auth.msalInstance.getAllAccounts()[0] as AccountInfo,
    };
    if (!logoutRequest.account) return;
    await auth.msalInstance.logoutRedirect(logoutRequest);
});


export const {setAuth} = slice.actions;

export const useAuthSelector = getSliceSelector(slice);

export default slice.reducer;