import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {IAppDispatch} from "../redux/store";
import {initializeAuth, setAuth, useAuthSelector} from "../redux/reducers/AuthReducer/AuthReducer";
import {
    clearListeners,
    initializeMSTeams,
    setListeners,
    useMsTeamsSelector
} from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import {initializeScope, useScopesSelector} from "../redux/reducers/ScopesReducer/ScopesReducer";
import {useMsTeamsCache} from "services/CacheService/msTeamsCache";
import {useConfigurationCache} from "services/CacheService/configurationCache";
import {setConfiguration} from "../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import {PrepareApi} from "../apis/Prepare/PrepareApi";
import {useUserRoleCache} from "../services/CacheService/userRoleCache";
import {useUserDataCache} from "../services/CacheService/userDataCache";
import * as microsoftTeams from "@microsoft/teams-js";
import {HostClientType} from "@microsoft/teams-js";
import {ReportingModule} from "../modules/Reporting.module";
import useUserActivityTimer from "./useUserActivityTimer";
import {GuidModule} from "../modules/Guid.module";
import {useDeeplinkContext} from "./useDeeplinkContext";
import {DeeplinkContextType} from "../interfaces/DeeplinkContext";
import {HistoryService} from "../services/HistoryService/HistoryService.hook";
import {useBestPracticesCache} from "../services/CacheService/bestPracticesCache";
import {useTrainingsCache} from "../services/CacheService/trainingsCache";
import {useMyRecommendationsCache} from "../services/CacheService/recommendationsCache";

export const useAppInitializer = () => {
    const [loaded, setLoaded] = useState(false);
    const [preparing, setPreparing] = useState(false);
    const sessionIdRef = React.useRef<string>(GuidModule.generateGUID());

    const dispatch = useDispatch<IAppDispatch>();

    const deeplinkContext = useDeeplinkContext();

    const auth = useAuthSelector("accessToken", "isTeamsApp");
    const teams = useMsTeamsSelector("loaded", "locale", "isOnMobile", "themeClass", "tenantId", "userId", "isTeamsIframe", "hostClientType", "isConfiguringApp", "isInMeeting");
    const scopes = useScopesSelector("loaded");

    const {isTeamsApp} = useMsTeamsCache();
    const {config} = useConfigurationCache();
    const {userRole} = useUserRoleCache(scopes.loaded);
    const {userData} = useUserDataCache(scopes.loaded);
    const {getActiveSeconds} = useUserActivityTimer();
    useBestPracticesCache(scopes.loaded);
    useTrainingsCache(scopes.loaded);
    const {recommendations} = useMyRecommendationsCache(scopes.loaded);

    useEffect(function initializeMsTeamsListeners() {
        dispatch(setListeners());
        return () => dispatch(clearListeners());
    }, []);

    useEffect(function onTeamsApp() {
        if (isTeamsApp !== undefined) dispatch(setAuth({isTeamsApp}));
    }, [isTeamsApp]);

    useEffect(function onCachedConfiguration() {
        if (config) dispatch(setConfiguration(config));
    }, [config]);

    useEffect(function onConfigurationLoaded() {
        if (!config?.clientId || isTeamsApp == undefined) return;
        dispatch(initializeAuth({isTeamsApp: isTeamsApp}));
    }, [config?.clientId, isTeamsApp]);

    useEffect(function onAccessTokenRetrieved() {
        if (!auth.accessToken || isTeamsApp == undefined) return;
        dispatch(initializeMSTeams({isTeamsApp: isTeamsApp, accessToken: auth.accessToken}));
    }, [auth.accessToken, isTeamsApp]);

    useEffect(function onMsTeamsInitialized() {
        if (!teams.loaded) return;
        if (teams.isConfiguringApp) return setLoaded(true);
        const scopesServiceConfiguration = {
            clientId: config?.clientId,
            apiBaseUrl: window.location.origin,
            scopes: config ? config?.graphScopes.split(" ") : [],
            inTeams: auth.isTeamsApp,
            locale: teams.locale,
            isWidget: false,
            isOnMobile: teams.isOnMobile,
            theme: teams.themeClass,
            tenantId: teams.tenantId
        }
        dispatch(initializeScope({initArgs: scopesServiceConfiguration}))
    }, [teams.loaded]);

    useEffect(function onScopesServiceInitialized() {
        if (!scopes.loaded || !config) return;
        if (config?.needToPrepare) {
            setPreparing(true);
            PrepareApi.prepareData().then(() => {
                setPreparing(false);
                setLoaded(true);
            });
        } else {
            setLoaded(true);
            PrepareApi.prepareData().then();
        }
    }, [scopes.loaded]);

    useEffect(function onExitApp() {
        if (!teams.loaded || teams.isConfiguringApp || teams.isInMeeting || !userData) return;
        let interval: NodeJS.Timeout | undefined = undefined;
        const handler = handleExitApp(teams.userId, getActiveSeconds, teams.hostClientType, sessionIdRef.current);
        if (!teams.isTeamsIframe) window.addEventListener("beforeunload", handler);
        else if (teams.isOnMobile) interval = setInterval(handleExitApp(teams.userId, () => 10, teams.hostClientType, sessionIdRef.current), 10000);
        else {
            const isTeamsCoreSupported = microsoftTeams.teamsCore.isSupported();
            if (isTeamsCoreSupported) microsoftTeams.teamsCore.registerBeforeUnloadHandler(handler);
            else {
                let prevActiveSeconds = 0;
                interval = setInterval(() => {
                    const totalSeconds = getActiveSeconds();
                    const activeSeconds = totalSeconds - prevActiveSeconds;
                    if (activeSeconds >= 10) {
                        handleExitApp(teams.userId, () => activeSeconds, teams.hostClientType, sessionIdRef.current)();
                        prevActiveSeconds = totalSeconds;
                    }
                }, 1000);
            }
        }
        return () => {
            window.removeEventListener("beforeunload", handler);
            if (interval) clearInterval(interval);
        }
    }, [teams.loaded, userData]);

    useEffect(function onDeeplinkContext() {
        if (!deeplinkContext) return;
        if (deeplinkContext.type === DeeplinkContextType.Notification) {
            HistoryService.clearAllStates();
        }
    }, [deeplinkContext]);

    const fullyLoaded = loaded && userRole !== undefined && userData && recommendations;

    return {
        loaded: fullyLoaded,
        preparing
    }
};

const handleExitApp = (
    userId: string,
    getDuration: () => number,
    hostClientType: HostClientType | undefined,
    sessionId: string
) => (): boolean => {
    const duration = getDuration();
    ReportingModule.notifySession(userId, duration, hostClientType, sessionId);
    return true;
}