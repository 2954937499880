import React, {memo} from "react";
import {Avatar, Skeleton} from "@fluentui/react-northstar";
import {Props} from "./UserThumbnail.interfaces";
import "./UserThumbnail.styles.scss";
import {CompareModule} from "../../modules/Compare.module";
import {SizeValue} from "@fluentui/react-northstar/dist/es/utils/commonPropTypes";
import {useUserThumbnailCache} from "../../services/CacheService/userThumbnailCache";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const UserThumbnail = memo((props: Props) => {
    const {userId, userName} = useMsTeamsSelector("userId", "userName");
    const {imageUrl, isLoading} = useUserThumbnailCache(userId);

    const sizeInPixels = getSizeInPixels(props.size);

    if (isLoading) return (
        <Skeleton animation={"pulse"} className={"no-shrink"} styles={{width: sizeInPixels, height: sizeInPixels}}>
            <Skeleton.Avatar size={props.size}/>
        </Skeleton>
    )

    return (
        <Avatar
            size={props.size}
            className={"no-shrink"}
            name={userName}
            image={imageUrl}
            square
        />
    )
}, CompareModule.areObjectsEqual);

const getSizeInPixels = (size: SizeValue) => {
    let pixels = 0;
    switch (size) {
        case "smallest":
            pixels = 20;
            break;
        case "smaller":
            pixels = 24;
            break;
        case "small":
            pixels = 28;
            break;
        case "medium":
            pixels = 32;
            break;
        case "large":
            pixels = 44;
            break;
        case "larger":
            pixels = 64;
            break;
        case "largest":
            pixels = 96;
            break;
    }
    return pixels + "px";
}