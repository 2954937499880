import React, {memo, ReactElement} from "react";
import {Props} from "./NotificationSentDialog.interfaces";
import {useMagicReducer, useMagicReducerRef} from "@witivio_teamspro/use-reducer";
import {initialState, reducer} from "./NotificationSentDialog.reducer";
import {CompareModule} from "modules/Compare.module";
import "./NotificationSentDialog.styles.scss";
import {DialogRef} from "../../../components/Dialog/Dialog.interfaces";
import {Dialog} from "../../../components/Dialog/Dialog";
import {AcceptIcon, Flex, Text} from "@fluentui/react-northstar";
import {translations} from "../../../translations";

export const NotificationSentDialog = memo((props: Props): ReactElement | null => {
    const dialogRef = useMagicReducerRef<DialogRef>();
    const [state, dispatch] = useMagicReducer(reducer(dialogRef), initialState, props.externalRef);

    return (
        <Dialog
            closeOnClick
            width={250}
            closeOnOutsideClick
            externalRef={dialogRef}
            renderContent={() => (
                <Flex column hAlign={"center"} vAlign={"center"} className={"notification-sent-dialog-container"}>
                    <AcceptIcon size={"largest"} className={"notification-sent-dialog-icon"}/>
                    <Text size={"large"} content={translations.get("NotificationSent")}/>
                </Flex>
            )}
        />
    );
}, CompareModule.areObjectsEqual);