import {useQuery} from "react-query";
import {BestPracticeApi} from "../../apis/BestPractice/BestPracticeApi";
import {ItemData, ItemDataType} from "../../interfaces/ItemData";
import {Immutable} from "@witivio_teamspro/use-reducer";

export const useThumbnailsCache = (item: Immutable<ItemData> | undefined) => {
    const {data: imageUrl} = useQuery("thumbnails" + item?.id + item?.type, async () => {
        if (!item?.id) return undefined;
        if (item?.type === ItemDataType.Training) return item?.pictureUrl;
        return await BestPracticeApi.getThumbnail(item?.id);
    }, {staleTime: Infinity});

    return {imageUrl};
};