import {Props, State} from "./EditMessageDialog.interfaces";
import {Immutable, ImmutableObject, MagicReducerObject} from "@witivio_teamspro/use-reducer";
import {useForm} from "@witivio_teamspro/northstar-form";
import {faker} from "@faker-js/faker/locale/fr";
import React from "react";
import {MessageOfTheDay, MessageOfTheDayButton} from "../../../interfaces/MessageOfTheDay";
import {ObjectModule} from "../../../modules/Object.module";
import {ConfigurationApi} from "../../../apis/Configuration/ConfigurationApi";
import {DialogRef} from "../../../components/Dialog/Dialog.interfaces";
import {MentionModule} from "../../../modules/Mention.module";
import {Notification} from "../../../interfaces/Notification";
import {GraphService} from "../../../services/GraphService/GraphService";
import {translations} from "../../../translations";
import {SessionCacheService} from "../../../services/CacheService/CacheService.hook";
import {Constants} from "../../../const/Constants";

export const draftMessageCacheKey = "messageOfTheDayDraft";

export const initialState: State = {
    isSaving: false,
    buttons: [],
    canRetrieveDraft: false,
}

export const reducer = (config: {
    props: Props,
    formData: ReturnType<typeof useForm>,
    buttonsForm: ReturnType<typeof useForm>,
    buttonsRef: React.MutableRefObject<ImmutableObject<MessageOfTheDayButton>[]>,
    dialogRef: DialogRef,
    initialMessageRef: React.MutableRefObject<string>,
}) => ({
    handleOpen: (reducerData) => {
        const {props, formData, buttonsForm, dialogRef} = config;
        const {setState} = reducerData;
        formData.reset();
        buttonsForm.reset();
        const buttons = ObjectModule.deepClone(props.message.buttons ?? []);
        const draftMessage = SessionCacheService.getItem<string>(draftMessageCacheKey);
        setState({isSaving: false, buttons, canRetrieveDraft: !!draftMessage});
        dialogRef.dispatch("open")();
    },
    handleClose: () => {
        const {dialogRef} = config;
        dialogRef.dispatch("close")();
    },
    handleSave: async (reducerData) => {
        const {props, formData, buttonsForm, dialogRef, initialMessageRef} = config;
        const show = formData.dataRef.current?.visible as boolean;
        const message = formData.dataRef.current?.message as string;
        const messageOfTheDay: MessageOfTheDay = {
            show,
            message,
            buttons: reducerData.state.buttons.map(b => ({
                ...b,
                title: buttonsForm.dataRef.current?.[b.id + "_title"] as string,
                link: buttonsForm.dataRef.current?.[b.id + "_link"] as string,
            })),
        }
        initialMessageRef.current = message;
        const {setState} = reducerData;
        setState({isSaving: true});
        await ConfigurationApi.updateMessageOfTheDay(messageOfTheDay);
        setState({isSaving: false});
        dialogRef.dispatch("close")();
        props.onMessageEdited(messageOfTheDay);
        await notifyMentionedUsersInMessage(message);
        SessionCacheService.removeItem(draftMessageCacheKey);
    },
    handleAddButton: (reducerData) => {
        const {buttonsRef} = config;
        const {state, setState} = reducerData;
        const newButton: MessageOfTheDayButton = {
            id: faker.string.uuid(),
            title: "",
            link: "",
            color: "#00427C"
        }
        const buttons = [...state.buttons, newButton];
        buttonsRef.current = buttons;
        setState({buttons});
    },
    handleDeleteButton: (reducerData, _, id: string) => {
        const {buttonsRef} = config;
        const {state, setState} = reducerData;
        const buttons = state.buttons.filter(b => b.id !== id);
        buttonsRef.current = buttons;
        setState({buttons});
    },
    handleSetButtonColor: (reducerData, [e]: [React.ChangeEvent<HTMLInputElement>], id: string) => {
        const {buttonsRef} = config;
        const {state, setState} = reducerData;
        const buttonIndex = state.buttons.findIndex(b => b.id === id);
        if (buttonIndex < 0) return;
        const newButton = {...state.buttons[buttonIndex], color: e.target.value} as Immutable<MessageOfTheDayButton>;
        const buttons = [...state.buttons];
        buttons.splice(buttonIndex, 1, newButton);
        buttonsRef.current = buttons;
        setState({buttons}, false);
    },
    saveDraftMessage: ({state}, [message]: [string]) => {
        if (state.canRetrieveDraft) return;
        SessionCacheService.setItem(draftMessageCacheKey, message);
    },
    handleRetrieveDraft: ({setState}) => {
        const {initialMessageRef} = config;
        const draftMessage = SessionCacheService.getAndRemoveItem<string>(draftMessageCacheKey);
        initialMessageRef.current = draftMessage ?? "";
        setState({canRetrieveDraft: false});
    },
    handleDraftRetrieved: ({state}) => {
        const {formData, dialogRef, initialMessageRef, props} = config;
        if (!dialogRef.state?.isOpen || state.canRetrieveDraft) return;
        formData.reset();
        initialMessageRef.current = props.message.message;
    },
    removeDraftMessage: () => {
        SessionCacheService.removeItem(draftMessageCacheKey);
    }
}) satisfies MagicReducerObject<State>;

const notifyMentionedUsersInMessage = async (message: string) => {
    const mentions = MentionModule.extractMentions(message);
    if (mentions.length === 0) return;
    const userIds = mentions.map(m => m.mentionId);
    const notification: Notification = {
        id: "notification",
        title: translations.en["CheckOutMessageOfTheDay"] + "!",
    }
    await GraphService.sendNotification({
        appName: Constants.appName,
        userIds,
        title: notification.title,
        subtitle: notification.subtitle,
        entityId: "starvoice",
    });
}