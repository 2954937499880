import React from 'react';
import ReactDOM from 'react-dom/client';
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import './index.scss';
import {App} from './App';
import {Provider} from 'react-redux';
import store from './redux/store';
import {QueryClient, QueryClientProvider} from 'react-query';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    window.document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();