import * as React from "react";
import {ReactElement} from "react";
import {ReactComponent as ChatSvg} from "./Chat.svg";
import {ReactComponent as KnowledgeSvg} from "./Knowledge.svg";
import {ReactComponent as TrainingSvg} from "./Training.svg";
import {ReactComponent as AppFolderSvg} from "./AppFolder.svg";
import {ReactComponent as QuestionCircleSvg} from "./QuestionCircle.svg";
import {ReactComponent as ThumbSvg} from "./Thumb.svg";
import {ReactComponent as ThumbFillSvg} from "./ThumbFill.svg";
import {ReactComponent as TargetSvg} from "./Target.svg";
import {ReactComponent as HomeSvg} from "./Home.svg";
import {ReactComponent as ReportSvg} from "./Report.svg";
import {ReactComponent as MegaphoneSvg} from "./Megaphone.svg";
import {ReactComponent as TrophySvg} from "./Trophy.svg";
import {ReactComponent as EyeSvg} from "./Eye.svg";

export type IIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
export type IconProps = React.SVGProps<SVGSVGElement> & { title?: string };
export type IconFunc = (props: IconProps) => ReactElement;

const renderSvgIcon = (Icon: IIcon): IconFunc => (props) => {
    const propsClone = {...props};
    propsClone.width = propsClone.width ?? 20;
    propsClone.height = propsClone.height ?? 20;
    return <Icon className={"custom-icon"} {...propsClone}/>;
}

export const ChatIcon = renderSvgIcon(ChatSvg);
export const KnowledgeIcon = renderSvgIcon(KnowledgeSvg);
export const TrainingIcon = renderSvgIcon(TrainingSvg);
export const AppFolderIcon = renderSvgIcon(AppFolderSvg);
export const QuestionCircleIcon = renderSvgIcon(QuestionCircleSvg);
export const ThumbIcon = renderSvgIcon(ThumbSvg);
export const ThumbFillIcon = renderSvgIcon(ThumbFillSvg);
export const TargetIcon = renderSvgIcon(TargetSvg);
export const HomeIcon = renderSvgIcon(HomeSvg);
export const ReportIcon = renderSvgIcon(ReportSvg);
export const MegaphoneIcon = renderSvgIcon(MegaphoneSvg);
export const TrophyIcon = renderSvgIcon(TrophySvg);
export const EyeIcon = renderSvgIcon(EyeSvg);