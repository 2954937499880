import React, {memo, ReactElement, useMemo, useRef} from "react";
import {DateRangeType, Props, State} from "./DateRangeSelector.interfaces";
import {MagicDispatch, useMagicReducer} from "@witivio_teamspro/use-reducer";
import {DateRangeSelectorHelpers, initialState, reducer} from "./DateRangeSelector.reducer";
import {CompareModule} from "modules/Compare.module";
import "./DateRangeSelector.styles.scss";
import {ArrowRightIcon, Button, CalendarIcon, Flex, FormDatepicker, PopperRefHandle} from "@fluentui/react-northstar";
import moment from "moment/moment";
import {WeekDaysModule} from "../../modules/WeekDays.module";
import {PopupMenuButton} from "../PopupMenuButton/PopupMenuButton";
import {translations} from "../../translations";
import {useMsTeamsSelector} from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";

export const DateRangeSelector = memo((props: Props): ReactElement | null => {
    const datePickerPopperRef = useRef<PopperRefHandle | null>(null);
    const popupContentRef = useRef<HTMLDivElement | null>(null);
    const [state, dispatch] = useMagicReducer(reducer({props, datePickerPopperRef, popupContentRef}), initialState);

    const {fullLocale} = useMsTeamsSelector("fullLocale");

    const shortDaysNames = useMemo(() => DateRangeSelectorHelpers.getShortDaysNames(fullLocale), []);
    const monthsNames = useMemo(() => DateRangeSelectorHelpers.getMonthsNames(fullLocale), []);
    const firstDayOfWeek = useMemo(() => WeekDaysModule.getFirstDayOfWeek(), []);

    const selectedDate = moment(props.dateRange?.start).toDate();
    const selectedEndDate = moment(props.dateRange?.end).toDate();

    const isCustomSelectionMode = state.selectionMode === DateRangeType.Custom;
    const pickerSelectedDate = isCustomSelectionMode && state.isCustomEndDateSelected ? selectedEndDate : selectedDate;

    const trigger = (
        <Button
            primary
            className={"gradient-button"}
            icon={<CalendarIcon outline/>}
            content={renderDateTitle(state.selectionMode, selectedDate, selectedEndDate)}
            onClick={dispatch("handleClickTrigger")}
        />
    )

    const datePicker = (
        <Flex fill styles={{height: "225px"}} className={"no-select"}>
            <FormDatepicker
                shortDays={shortDaysNames}
                months={monthsNames}
                formatMonthDayYear={date => moment(date).locale(fullLocale).format("L")}
                buttonOnly
                calendar={{
                    header: {
                        onPreviousClick: dispatch("handleChangeMonth", "previous"),
                        onNextClick: dispatch("handleChangeMonth", "next"),
                    }
                }}
                firstDayOfWeek={firstDayOfWeek as number}
                popup={{
                    popperRef: datePickerPopperRef,
                    open: true,
                    trigger: <div/>,
                    contentRef: popupContentRef,
                }}
                onDateChange={dispatch("handleChangeDate")}
                maxDate={new Date()}
                dateRangeType={isCustomSelectionMode ? DateRangeType.Day : state.selectionMode as number}
                selectedDate={pickerSelectedDate}
            />
        </Flex>
    )

    return (
        <PopupMenuButton
            className={"date-range-selector-picker"}
            position={"below"}
            align={"center"}
            on={"click"}
            trigger={trigger}
            menu={{
                className: "no-select",
                items: [
                    {
                        key: "selectMode",
                        disabled: true,
                        children: renderModesButtons(state, dispatch),
                    },
                    {
                        key: "divider-1",
                        kind: "divider",
                        styles: {margin: "5px 0"},
                    },
                    ...(state.selectionMode === DateRangeType.Custom ? [{
                        key: "customDateRange",
                        disabled: true,
                        children: renderCustomDateButtons(state, dispatch, selectedDate, selectedEndDate),
                    }] : []),
                    {
                        key: "date-picker",
                        disabled: true,
                        children: datePicker,
                        className: "date-range-calendar no-select"
                    }
                ]
            }}
        />
    )
}, CompareModule.areObjectsEqual);

///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////

const renderDateTitle = (selectionMode: State["selectionMode"], date: Date, endDate: Date) => {
    switch (selectionMode) {
        case DateRangeType.Day:
            return date.toLocaleDateString(moment.locale(), {weekday: "short", day: "numeric", month: "long"});
        case DateRangeType.Week:
            return moment(date).startOf("week").format("L") + " - " + moment(date).endOf("week").format("L");
        case DateRangeType.Month:
            return date.toLocaleDateString(moment.locale(), {month: "long", year: "numeric"});
        case DateRangeType.Custom:
            return moment(date).format("L") + " - " + moment(endDate).format("L");
        default:
            return "";
    }
}

const renderModesButtons = (state: State, dispatch: MagicDispatch<typeof reducer>) => {
    const modes = [DateRangeType.Day, DateRangeType.Week, DateRangeType.Month, DateRangeType.Custom];
    return (
        <Flex fill gap={"gap.small"} vAlign={"center"}>
            {modes.map(mode => (
                <Button
                    key={"button-mode" + mode}
                    primary={state.selectionMode === mode}
                    content={translations.get(DateRangeType[mode])}
                    onClick={dispatch("handleSwitchMode", mode)}
                />
            ))}
        </Flex>
    )
}

const renderCustomDateButtons = (
    state: State,
    dispatch: MagicDispatch<typeof reducer>,
    selectedDate: Date,
    selectedEndDate: Date
) => {
    return (
        <Flex fill gap={"gap.small"} vAlign={"center"}>
            <Button
                primary={!state.isCustomEndDateSelected}
                fluid
                content={renderDateTitle(DateRangeType.Day, selectedDate, selectedEndDate)}
                onClick={dispatch("handleToggleSelectedCustomDate", false)}
            />
            <ArrowRightIcon/>
            <Button
                primary={state.isCustomEndDateSelected}
                fluid
                content={renderDateTitle(DateRangeType.Day, selectedEndDate, selectedEndDate)}
                onClick={dispatch("handleToggleSelectedCustomDate", true)}
            />
        </Flex>
    )
}