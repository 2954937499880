import {Flex, Image, Loader, Text} from "@fluentui/react-northstar";
import React from "react";
import StarvoiceLogo from "../../assets/images/starvoice_logo.svg";
import "./LoadingPage.styles.scss";

type Props = {
    label?: string;
}

export const LoadingPage = (props: Props) => {
    return (
        <Loader
            label={
                <Flex gap={"gap.medium"} column hAlign={"center"}>
                    <Image src={StarvoiceLogo} width={200}/>
                    {props.label && <Text size={"large"} styles={{color: "white"}} content={props.label}/>}
                </Flex>
            }
            labelPosition={"above"}
            styles={{height: "100vh"}}
            secondary
            className={"loading-page"}
        />
    )
}