import React, {useEffect} from "react";
import {Flex, Image, Text} from "@fluentui/react-northstar";
import StarvoiceLogo from "../../assets/images/starvoice_logo.svg";
import {translations} from "../../translations";
import * as microsoftTeams from "@microsoft/teams-js";

export const Configuration = () => {

    useEffect(() => {
        microsoftTeams.pages.config.registerOnSaveHandler(async event => {
            await microsoftTeams.pages.config.setConfig({
                contentUrl: "https://" + window.location.host,
            });
            event.notifySuccess();
        });
        microsoftTeams.pages.config.setValidityState(true);
    }, []);

    return (
        <Flex fill column vAlign={"center"} hAlign={"center"} gap={"gap.medium"}>
            <Image width={200} src={StarvoiceLogo}/>
            <Text content={translations.get("ClickSaveToContinue")} size={"larger"}/>
        </Flex>
    )
}